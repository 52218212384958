import React, { useState, useCallback } from 'react';
import {
  IconButton,
  Label,
  PrimaryButton,
  Stack,
  SelectionMode,
  Selection,
  Icon,
} from '@fluentui/react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import _ from 'lodash';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { ConstructionSiteCompactView } from '../construction';
import { Heading } from '../../components/typography';
import {
  stackTokens15,
  IConstructionSite,
  IProperty,
  ISuggestedValueCategory,
  ISalesRep,
  IEmployee,
  ISupplier,
  stackTokens5,
  iconButtonStyles,
  cancelIcon,
  IPropertyListType,
  IPurchaseOrder,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_STATUSES,
  GET_PURCHASE_ORDER_BY_ID,
  DELETE_PURCHASE_ORDER,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  DEFAULT_TOAST_POSITION,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_ERROR_MESSAGE,
} from '../../utils';
import ExecutionListPanel from '../implementation-list/components/ExecutionListPanel';
import { markItem, deleteItem } from '../construction/utils';
import { defaultProperty } from '../../utils/Property';
import PurchaseOrderDetailFast, {
  sortLineItems,
} from '../purchase-orders/components/PurchaseOrderDetailFast';
import { GET_SUPPLIERS_WITH_CONTACT_PERSONS } from '../../utils/Supplier';
import purchaseOrderDownload from '../purchase-orders/components/PurchaseOrderDownload';
import useDebounce from '../../components/hooks/useDebounce';
import { toastError, toastSuccess } from '../../utils/toast';
import ConstructionSheetMenu from '../../components/parts/ConstructionSheetMenu';
import ExecutionListPanelNew from '../implementation-list/components/ExecutionListPanelNew';
import ConstructionSheetTemplate from './components/ConstructionSheetTemplate';
import ConstructionSheetOverview from './components/ConstructionSheetOverview';

interface Props {
  constructionSite: IConstructionSite;
  employees: IEmployee[];
  dataTypes: IPropertyListType[];
  suggestedValueCategories: ISuggestedValueCategory[];
  salesReps: ISalesRep[];
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
  suppliers: ISupplier[];
  saveConstructionSite: () => void; // apollomutation
  setCurrentView: (value: string) => void;
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
  documentEdited?: boolean;
  refetch: any;
}

const getProperty = (properties: IProperty[], selectedKey: number) => {
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (property.id === selectedKey) return property;
  }

  return defaultProperty;
};

const ConstructionSheet: React.FC<Props> = ({
  constructionSite,
  employees,
  dataTypes,
  suggestedValueCategories,
  salesReps,
  suppliers,
  setConstructionSite,
  saveConstructionSite,
  setCurrentView,
  setFilter,
  filter,
  moveItem,
  documentEdited,
  refetch,
}) => {
  // =====================================================
  // ===================== S T A T E =====================
  // =====================================================

  const [showPDF, setShowPDF] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [selectedPropertyID, setSelectedPropertyID] = useState<number>();

  const getFileName = () => 'werffiche';

  const getPDFItems = () => {
    const newConstructionSite = { ...constructionSite };
    newConstructionSite.properties = constructionSite.properties
      .filter(
        (property: IProperty) => property.weight_for_construction_sheet > 0,
      )
      .sort(
        (a, b) =>
          a.weight_for_construction_sheet - b.weight_for_construction_sheet,
      );

    return newConstructionSite;
  };

  const updateProperties = (properties: IProperty[]) => {
    if (constructionSite) {
      const newConstructionSite = { ...constructionSite };
      newConstructionSite.properties = properties;
      setConstructionSite(newConstructionSite);
    }
  };

  const assignConstructionManagerToOpenItems = useCallback(() => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    const properties = newConstructionSite.properties;

    for (let i = 0; i < properties.length; i += 1) {
      if (!properties[i].employee) {
        properties[i].employee = constructionSite.site_manager;
        properties[i].isDirty = true;
      }
    }
    newConstructionSite.properties = properties;

    setConstructionSite(newConstructionSite);
  }, [constructionSite, setConstructionSite]);

  const addNewProperty = useCallback(() => {
    const newConstructionSite = _.cloneDeep(constructionSite);
    const newProperty = _.cloneDeep(defaultProperty);
    newProperty.show_in_construction_sheet = true;
    newProperty.show_in_execution_list = false;
    newProperty.id = moment().unix();

    setSelectedPropertyID(newProperty.id);

    newConstructionSite.properties.push(newProperty);
    // newConstructionSite.isDirty = true; // for autosave!!
    setConstructionSite(newConstructionSite);

    setShowPanel(true);
  }, [constructionSite, setConstructionSite]);

  const selectionIsMarked = useCallback(() => {
    if (constructionSite && constructionSite.properties) {
      for (let i = 0; i < constructionSite.properties.length; i++) {
        if (
          constructionSite.properties[i] &&
          constructionSite.properties[i].id === selectedPropertyID
        ) {
          return constructionSite.properties[i].marked;
        }
      }
    }
    return false;
  }, [constructionSite, selectedPropertyID]);

  const selectionIsDeleted = useCallback(() => {
    if (constructionSite && constructionSite.properties) {
      for (let i = 0; i < constructionSite.properties.length; i++) {
        if (
          constructionSite.properties[i] &&
          constructionSite.properties[i].id === selectedPropertyID
        ) {
          return constructionSite.properties[i].delete;
        }
      }
    }
    return false;
  }, [constructionSite, selectedPropertyID]);

  // =====================================================
  // ============= P U R C H A S E O R D E R =============
  // =====================================================

  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);

  const [showDeletePurchaseOrderDialog, setShowDeletePurchaseOrderDialog] =
    useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();

  const [deletePurchaseOrder, { loading: deletingPurchaseTransaction }] =
    useMutation(DELETE_PURCHASE_ORDER, {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables:
            constructionSite && constructionSite.id
              ? {
                  where: { id: constructionSite.id },
                }
              : undefined,
        },
      ],
    });

  const deleteSelectedPurchaseOrder = useCallback(async () => {
    try {
      if (purchaseOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePurchaseOrder({
              variables: {
                where: {
                  id: purchaseOrder.id,
                },
              },
              onError: error => {
                setShowDeletePurchaseOrderDialog(false);
                reject(error);
              },
              onCompleted: (x: any) => {
                setShowDeletePurchaseOrderDialog(false);
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Bestelbon verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [purchaseOrder]);

  const getSelectionPurchaseOrderDetails = () => {
    const currentSelection: any = selectionPurchaseOrder.getSelection();

    if (currentSelection.length > 0) {
      setPurchaseOrder(currentSelection[0]);
    } else {
      setPurchaseOrder(undefined);
    }
  };

  const selectionPurchaseOrder = new Selection({
    onSelectionChanged: getSelectionPurchaseOrderDetails,
  });

  const openPurchaseOrderDetail = (newLead?: boolean) => {
    setPurchaseOrder(undefined);

    setIsPurchaseOrderPanelOpen(true);
  };

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataPTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS_WITH_CONTACT_PERSONS, {
    variables: {
      ...supplierQuery(),
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
    {
      skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
    },
  );

  const [getPurchaseOrderByID] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        // setPurchaseOrder(purchaseOrder);
      }
    },
  });

  const downloadPDF = () => {
    if (purchaseOrder && purchaseOrder.id) {
      getPurchaseOrderByID({
        variables: {
          where: {
            id: purchaseOrder.id,
          },
        },
      }).then((x: any) => {
        if (x && x.data && x.data.findOnePurchaseOrder) {
          let purchaseOrder: IPurchaseOrder = x.data.findOnePurchaseOrder;
          purchaseOrder = sortLineItems(purchaseOrder);
          // setPurchaseOrder(purchaseOrder);
          purchaseOrderDownload(purchaseOrder);
        }
      });
    }
  };

  if (!constructionSite) return <>Geen constructiesite</>;

  return (
    <Stack
      tokens={stackTokens15}
      style={{ width: '100%', marginBottom: '50px' }}
    >
      <div style={{ padding: '25px', paddingBottom: '0px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='ClipboardList' style={{ fontSize: '20px' }} />
          Werffiche
        </h3>
      </div>

      <ConstructionSheetMenu
        id={constructionSite.id}
        isConstructionSheet='true'
        addNewItem={() => addNewProperty()}
        saveConstructionSite={saveConstructionSite}
        download={() => {
          setShowPDF(true);
        }}
        setCurrentView={setCurrentView}
        documentEdited={documentEdited}
        editItem={() => {
          setShowPanel(true);
        }}
        toggleDeleteItem={() => {
          deleteItem(
            {
              selectedKey: selectedPropertyID, // update!
              constructionSite,
            },
            setConstructionSite,
          );
        }}
        markItem={(value: boolean) => {
          markItem(
            {
              selectedKey: selectedPropertyID, // update!
              value,
              constructionSite,
            },
            setConstructionSite,
          );
        }}
        isMarked={selectionIsMarked()}
        isDeleted={selectionIsDeleted()}
        canEditItem={!!selectedPropertyID}
        newBon={openPurchaseOrderDetail}
      />

      <div
        style={{
          paddingRight: '25px',
          paddingLeft: '25px',
        }}
      >
        <ConstructionSiteCompactView constructionsite={constructionSite} />
        <Label>Niet tegewezen items aan werfleider toewijzen:</Label>
        <PrimaryButton
          styles={{
            root: { alignSelf: 'baseline', marginTop: '0px!important' },
          }}
          onClick={assignConstructionManagerToOpenItems}
        >
          toewijzen
        </PrimaryButton>
      </div>
      <ConstructionSheetOverview
        // id={constructionSite.id}
        properties={
          constructionSite
            ? constructionSite?.properties.filter(
                (el: IProperty) => el.weight_for_construction_sheet > 0,
              )
            : []
        }
        suggestedvalues={suggestedValueCategories}
        salesreps={salesReps}
        employees={employees}
        suppliers={suppliers}
        // callBack={setConstructionSite}
        callBackProperties={updateProperties}
        setFilter={setFilter}
        filter={filter}
        moveItem={moveItem}
        editItem={() => {
          setShowPanel(true);
        }}
        setSelectedPropertyID={setSelectedPropertyID}
      />
      {showPanel && (
        <ExecutionListPanelNew
          closePanel={() => setShowPanel(false)}
          dataTypes={dataTypes}
          // isConstructionSheet={false} //this is the execution list
          properties={constructionSite.properties}
          selectedKey={selectedPropertyID}
          setConstructionSite={setConstructionSite}
          constructionSite={constructionSite}
          refetch={refetch}
        />
      )}
      {showPDF && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 75,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <Stack horizontalAlign='end'>
                    <PDFDownloadLink
                      document={
                        <ConstructionSheetTemplate
                          constructionSite={getPDFItems()}
                          title={getFileName()}
                          suggestedValues={suggestedValueCategories}
                        />
                      }
                      fileName={getFileName()}
                    >
                      {({
 blob, url, loading, error,
}) =>
                        (loading ? '' : <PrimaryButton text='Pdf downloaden' />)
                      }
                    </PDFDownloadLink>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPDF(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <ConstructionSheetTemplate
                constructionSite={getPDFItems()}
                title={getFileName()}
                suggestedValues={suggestedValueCategories}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
      {isPurchaseOrderPanelOpen && (
        <PurchaseOrderDetailFast
          isOpen={isPurchaseOrderPanelOpen}
          dismissPanel={() => {
            setIsPurchaseOrderPanelOpen(false);
            setPurchaseOrder(undefined);
          }}
          purchaseOrderSource={
            purchaseOrder || {
              construction_site: constructionSite,
              property: selectedPropertyID
                ? getProperty(constructionSite.properties, selectedPropertyID)
                : undefined,
            }
          }
          constructionSites={
            constructionSite ? ([{ ...constructionSite }] as any[]) : []
          }
          suppliers={dataSuppliers ? dataSuppliers.findManySuppliers : []}
          templates={
            dataPTemplates ? dataPTemplates.findManyPurchaseOrderTemplates : []
          }
          statuses={
            dataStatuses
              ? dataStatuses.findManyPurchaseOrderStatuses
              : undefined
          }
          measuringUnits={
            dataMeasuringUnits ? dataMeasuringUnits.findManyMeasuringUnits : []
          }
          supplierSearch={supplierSearch}
          setSupplierSearch={setSupplierSearch}
          constructionSiteSearch={undefined}
          setConstructionSiteSearch={() => {}}
          downloadPDF={downloadPDF}
          refetch={refetch}
          setSelectionDetails={selectionPurchaseOrder}
          fast
        />
      )}
    </Stack>
  );
};

export default ConstructionSheet;

// Functions
/* const addNewProperty = (property: IProperty) => {
        if (constructionSite) {
            property.id =
                Math.max(
                    0,
                    ...constructionSite.properties.map((prop: IProperty) => prop.id),
                ) + 1;
            property.weight_for_execution_list = 0;
            property.weight_for_construction_sheet = 0;

            if (property.show_in_execution_list) {
                property.weight_for_execution_list =
                    Math.max(
                        0,
                        ...constructionSite.properties.map(
                            (prop: IProperty) => prop.weight_for_execution_list,
                        ),
                    ) + 1;
            }

            if (property.show_in_construction_sheet) {
                property.weight_for_construction_sheet =
                    Math.max(
                        0,
                        ...constructionSite.properties.map(
                            (prop: IProperty) => prop.weight_for_construction_sheet,
                        ),
                    ) + 1;
            }

            const newConstructionSite = { ...constructionSite };
            newConstructionSite.properties = [
                ...constructionSite.properties,
                property,
            ];
            setConstructionSite(newConstructionSite);
        }
    };
*/
