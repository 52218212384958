/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  StackItem,
  TextField,
  IComboBoxOption,
  Label,
  Checkbox,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IDetailsRowCheckStyles,
  IDetailsRowBaseProps,
  DetailsRowCheck,
  IDetailsFooterProps,
  DetailsRow,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  SpinnerSize,
  Spinner,
} from '@fluentui/react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import moment from 'moment';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import {
  convertArchitectsToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  DELETE_PURCHASE_ORDER,
  GET_ARCHITECTS,
  GET_EMPLOYEES,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_STATUSES,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  IEmployee,
  IPurchaseOrder,
  modalContentStyles,
} from '../../../utils';
import {
  GET_LEADS,
  ILead,
  convertLeadsToComboBoxOptions,
} from '../../../utils/Lead';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import SuggestedView from '../../../components/parts/SuggestedView';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import {
  DELETE_CUSTOMER_PAYMENT_TRANSACTION,
  ICustomerPaymentTransaction,
} from '../../../utils/CustomerPaymentTransaction';
import { GET_TEMPLATES, ITemplate } from '../../../utils/Template';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import PaymentTransactionDetail from '../../payment-transaction/components/CustomerPaymentTransactionDetail';
import useDebounce from '../../../components/hooks/useDebounce';
import CustomerContractUpdateDetail from '../../customer-contract-update/components/CustomerContractUpdateDetail';
import PaymentTransactionInvoiceDetail from '../../payment-transaction/components/PaymentTransactionInvoiceDetail';
import { ICustomerContractUpdate } from '../../../utils/CustomerContractUpdate';
import {
  ADD_CONSTRUCTION_SITE,
  GET_CONSTRUCTION_SITES,
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
  UPDATE_CONSTRUCTION_SITE,
} from '../../../utils/ConstructionSite';
import {
  IInvoiceCustomer,
  DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
} from '../../../utils/ConstructionSiteInvoiceCustomer';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import {
  convertConstructionSiteStatusesToComboBoxOptions,
  GET_CONSTRUCTION_SITE_STATUSES,
  IConstructionSiteStatus,
} from '../../../utils/ConstructionSiteStatus';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import parseNumber from '../../../utils/Numbers';
import CustomerInvoiceDetail from '../../customer-invoice/components/CustomerInvoiceDetail';
import { toastSuccess, toastError } from '../../../utils/toast';
import ZipCodeFetcher from '../../../components/DynamicZip';
import { ITaxCode } from '../../../utils/TaxCode';
import { ICustomerInvoiceLineItem } from '../../../utils/CustomerInvoiceLineItem';
import { PurchaseOrderBody } from '../../purchase-orders';
import PurchaseOrderDetail, {
  sortLineItems,
} from '../../purchase-orders/components/PurchaseOrderDetail';
import { GET_SUPPLIERS_WITH_CONTACT_PERSONS } from '../../../utils/Supplier';
import purchaseOrderDownload from '../../purchase-orders/components/PurchaseOrderDownload';
import PurchaseOrderDetailFast from '../../purchase-orders/components/PurchaseOrderDetailFast';
import InvoiceCustomerDetail from './CustomerInvoiceDetail';

interface Props {
  isOpen: boolean;
  dismissPanel: () => void;
  constructionSiteSource: IConstructionSiteCreateUpdate;
  listFilters: any;
  constructionSiteId?: number;
  taxCodes: ITaxCode[];
}

interface ICustomerContractUpdateCalculated extends ICustomerContractUpdate {
  calculatedTotal?: number;
}

const ConstructionSiteDetail = ({
  isOpen,
  dismissPanel,
  constructionSiteSource,
  constructionSiteId,
  taxCodes,
}: Props) => {
  // =====================================================
  // ====================== A U T H ======================
  // =====================================================

  const { getAccessTokenSilently, user } = useAuth0();

  const hasDirectieRole = user?.['3bouw/roles']?.includes('Directie');

  // =====================================================
  // ===================== S T A T E =====================
  // =====================================================

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  const [templates, setTemplates] = useState<ITemplate[]>([]);

  const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
    onCompleted: data => {
      setTemplates(data.findManyTemplates);
    },
  });

  const getTemplateOptions = (templates: ITemplate[]) => {
    const templateItems = templates.map(template => ({
      key: template.id!,
      text: template.name,
    }));
    templateItems.push({ key: -1, text: 'geen sjabloon' });

    return templateItems;
  };

  const [architectFilter, setArchitectFilter] = useState('');
  const debouncedArchitectFilter = useDebounce(architectFilter, 500);

  const getArchitectFilters = (filterstring: string) => {
    const filter: any = {
      AND: [],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        const filters: any = [
          { first_name: { contains: stringArray[i] } },
          { last_name: { contains: stringArray[i] } },
          { company: { contains: stringArray[i] } },
          { city: { contains: stringArray[i] } },
        ];

        const numericValue = parseInt(filter, 10);

        if (numericValue) {
          filters.OR.push({ zip_code: { equals: numericValue } });
        }

        filter.AND = [...filter.AND, { OR: filters }];
      }
    }
    return filter;
  };

  const { data: architectsData } = useQuery(GET_ARCHITECTS, {
    variables: {
      filter:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? getArchitectFilters(debouncedArchitectFilter)
          : {},
      take:
        debouncedArchitectFilter && debouncedArchitectFilter.length > 0
          ? undefined
          : 20,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
  });

  const { data: constructionSiteStatusesData } = useQuery(
    GET_CONSTRUCTION_SITE_STATUSES,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [leadFilter, setLeadFilter] = useState('');
  const debouncedLeadFilter = useDebounce(leadFilter, 500);

  const getLeadFilter = (filterstring: string) => {
    const filter: any = {
      AND: [
        {
          OR: [
            { status: { contains: 'SOLD' } },
            { status: { contains: 'ONLY_INVOICE' } },
            { status: { contains: 'PROVISIONAL_AGREEMENT' } },
            { status: { contains: 'CANCELED' } },
            { only_invoice: true },
            {
              construction_sites: {
                some: {
                  id: {
                    gt: 0,
                  },
                },
              },
            },
          ],
        },
      ],
    };
    if (filterstring) {
      const stringArray = filterstring.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  };

  const [leads, setLeads] = useState<ILead[]>([]);
  useQuery(GET_LEADS, {
    variables: {
      filter:
        debouncedLeadFilter && debouncedLeadFilter.length > 0
          ? getLeadFilter(debouncedLeadFilter)
          : {},
      take:
        debouncedLeadFilter && debouncedLeadFilter.length > 0 ? undefined : 20,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      const leads = x.findManyLeads;
      if (
        x.findManyLeads &&
        constructionSite &&
        constructionSite.lead &&
        constructionSite.lead.id
      ) {
        let currentLeadExists = false;
        for (let i = 0; i < x.findManyLeads.length; i++) {
          if (x.findManyLeads[i].id === constructionSite.lead.id) {
            currentLeadExists = true;
          }
        }
        if (!currentLeadExists) {
          leads.push(constructionSite.lead);
        }
      }
      setLeads(leads);
    },
  });

  // =====================================================
  // ========== C O N S T R U C T I O N S I T E ==========
  // =====================================================

  const [constructionSite, setConstructionSite] =
    useState<IConstructionSiteCreateUpdate>(constructionSiteSource);

  const [isLoadingUpsertCon, setIsLoadingUpsertCon] = useState(false);

  const { data: constructionSiteData, refetch } = useQuery(
    GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
    {
      variables:
        (constructionSite && constructionSite.id) || constructionSiteId
          ? {
              where: { id: constructionSite.id || constructionSiteId },
              whereProps: {
                OR: [
                  { template_item_id: 1351 },
                  { template_item_id: 1376 },
                  { template_item_id: 1378 },
                ],
              },
            }
          : undefined,
      skip: (!constructionSite || !constructionSite.id) && !constructionSiteId,
    },
  );

  const [modifyConstructionSite] = useMutation(UPDATE_CONSTRUCTION_SITE, {
    refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });

  const [addConstructionSite] = useMutation(ADD_CONSTRUCTION_SITE, {
    // refetchQueries: [{ query: GET_CONSTRUCTION_SITES }],
  });

  const saveConstructionSite = async () => {
    if (constructionSite) {
      if (constructionSite.name === '' || !constructionSite.name) return;

      if (
        constructionSite.zip_code &&
        Number.isNaN(+constructionSite.zip_code)
      ) {
        return;
      }

      if (
        !constructionSite.lead ||
        !constructionSite.lead.id ||
        constructionSite.lead.id === 0
      ) {
        return;
      }

      try {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const data = {
              name: constructionSite.name,
              lead: {
                connect: {
                  id: constructionSite?.lead?.id,
                },
              },
              address: constructionSite.address,
              archive_no: constructionSite.archive_no,
              comments: constructionSite.comments,
              city: constructionSite.city,
              contract_amount: constructionSite.contract_amount
                ? parseNumber(constructionSite.contract_amount)
                : undefined,
              zip_code: constructionSite.zip_code
                ? +constructionSite.zip_code
                : undefined,
              architect: constructionSite.architect
                ? {
                    connect: { id: constructionSite.architect.id },
                  }
                : undefined,
              site_manager: constructionSite.site_manager
                ? {
                    connect: { id: constructionSite.site_manager.id },
                  }
                : undefined,
              epb_reporter: constructionSite.epb_reporter
                ? {
                    connect: { id: constructionSite.epb_reporter.id },
                  }
                : undefined,
              project_manager: constructionSite.project_manager
                ? {
                    connect: { id: constructionSite.project_manager.id },
                  }
                : undefined,
              sales_rep: constructionSite.sales_rep
                ? {
                    connect: { id: constructionSite.sales_rep.id },
                  }
                : undefined,
              status: constructionSite.status
                ? {
                    connect: { id: constructionSite.status.id },
                  }
                : undefined,
              safety_coordinator: constructionSite.safety_coordinator
                ? {
                    connect: { id: constructionSite.safety_coordinator.id },
                  }
                : undefined,
              degree_of_completion: constructionSite.degree_of_completion,
              type_of_home: constructionSite.type_of_home,
              style_of_home: constructionSite.style_of_home,
              billboard_comments: constructionSite.billboard_comments,
              billboard_required: constructionSite.billboard_required,
              billboard_status: constructionSite.billboard_status,
              k_level: constructionSite.k_level,
              e_level: constructionSite.e_level,
              airtightness: constructionSite.airtightness,
              date_of_contract: constructionSite.date_of_contract,
              date_sold: constructionSite.date_sold,
              date_completed: constructionSite.date_completed,
              sounding_required: constructionSite.sounding_required,
              sounding_status: constructionSite.sounding_status,
              template_id:
                constructionSite.template_id === -1
                  ? 0
                  : constructionSite.template_id,
            };

            if (constructionSite.id) {
              modifyConstructionSite({
                variables: {
                  id: constructionSite.id,
                  data,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  dismissPanel();
                  resolve(x);
                },
              });
            } else {
              addConstructionSite({
                variables: {
                  data,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: (x: any) => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        setConstructionSite(x.createConstructionSite);
        toastSuccess('Werf opgeslagen');
      } catch (error: any) {
        toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
      }
    }
  };

  useEffect(() => {
    if (constructionSiteData && constructionSiteData.findOneConstructionSite) {
      setConstructionSite(prevState => {
        if (!prevState.updated) {
          return constructionSiteData.findOneConstructionSite;
        }

        return prevState;
      });
    }
  }, [constructionSiteData]);

  // =====================================================
  // ============= P U R C H A S E O R D E R =============
  // =====================================================

  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);

  const [showDeletePurchaseOrderDialog, setShowDeletePurchaseOrderDialog] =
    useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();

  const [deletePurchaseOrder, { loading: deletingPurchaseTransaction }] =
    useMutation(DELETE_PURCHASE_ORDER, {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables:
            constructionSiteSource && constructionSiteSource.id
              ? {
                  where: { id: constructionSiteSource.id },
                }
              : undefined,
        },
      ],
    });

  const deleteSelectedPurchaseOrder = useCallback(async () => {
    try {
      if (purchaseOrder) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePurchaseOrder({
              variables: {
                where: {
                  id: purchaseOrder.id,
                },
              },
              onError: error => {
                setShowDeletePurchaseOrderDialog(false);
                reject(error);
              },
              onCompleted: (x: any) => {
                setShowDeletePurchaseOrderDialog(false);
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Bestelbon verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [purchaseOrder]);

  const getSelectionPurchaseOrderDetails = () => {
    const currentSelection: any = selectionPurchaseOrder.getSelection();

    if (currentSelection.length > 0) {
      setPurchaseOrder(currentSelection[0]);
    } else {
      setPurchaseOrder(undefined);
    }
  };

  const selectionPurchaseOrder = new Selection({
    onSelectionChanged: getSelectionPurchaseOrderDetails,
  });

  const openPurchaseOrderDetail = (newLead?: boolean) => {
    if (purchaseOrder && !newLead) {
      // test
    } else {
      setPurchaseOrder(undefined);
    }
    setIsPurchaseOrderPanelOpen(true);
  };

  // =====================================================
  // ======== P A Y M E N T T R A N S A C T I O N ========
  // =====================================================

  const [
    isCustomerPaymentTransactionPanelOpen,
    setIsCustomerPaymentTransactionPanelOpen,
  ] = useState(false);

  const [
    showDeletePaymentTransactionDialog,
    setShowDeletePaymentTransactionDialog,
  ] = useState(false);

  const [customerPaymentTransaction, setCustomerPaymentTransaction] =
    useState<ICustomerPaymentTransaction>();

  const [deletePaymentTranaction, { loading: deletingPaymentTransaction }] =
    useMutation(DELETE_CUSTOMER_PAYMENT_TRANSACTION, {
      refetchQueries: [
        {
          query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
          variables:
            constructionSiteSource && constructionSiteSource.id
              ? {
                  where: { id: constructionSiteSource.id },
                }
              : undefined,
        },
      ],
    });

  const deleteSelectedPaymentTransaction = useCallback(async () => {
    try {
      if (customerPaymentTransaction) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePaymentTranaction({
              variables: {
                where: {
                  id: customerPaymentTransaction.id,
                },
              },
              onError: error => {
                setShowDeletePaymentTransactionDialog(false);
                reject(error);
              },
              onCompleted: (x: any) => {
                setShowDeletePaymentTransactionDialog(false);
                resolve(x);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Verrekening verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [customerPaymentTransaction]);

  const getSelectionCustomerPaymentTransactionDetails = () => {
    const currentSelection: any =
      selectionCustomerPaymentTransaction.getSelection();

    if (currentSelection.length > 0) {
      setCustomerPaymentTransaction(currentSelection[0]);
    } else {
      setCustomerPaymentTransaction(undefined);
    }
  };

  const selectionCustomerPaymentTransaction = new Selection({
    onSelectionChanged: getSelectionCustomerPaymentTransactionDetails,
  });

  const openCustomerPaymentTransactionDetail = (newLead?: boolean) => {
    if (customerPaymentTransaction && !newLead) {
      // test
    } else {
      setCustomerPaymentTransaction(undefined);
    }
    setIsCustomerPaymentTransactionPanelOpen(true);
  };

  // =====================================================
  // =========== I N V O I C E C U S T O M E R ===========
  // =====================================================

  const [isInvoiceCustomerPanelOpen, setIsInvoiceCustomerPanelOpen] =
    useState(false);

  const [invoiceCustomer, setInvoiceCustomer] = useState<IInvoiceCustomer>();

  const getSelectionInvoiceCustomerDetails = () => {
    const currentSelection: any = selectionInvoiceCustomer.getSelection();

    if (currentSelection.length > 0) {
      setInvoiceCustomer(currentSelection[0]);
    } else {
      setInvoiceCustomer({});
    }
  };

  const selectionInvoiceCustomer = new Selection({
    onSelectionChanged: getSelectionInvoiceCustomerDetails,
  });

  const openInvoiceCustomerDetail = (newIC?: boolean) => {
    if (newIC) {
      setInvoiceCustomer({});
    }
    setIsInvoiceCustomerPanelOpen(true);
  };

  const [deleteInvoiceCustomer] = useMutation(
    DELETE_CONSTRUCTION_SITE_INVOICE_CUSTOMER,
  );

  // =====================================================
  // =========== C U S T O M E R I N V O I C E ===========
  // =====================================================

  const [isCreateInvoicePanelOpen, setIsCreateInvoicePanelOpen] =
    useState(true);

  const [isCustomerInvoicePanelOpen, setIsCustomerInvoicePanelOpen] =
    useState(false);

  const [customerInvoice, setCustomerInvoice] = useState<any>({});

  const getSelectionCustomerInvoiceDetails = () => {
    const currentSelection: any = selectionCustomerInvoice.getSelection();

    if (currentSelection.length > 0) {
      setCustomerInvoice(currentSelection[0]);
    } else {
      setCustomerInvoice({});
    }
  };

  const selectionCustomerInvoice = new Selection({
    onSelectionChanged: getSelectionCustomerInvoiceDetails,
  });

  const openCustomerInvoiceDetail = (newItem?: boolean) => {
    if (newItem) {
      setCustomerInvoice({});
    }
    setIsCustomerInvoicePanelOpen(true);
  };

  // =====================================================
  // =========== C O N T R A C T U P D A T E S ===========
  // =====================================================

  const [
    isCustomerContractUpdatePanelOpen,
    setIsCustomerContractUpdatePanelOpen,
  ] = useState(false);

  const [customerContractUpdate, setCustomerContractUpdate] =
    useState<ICustomerContractUpdate>();

  const getSelectionCustomerContractUpdateDetails = () => {
    const currentSelection: any =
      selectionCustomerContractUpdate.getSelection();

    if (currentSelection.length > 0) {
      setCustomerContractUpdate(currentSelection[0]);
    } else {
      setCustomerContractUpdate({});
    }
  };

  const selectionCustomerContractUpdate = new Selection({
    onSelectionChanged: getSelectionCustomerContractUpdateDetails,
  });

  const openCustomerContractUpdateDetail = (newLead?: boolean) => {
    if (customerContractUpdate && !newLead) {
      // getCustomerContractUpdate({
      //   variables: {
      //     where: { id: selectionCustomerContractUpdateDetails.id },
      //   },
      // });
    } else {
      setCustomerContractUpdate({
        intro: 'Verrekeningen/ aanpassingen tov de aannemingsovereenkomst',
      });
    }
    setIsCustomerContractUpdatePanelOpen(true);
  };

  // =====================================================
  // =====================================================
  // =====================================================

  useEffect(() => {
    if (
      !constructionSite ||
      !constructionSite.id ||
      constructionSite.id === -1
    ) {
      getTemplates();
    }
  }, []);

  const handleButtonClick = async (_lid: string) => {
    try {
      setIsLoadingUpsertCon(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(
        'https://3bouw-wings-sync-function.azurewebsites.net/api/PublicUpsertConstructionSite',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
          body: JSON.stringify({
            id: _lid,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setConstructionSite((prevState: any) => ({
        ...prevState,
        wings_account_code: data.wings_code,
      }));
      toastSuccess('Klant succesvol gesynchroniseerd');
    } catch (err: any) {
      toastError(err?.message ? err.message : 'Er is iets misgelopen');
    } finally {
      setIsLoadingUpsertCon(false);
    }
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setConstructionSite((prevState: any) => ({
      ...prevState,
      updated: true,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const onChangeTemplate = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption,
  ): void => {
    setConstructionSite((prevState: any) => ({
      ...prevState,
      updated: true,
      template_id: item ? item.key : -1,
    }));
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected />
  );

  const onRenderDetailsFooter = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerPaymentTransaction = {};
    if (constructionSite && constructionSite.payment_transactions) {
      let totalPercentage = 0;
      for (let i = 0; i < constructionSite.payment_transactions.length; i++) {
        totalPercentage += parseNumber(
          constructionSite.payment_transactions[i].percentage,
        );
      }
      item.percentage = totalPercentage;
      item.description = 'Totaal';
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          constructionSite.payment_transactions
            ? constructionSite.payment_transactions.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  const onRenderDetailsFooterContractUpdates = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerContractUpdateCalculated = {};
    if (
      constructionSite &&
      constructionSite.customer_contract_updates &&
      constructionSite.customer_contract_updates.length > 0
    ) {
      let total = 0;
      for (
        let i = 0;
        i < constructionSite.customer_contract_updates.length;
        i++
      ) {
        total += constructionSite.customer_contract_updates![i]
          .customer_contract_update_line_items
          ? constructionSite.customer_contract_updates![
              i
            ].customer_contract_update_line_items!.reduce(
              (total, item) =>
                parseNumber(item.units) * parseNumber(item.unit_price) + total,
              0,
            )
          : 0;
      }
      item.summary = 'Totaal';
      item.calculatedTotal = total;
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          constructionSite.customer_contract_updates
            ? constructionSite.customer_contract_updates.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  // ====================================================

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataPTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS_WITH_CONTACT_PERSONS, {
    variables: {
      ...supplierQuery(),
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
    {
      skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
    },
  );

  const [getPurchaseOrderByID] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        // setPurchaseOrder(purchaseOrder);
      }
    },
  });

  const downloadPDF = () => {
    if (purchaseOrder && purchaseOrder.id) {
      getPurchaseOrderByID({
        variables: {
          where: {
            id: purchaseOrder.id,
          },
        },
      }).then((x: any) => {
        if (x && x.data && x.data.findOnePurchaseOrder) {
          let purchaseOrder: IPurchaseOrder = x.data.findOnePurchaseOrder;
          purchaseOrder = sortLineItems(purchaseOrder);
          // setPurchaseOrder(purchaseOrder);
          purchaseOrderDownload(purchaseOrder);
        }
      });
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      //
      headerText={
        constructionSite && constructionSite.id
          ? 'Werf wijzigen'
          : 'Werf toevoegen'
      }
      //
      type={PanelType.custom}
      customWidth='1200px'
      styles={{
        root: {
          zIndex: 8000,
        },
        ...customPanelStyles,
      }}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {constructionSite && constructionSite.id && (
            <Label>ID: {constructionSite.id}</Label>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '48%' }}>
              {/* <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                Algemene informatie
              </h3> */}
              <Stack>
                <Stack
                  style={{
                    marginBottom: 10,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '100%' }}>
                    <TextField
                      name='name'
                      label='Naam'
                      value={
                        constructionSite && constructionSite.name
                          ? constructionSite.name
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                      required
                      errorMessage={
                        constructionSite && !constructionSite.name
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                    />
                  </StackItem>
                </Stack>

                {/*
                <ComboBox
                  label='Sjabloon'
                  onSelect={(
                    keys: string | string[] | number | undefined,
                    value: string | undefined,
                  ) => {
                    if (keys && !Array.isArray(keys)) {
                      updateConstructionSiteNumberValue(keys, 'template');
                    }
                  }}
                  options={addEmptyOptionToTemplates()}
                  //allowFreeForm
                  selectedKey={getTemplateId()}
                />
                */}
                {(!constructionSite ||
                  !constructionSite.id ||
                  constructionSite.id === -1) && (
                  <Stack
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Dropdown
                      label='Sjabloon'
                      selectedKey={
                        constructionSite ? constructionSite.template_id : -1
                      }
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={onChangeTemplate}
                      placeholder='Select an option'
                      options={getTemplateOptions(templates)}
                      // styles={dropdownStyles}
                    />
                  </Stack>
                )}

                <Stack
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <ComboboxWithFilter
                    label='Klant'
                    options={leads ? convertLeadsToComboBoxOptions(leads) : []}
                    value={
                      constructionSite && constructionSite.lead
                        ? constructionSite.lead.id
                        : ''
                    }
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...constructionSite };

                        result.lead = {
                          ...result.lead!,
                          id: newValue[0].key as number,
                        };

                        result.updated = true;

                        setConstructionSite(result);
                      }
                    }}
                    required
                    allowFreeForm
                    errorMessage={
                      constructionSite && !constructionSite.lead
                        ? 'Dit veld is verplicht'
                        : ''
                    }
                    setFilter={(value: string) => {
                      setLeadFilter(value);
                    }}
                  />
                  {constructionSite && constructionSite.lead && (
                    <a
                      href={`/customers/${constructionSite.lead.id}`}
                      target='_blank'
                      rel='noreferrer'
                      style={{ marginTop: '5px', color: '#71BF43' }}
                    >
                      klantenfiche openen
                    </a>
                  )}
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='wings_account_code'
                    label='Analytische code'
                    value={
                      constructionSite &&
                      (constructionSite as any).wings_account_code
                        ? (
                            constructionSite as any
                          ).wings_account_code.toString()
                        : ''
                    }
                    disabled
                  />
                  {constructionSite &&
                    !(constructionSite as any)?.wings_account_code &&
                    (constructionSite as any)?.id &&
                    hasDirectieRole && (
                      <p
                        style={{
                          cursor: 'pointer',
                          color: '#71BF44',
                          textDecoration: 'underline',
                          marginTop: 0,
                        }}
                        onClick={() =>
                          handleButtonClick((constructionSite as any)?.id)
                        }
                      >
                        code aanmaken{' '}
                        {isLoadingUpsertCon && (
                          <Spinner size={SpinnerSize.small} />
                        )}
                      </p>
                    )}
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='contract_amount'
                    label='Contractbedrag'
                    value={
                      constructionSite && constructionSite.contract_amount
                        ? constructionSite.contract_amount.toString()
                        : ''
                    }
                    prefix='€'
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
                <Stack style={{ marginBottom: 5 }}>
                  <TextField
                    name='address'
                    label='Adres'
                    value={
                      constructionSite && constructionSite.address
                        ? constructionSite.address
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>

                <Stack
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}
                >
                  <StackItem style={{ width: '25%' }}>
                    <ZipCodeFetcher
                      object={constructionSite}
                      setObject={setConstructionSite}
                      zipFieldName='zip_code'
                      cityFieldName='city'
                    />
                  </StackItem>
                  <StackItem style={{ width: '74%' }}>
                    <TextField
                      name='city'
                      value={
                        constructionSite && constructionSite.city
                          ? constructionSite.city
                          : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>

                <Stack style={{ marginBottom: 10 }}>
                  <TextField
                    label='Opmerkingen'
                    name='comments'
                    multiline
                    value={
                      constructionSite && constructionSite.comments
                        ? constructionSite.comments
                        : ''
                    }
                    onChange={onChangeTextFieldValue}
                  />
                </Stack>
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Architect'
                  options={
                    architectsData && architectsData.findManyArchitects
                      ? convertArchitectsToComboBoxOptions(
                          architectsData.findManyArchitects,
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.architect
                      ? constructionSite.architect.id
                      : ''
                  }
                  allowFreeForm
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.architect = {
                        ...result.architect!,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                  setFilter={(value: string) => {
                    setArchitectFilter(value);
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Verkoper'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.sales_rep === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.sales_rep
                      ? constructionSite.sales_rep.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.sales_rep = {
                        ...result.sales_rep,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='EPB verslaggever'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.epb_reporter === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.epb_reporter
                      ? constructionSite.epb_reporter.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.epb_reporter = {
                        ...result.epb_reporter,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Veiligheidscoördinator'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.safety_coordinator === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.safety_coordinator
                      ? constructionSite.safety_coordinator.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.safety_coordinator = {
                        ...result.safety_coordinator,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Projectbeheerder'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.customer_manager === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.project_manager
                      ? constructionSite.project_manager.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.project_manager = {
                        ...result.project_manager,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Werfleider'
                  options={
                    employeesData && employeesData.findManyEmployees
                      ? convertEmployeesToComboBoxOptions(
                          [...employeesData.findManyEmployees].filter(
                            (employee: IEmployee) =>
                              employee.construction_site_manager === true,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.site_manager
                      ? constructionSite.site_manager.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.site_manager = {
                        ...result.site_manager,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.date_sold}
                  label='Datum verkoop'
                  field='date_sold'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite.date_of_contract}
                  label='Datum contract'
                  field='date_of_contract'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <DateView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  date={constructionSite && constructionSite.date_completed}
                  label='Datum oplevering'
                  field='date_completed'
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='archive_no'
                  label='Archiefnummer'
                  value={
                    constructionSite && constructionSite.archive_no
                      ? constructionSite.archive_no
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </StackItem>

            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='billBoardRequired'
                  code={
                    constructionSite &&
                    constructionSite.billboard_required &&
                    constructionSite.billboard_required.substr(
                      constructionSite.billboard_required.length - 4,
                    )
                  }
                  label='Reclamebord toegestaan'
                  field='billboard_required'
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='billboard_status'
                  label='Reclamebord in orde'
                  checked={constructionSite.billboard_status || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      billboard_status: checked,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='billboard_comments'
                  label='Reclamebord info'
                  type='text'
                  value={
                    constructionSite && constructionSite.billboard_comments
                      ? constructionSite.billboard_comments
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='k_level'
                  label='K-peil'
                  type='text'
                  value={
                    constructionSite && constructionSite.k_level
                      ? constructionSite.k_level
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='e_level'
                  label='E-peil'
                  type='text'
                  value={
                    constructionSite && constructionSite.e_level
                      ? constructionSite.e_level
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='airtightness'
                  label='Luchtdichtheidsmeting'
                  type='text'
                  value={
                    constructionSite && constructionSite.airtightness
                      ? constructionSite.airtightness
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='sounding_required'
                  label='Sondering via 3bouw'
                  checked={constructionSite.sounding_required || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      sounding_required: checked,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginTop: 20, marginBottom: 10 }}>
                <Checkbox
                  name='sounding_status'
                  label='Sondering in orde'
                  checked={constructionSite.sounding_status || false}
                  onChange={(e, checked) => {
                    setConstructionSite((prevState: any) => ({
                      ...prevState,
                      updated: true,
                      sounding_status: checked,
                    }));
                  }}
                />
              </Stack>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Status'
                  options={
                    constructionSiteStatusesData &&
                    constructionSiteStatusesData.findManyConstructionSiteStatuses
                      ? convertConstructionSiteStatusesToComboBoxOptions(
                          [
                            ...constructionSiteStatusesData.findManyConstructionSiteStatuses,
                          ].filter(
                            (constructionSiteSatus: IConstructionSiteStatus) =>
                              constructionSiteSatus.label !== undefined,
                          ),
                        )
                      : []
                  }
                  value={
                    constructionSite && constructionSite.status
                      ? constructionSite.status.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...constructionSite };

                      result.status = {
                        ...result.status,
                        id: newValue[0].key as number,
                      };

                      result.updated = true;

                      setConstructionSite(result);
                    }
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='customer_degreeOfCompletion_'
                  code={
                    constructionSite && constructionSite.degree_of_completion
                      ? constructionSite.degree_of_completion.substr(
                          constructionSite.degree_of_completion.length - 4,
                        )
                      : constructionSite.lead &&
                        leads &&
                        leads.length > 0 &&
                        !constructionSite.id
                      ? leads.filter(
                          w => w.id === constructionSite?.lead?.id,
                        )[0].degree_of_completion
                        ? leads
                            .filter(w => w.id === constructionSite?.lead?.id)[0]
                            .degree_of_completion.substr(
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              leads.filter(
                                w => w.id === constructionSite?.lead?.id,
                              )[0].degree_of_completion.length - 4,
                            )
                        : ''
                      : ''
                  }
                  label='Graad van afwerking'
                  field='degree_of_completion'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='customer_typeOfHome_'
                  code={
                    constructionSite && constructionSite.type_of_home
                      ? constructionSite.type_of_home.substr(
                          constructionSite.type_of_home.length - 4,
                        )
                      : constructionSite.lead &&
                        leads &&
                        leads.length > 0 &&
                        !constructionSite.id
                      ? leads.filter(
                          w => w.id === constructionSite?.lead?.id,
                        )[0].type_of_home
                        ? leads
                            .filter(w => w.id === constructionSite?.lead?.id)[0]
                            .type_of_home.substr(
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              leads.filter(
                                w => w.id === constructionSite?.lead?.id,
                              )[0].type_of_home.length - 4,
                            )
                        : ''
                      : ''
                  }
                  label='Type woning'
                  field='type_of_home'
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <SuggestedView
                  item={constructionSite}
                  setItem={(item: IConstructionSiteCreateUpdate) => {
                    item.updated = true;
                    setConstructionSite(item);
                  }}
                  prefix='customer_styleOfHome_'
                  code={
                    constructionSite &&
                    constructionSite.style_of_home &&
                    constructionSite.style_of_home.substr(
                      constructionSite.style_of_home.length - 4,
                    )
                  }
                  label='Stijl woning'
                  field='style_of_home'
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='gevelsteen-type'
                  label='Gevelsteen type'
                  type='text'
                  disabled
                  value={
                    constructionSite && constructionSite.properties
                      ? constructionSite.properties
                          .find(p => p.name === 'Gevelsteen')
                          ?.children.find(c => c.name === 'Type')
                          ?.suggested_value?.suggested_value
                      : ''
                  }
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='gevelsteen-formaat'
                  label='Gevelsteen formaat'
                  type='text'
                  disabled
                  value={
                    constructionSite && constructionSite.properties
                      ? constructionSite.properties
                          .find(p => p.name === 'Gevelsteen')
                          ?.children.find(c => c.name === 'Formaat')
                          ?.suggested_value?.suggested_value
                      : ''
                  }
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='gevelsteen-metselmethode'
                  label='Gevelsteen metselmethode'
                  type='text'
                  disabled
                  value={
                    constructionSite && constructionSite.properties
                      ? constructionSite.properties
                          .find(p => p.name === 'Gevelsteen')
                          ?.children.find(c => c.name === 'Metselmethode')
                          ?.suggested_value?.suggested_value
                      : ''
                  }
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='buitenschrijnwerk-ramen-type'
                  label='Buitenschrijnwerk type'
                  type='text'
                  disabled
                  value={
                    constructionSite && constructionSite.properties
                      ? constructionSite.properties
                          .find(p => p.name === 'Buitenschrijnwerk ramen')
                          ?.children.find(c => c.name === 'Type')
                          ?.suggested_value?.suggested_value
                      : ''
                  }
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='pannen-dakbedekking-type'
                  label='Pannen dakbedekking type'
                  type='text'
                  disabled
                  value={
                    constructionSite && constructionSite.properties
                      ? constructionSite.properties.find(
                          p => p.name === 'Pannen - dakbedekking',
                        )?.suggested_value?.suggested_value
                      : ''
                  }
                />
              </Stack>
              {/*
              <Stack>
                {constructionSite &&
                  constructionSite.properties &&
                  constructionSite.properties.map((property: any) => {
                    return (
                      <Stack style={{ marginTop: 15 }}>
                        <Label>{property.name}</Label>{' '}
                        <span>{property.value}</span>
                        {property.children &&
                          property.children.map((child: any) => {
                            return child.name === 'Type' ? (
                              <Stack style={{ marginTop: 3, marginLeft: 10 }}>
                                <Label>{child.name}</Label>{' '}
                                <span>{child.value}</span>
                              </Stack>
                            ) : null;
                          })}
                      </Stack>
                    );
                  })}
              </Stack>
                */}
            </StackItem>
          </Stack>
          {showDeletePaymentTransactionDialog && customerPaymentTransaction && (
            <Dialog
              hidden={!showDeletePaymentTransactionDialog}
              onDismiss={() => {
                setShowDeletePaymentTransactionDialog(false);
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Betalingsschijf verwijderen',
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Ben je zeker dat je deze lijn wil verwijderen?</strong>
              </p>
              <p>{customerPaymentTransaction.description}</p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton
                  onClick={() => {
                    deleteSelectedPaymentTransaction();
                  }}
                >
                  Verwijderen{' '}
                  {deletingPaymentTransaction && (
                    <Spinner size={SpinnerSize.small} />
                  )}
                </PrimaryButton>
                <DefaultButton
                  text='Annuleren'
                  onClick={() => {
                    setShowDeletePaymentTransactionDialog(false);
                  }}
                />
              </DialogFooter>
            </Dialog>
          )}

          {showDeletePurchaseOrderDialog && purchaseOrder && (
            <Dialog
              hidden={!showDeletePurchaseOrderDialog}
              onDismiss={() => {
                setShowDeletePaymentTransactionDialog(false);
              }}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Bestelbon verwijderen',
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>
                  Ben je zeker dat je deze bestelbon wil verwijderen?
                </strong>
              </p>
              <p>{purchaseOrder.description}</p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton
                  onClick={() => {
                    deleteSelectedPurchaseOrder();
                  }}
                >
                  Verwijderen{' '}
                  {deletingPaymentTransaction && (
                    <Spinner size={SpinnerSize.small} />
                  )}
                </PrimaryButton>
                <DefaultButton
                  text='Annuleren'
                  onClick={() => {
                    setShowDeletePurchaseOrderDialog(false);
                  }}
                />
              </DialogFooter>
            </Dialog>
          )}

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {constructionSite && constructionSite.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-contract-updates'
                  id='accordion-contract-updates'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Min- en meerwerken
                      </h3>
                    </Stack>
                  }
                >
                  {isCustomerContractUpdatePanelOpen && (
                    <CustomerContractUpdateDetail
                      isOpen={isCustomerContractUpdatePanelOpen}
                      dismissPanel={() => {
                        setIsCustomerContractUpdatePanelOpen(false);
                        setCustomerContractUpdate(undefined);
                        selectionCustomerContractUpdate.setAllSelected(false);
                      }}
                      customerContractUpdateSource={
                        customerContractUpdate || {}
                      }
                      constructionSite={constructionSite}
                      skipFetchConstructionSites
                      taxCodes={taxCodes}
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openCustomerContractUpdateDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCustomerContractUpdateDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !customerContractUpdate || !customerContractUpdate.id,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .customer_contract_updates
                        ? constructionSiteData.findOneConstructionSite
                            .customer_contract_updates
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Document nr',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (
                          customer_contract_update: ICustomerContractUpdate,
                        ) => (
                          <span>
                            {customer_contract_update &&
                              customer_contract_update.document_no}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Omschrijving',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 500,
                        isRowHeader: true,
                        onRender: (
                          customer_contract_update: ICustomerContractUpdate,
                        ) => (
                          <span>
                            {customer_contract_update &&
                              customer_contract_update.summary}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Status',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 500,
                        isRowHeader: true,
                        onRender: (
                          customer_contract_update: ICustomerContractUpdate,
                        ) => <span>{customer_contract_update.status}</span>,
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Totaal (excl. BTW)',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (
                          customer_contract_update: ICustomerContractUpdateCalculated,
                        ) => {
                          if (
                            customer_contract_update &&
                            customer_contract_update.calculatedTotal
                          ) {
                            return (
                              <span>
                                €{' '}
                                {customer_contract_update.calculatedTotal.toFixed(
                                  2,
                                )}
                              </span>
                            );
                          }
                          if (
                            customer_contract_update &&
                            customer_contract_update.customer_contract_update_line_items
                          ) {
                            return (
                              <span>
                                {' '}
                                €{' '}
                                {customer_contract_update.customer_contract_update_line_items
                                  .reduce(
                                    (total, item) =>
                                      parseNumber(item.units) *
                                        parseNumber(item.unit_price) +
                                      total,
                                    0,
                                  )
                                  .toFixed(2)}
                              </span>
                            );
                          }
                          return <span />;
                        },
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCustomerContractUpdate}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onRenderDetailsFooter={onRenderDetailsFooterContractUpdates}
                    onItemInvoked={() => {
                      openCustomerContractUpdateDetail();
                    }}
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-purchase'
                  id='accordion-purchase'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Bestelbonnen
                      </h3>
                    </Stack>
                  }
                >
                  {isPurchaseOrderPanelOpen && (
                    <PurchaseOrderDetailFast
                      isOpen={isPurchaseOrderPanelOpen}
                      dismissPanel={() => {
                        setIsPurchaseOrderPanelOpen(false);
                        setPurchaseOrder(undefined);
                      }}
                      purchaseOrderSource={
                        purchaseOrder ||
                        ({
                          construction_site: constructionSite,
                        } as any)
                      }
                      constructionSites={
                        constructionSite
                          ? ([{ ...constructionSite }] as any[])
                          : []
                      }
                      suppliers={
                        dataSuppliers ? dataSuppliers.findManySuppliers : []
                      }
                      templates={
                        dataPTemplates
                          ? dataPTemplates.findManyPurchaseOrderTemplates
                          : []
                      }
                      statuses={
                        dataStatuses
                          ? dataStatuses.findManyPurchaseOrderStatuses
                          : undefined
                      }
                      measuringUnits={
                        dataMeasuringUnits
                          ? dataMeasuringUnits.findManyMeasuringUnits
                          : []
                      }
                      supplierSearch={supplierSearch}
                      setSupplierSearch={setSupplierSearch}
                      constructionSiteSearch={undefined}
                      setConstructionSiteSearch={() => {}}
                      downloadPDF={downloadPDF}
                      refetch={refetch}
                      setSelectionDetails={selectionPurchaseOrder}
                      fast
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openPurchaseOrderDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openPurchaseOrderDetail(),
                        theme: commandBarTheme,
                        disabled: !purchaseOrder || !purchaseOrder.id,
                      },
                      {
                        key: 'delete',
                        text: 'Verwijderen',
                        iconProps: { iconName: 'Trash' },
                        onClick: () => {
                          setShowDeletePurchaseOrderDialog(true);
                        },
                        theme: commandBarTheme,
                        disabled: !purchaseOrder || !purchaseOrder.id,
                        // ||
                        // (customerPaymentTransaction.customer_invoices &&
                        //   customerPaymentTransaction.customer_invoices
                        //     .length > 0),
                      },
                    ]}
                    theme={commandBarTheme}
                  />

                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .purchase_orders
                        ? constructionSiteData.findOneConstructionSite
                            .purchase_orders
                        : []
                    }
                    onItemInvoked={() => openPurchaseOrderDetail()}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Nr',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item && item.purchase_order_no
                              ? item.purchase_order_no
                              : ''}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Beschrijving',
                        minWidth: 190,
                        maxWidth: 190,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item && item.description ? item.description : ''}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Leverancier',
                        minWidth: 150,
                        maxWidth: 150,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item && item.supplier && item.supplier.name}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Werf',
                        minWidth: 150,
                        maxWidth: 150,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item.construction_site &&
                              item.construction_site.name}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column5',
                        name: 'Status',
                        minWidth: 75,
                        maxWidth: 75,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <Label
                            color={
                              item.status
                                ? item.status.id === 2
                                  ? 'green'
                                  : item.status.id === 1
                                  ? 'blue'
                                  : 'gray'
                                : 'gray'
                            }
                          >
                            {item.status && item.status.label}
                          </Label>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Aangemaakt',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        isResizable: false,
                        isSorted: true,
                        isSortedDescending: true,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item.creation_date &&
                              moment(item.creation_date).format('DD/MM/YYYY')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Leverdatum',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        isResizable: false,
                        onColumnClick: () => {},
                        onRender: (item: IPurchaseOrder) => (
                          <span>
                            {item.delivery_date
                              ? moment(item.delivery_date).format('DD/MM/YYYY')
                              : null}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionPurchaseOrder}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onRenderDetailsFooter={onRenderDetailsFooter}
                  />
                </AccordionItem>

                <AccordionItem
                  key='accordion-log-items'
                  id='accordion-log-items'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Betalingsschijven
                      </h3>
                    </Stack>
                  }
                >
                  {isCustomerPaymentTransactionPanelOpen && (
                    <PaymentTransactionDetail
                      isOpen={isCustomerPaymentTransactionPanelOpen}
                      dismissPanel={() => {
                        setIsCustomerPaymentTransactionPanelOpen(false);
                        setCustomerPaymentTransaction(undefined);
                      }}
                      customerPaymentTransactionSource={
                        customerPaymentTransaction || {}
                      }
                      constructionSite={constructionSite} // dummy
                      skipFetchConstructionSites
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () =>
                          openCustomerPaymentTransactionDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCustomerPaymentTransactionDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !customerPaymentTransaction ||
                          !customerPaymentTransaction.id,
                      },
                      {
                        key: 'delete',
                        text: 'Verwijderen',
                        iconProps: { iconName: 'Trash' },
                        onClick: () => {
                          setShowDeletePaymentTransactionDialog(true);
                        },
                        theme: commandBarTheme,
                        disabled:
                          !customerPaymentTransaction ||
                          !customerPaymentTransaction.id ||
                          (customerPaymentTransaction.customer_invoices &&
                            customerPaymentTransaction.customer_invoices
                              .length > 0),
                      },
                      {
                        key: 'invoice',
                        text: 'Factuur aanmaken',
                        iconProps: { iconName: 'Certificate' },
                        onClick: () => setIsCreateInvoicePanelOpen(true),
                        theme: commandBarTheme,
                        disabled:
                          !customerPaymentTransaction ||
                          !customerPaymentTransaction.id ||
                          (customerPaymentTransaction.customer_invoices &&
                            customerPaymentTransaction.customer_invoices
                              .length > 0),
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  {customerPaymentTransaction && isCreateInvoicePanelOpen && (
                    <>
                      <PaymentTransactionInvoiceDetail
                        isOpen={isCreateInvoicePanelOpen}
                        dismissPanel={() => {
                          setIsCreateInvoicePanelOpen(false);
                        }}
                        paymentTransaction={customerPaymentTransaction}
                        setPaymentTransaction={setCustomerPaymentTransaction}
                      />
                      {/* <p>{JSON.stringify(constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .payment_transactions
                        ? constructionSiteData.findOneConstructionSite
                            .payment_transactions
                        : [])}
                      </p> */}
                    </>
                  )}
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .payment_transactions
                        ? constructionSiteData.findOneConstructionSite
                            .payment_transactions
                        : []
                    }
                    onItemInvoked={() => openCustomerPaymentTransactionDetail()}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Omschrijving',
                        fieldName: 'description',
                        minWidth: 250,
                        maxWidth: 250,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>{customerPaymentTransaction.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Factuur',
                        fieldName: 'customer_invoice',
                        minWidth: 110,
                        maxWidth: 110,
                        isRowHeader: true,
                        /* onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy &&
                            customerPaymentTransaction.customer_invoice_legacy
                              .invoice_no
                              ? `${customerPaymentTransaction.customer_invoice_legacy.invoice_no}`
                              : 'Geen factuur'}
                          </span>
                        ), */
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoices && (
                              <a
                                href={`/customer-invoices/${customerPaymentTransaction.customer_invoices.map(
                                  invoice => invoice.id,
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                                style={{ width: '20%', color: 'black' }}
                              >
                                {customerPaymentTransaction.customer_invoices.map(
                                  invoice => invoice.invoice_no,
                                )}
                              </a>
                            )}
                            {(!customerPaymentTransaction.customer_invoices ||
                              customerPaymentTransaction.customer_invoices
                                .length === 0) &&
                              'Geen factuur'}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Factuurdatum',
                        fieldName: 'payment_date',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoices &&
                              customerPaymentTransaction.customer_invoices
                                .length > 0 &&
                              moment(
                                customerPaymentTransaction.customer_invoices[0]
                                  .invoice_date,
                              ).format('YYYY-MM-DD')}
                          </span>
                        ),
                        /* onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy
                              ?.invoice_date &&
                              moment(
                                new Date(
                                  customerPaymentTransaction.customer_invoice_legacy.invoice_date,
                                ),
                              ).format('YYYY-MM-DD')}
                          </span>
                        ), */
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Percentage',
                        fieldName: 'percentage',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        onRender: (
                          payment_transaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {payment_transaction.percentage &&
                              `${payment_transaction.percentage}%`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Bedrag',
                        fieldName: 'amount',
                        minWidth: 75,
                        maxWidth: 75,
                        isRowHeader: true,
                        onRender: (
                          payment_transaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {payment_transaction.percentage &&
                              constructionSite.contract_amount &&
                              `€${(
                                parseNumber(constructionSite.contract_amount) *
                                (parseNumber(payment_transaction.percentage) /
                                  100)
                              ).toLocaleString('NL-nl')}`}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Vervaldatum',
                        fieldName: 'contract_amount',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        /* onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoice_legacy
                              ?.invoice_due_date &&
                              moment(
                                new Date(
                                  customerPaymentTransaction.customer_invoice_legacy.invoice_due_date,
                                ),
                              ).format('YYYY-MM-DD')}
                          </span>
                        ), */
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => (
                          <span>
                            {customerPaymentTransaction.customer_invoices &&
                              customerPaymentTransaction.customer_invoices
                                .length > 0 &&
                              moment(
                                customerPaymentTransaction.customer_invoices[0]
                                  .invoice_due_date,
                              ).format('YYYY-MM-DD')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Betaald',
                        fieldName: 'payed',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        onRender: (
                          customerPaymentTransaction: ICustomerPaymentTransaction,
                        ) => {
                          const isPaid =
                            customerPaymentTransaction.customer_invoices &&
                            customerPaymentTransaction.customer_invoices
                              .length > 0
                              ? customerPaymentTransaction.customer_invoices.filter(
                                  item => !item.invoice_paid,
                                ).length === 0
                              : false;
                          return (
                            <span>
                              <Checkbox disabled defaultChecked={!!isPaid} />
                            </span>
                          );
                        },
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCustomerPaymentTransaction}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onRenderDetailsFooter={onRenderDetailsFooter}
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-invoice-customer-invoices'
                  id='accordion-invoice-customer-invoices'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Facturen
                      </h3>
                    </Stack>
                  }
                >
                  {isCustomerInvoicePanelOpen && (
                    <CustomerInvoiceDetail
                      isOpen={isCustomerInvoicePanelOpen}
                      dismissPanel={() => {
                        setIsCustomerInvoicePanelOpen(false);
                        setCustomerInvoice(undefined);
                        selectionCustomerInvoice.setAllSelected(false);
                      }}
                      customerInvoiceSource={customerInvoice}
                      constructionSite={constructionSite}
                      skipFetchConstructionSites
                      tax_codes={taxCodes}
                    />
                  )}
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openCustomerInvoiceDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openCustomerInvoiceDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !invoiceCustomer ||
                          !invoiceCustomer.id ||
                          invoiceCustomer.id === -1,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .customer_invoices
                        ? constructionSiteData.findOneConstructionSite
                            .customer_invoices
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Factuurdatum',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (item: ICustomerInvoice) => (
                          <span>
                            {moment(item.invoice_date).format('YYYY-MM-DD')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Factuurnr',
                        fieldName: 'text',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (item: ICustomerInvoice) => (
                          <span>
                            <a
                              href={`/customer-invoices/${item.id}`}
                              target='_blank'
                              rel='noreferrer'
                              style={{ width: '20%', color: 'black' }}
                            >
                              {item.invoice_no}
                            </a>
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Intro',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (item: ICustomerInvoice) => (
                          <span>{item.intro}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Betaald',
                        fieldName: 'text',
                        minWidth: 50,
                        maxWidth: 50,
                        isRowHeader: true,
                        onRender: (item: ICustomerInvoice) => (
                          <span>
                            <Checkbox disabled checked={!!item.invoice_paid} />
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column6',
                        name: 'Totaal excl. BTW',
                        fieldName: 'unit_price',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (customerInvoice: ICustomerInvoice) => (
                          <span>
                            &euro;{' '}
                            {customerInvoice.customer_invoice_line_items
                              .reduce(
                                (previousValue, currentValue) =>
                                  previousValue +
                                  currentValue.unit_price * currentValue.units,
                                0,
                              )
                              .toFixed(2)}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column7',
                        name: 'Totaal incl. BTW',
                        fieldName: 'unit_price_with_vat',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (customerInvoice: ICustomerInvoice) =>
                          customerInvoice.customer_invoice_line_items
                            .reduce((previousValue, currentValue) => {
                              const unitPrice = +currentValue.unit_price; // Convert unit_price to a number if it's a string
                              const units = +currentValue.units; // Convert units to a number if it's a string
                              const taxRate = currentValue.tax_code
                                ? +currentValue.tax_code.rate // Use tax_code.rate if available
                                : +currentValue.percentage_vat; // Fallback to percentage_vat if tax_code.rate is not available

                              let taxMultiplier = 1; // Default to no tax

                              if (!taxRate || taxRate === 0) {
                                // No tax case
                                taxMultiplier = 1;
                              } else if (taxRate < 1) {
                                // If taxRate is a fraction (not percentage)
                                taxMultiplier = 1 + taxRate;
                              } else {
                                // If taxRate is a percentage
                                taxMultiplier = 1 + taxRate / 100;
                              }

                              return (
                                previousValue +
                                unitPrice * units * taxMultiplier
                              );
                            }, 0)
                            .toFixed(2),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column8',
                        name: 'Open saldo',
                        fieldName: 'open_saldo',
                        minWidth: 80,
                        maxWidth: 80,
                        isRowHeader: true,
                        onRender: (customerInvoice: ICustomerInvoice) => {
                          const invoiceAmount =
                            customerInvoice.customer_invoice_line_items
                              .reduce(
                                (
                                  previousValue: number,
                                  currentValue: ICustomerInvoiceLineItem,
                                ) => {
                                  const unitPrice = currentValue.unit_price
                                    ? +currentValue.unit_price
                                    : 0; // Default to 0 if unit_price is undefined
                                  const units = currentValue.units
                                    ? +currentValue.units
                                    : 0; // Default to 0 if units is undefined
                                  const taxRate =
                                    currentValue.tax_code &&
                                    currentValue.tax_code.rate !== undefined
                                      ? +currentValue.tax_code.rate // Use tax_code.rate if available
                                      : currentValue.percentage_vat
                                      ? +currentValue.percentage_vat
                                      : 0; // Use percentage_vat or default to 0

                                  let taxMultiplier = 1; // Default to no tax

                                  if (!taxRate || taxRate === 0) {
                                    taxMultiplier = 1;
                                  } else if (taxRate < 1) {
                                    taxMultiplier = 1 + taxRate;
                                  } else {
                                    taxMultiplier = 1 + taxRate / 100;
                                  }

                                  return (
                                    previousValue +
                                    unitPrice * units * taxMultiplier
                                  );
                                },
                                0,
                              )
                              .toFixed(2);

                          const invoiceAmountPayment =
                            customerInvoice.customer_invoice_payments
                              .reduce(
                                (previousValue: number, currentValue: any) => {
                                  const amount = currentValue.amount
                                    ? +currentValue.amount
                                    : 0; // Default to 0 if amount is undefined
                                  return previousValue + amount;
                                },
                                0,
                              )
                              .toFixed(2);

                          return (
                            <span
                              style={{
                                color:
                                  +(
                                    invoiceAmount - +invoiceAmountPayment
                                  ).toFixed(2) < 0
                                    ? 'orange'
                                    : '',
                              }}
                            >
                              &euro;{' '}
                              {(invoiceAmount - +invoiceAmountPayment).toFixed(
                                2,
                              )}
                            </span>
                          );
                        },

                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionCustomerInvoice}
                    selectionMode={SelectionMode.single}
                    onItemInvoked={() => {
                      openCustomerInvoiceDetail();
                    }}
                    selectionPreservedOnEmptyClick
                    // enableShimmer={constructionSite.invoice_customers || constructionSite.invoice_customers.length === 0}
                  />
                </AccordionItem>
                <AccordionItem
                  key='accordion-invoice-customer'
                  id='accordion-invoice-customer'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Extra klanten voor facturatie
                      </h3>
                    </Stack>
                  }
                >
                  {invoiceCustomer && (
                    <InvoiceCustomerDetail
                      isOpen={isInvoiceCustomerPanelOpen}
                      dismissPanel={() => {
                        setIsInvoiceCustomerPanelOpen(false);
                        setInvoiceCustomer(undefined);
                      }}
                      invoiceCustomerSource={invoiceCustomer}
                      constructionSite={constructionSite}
                    />
                  )}

                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: () => openInvoiceCustomerDetail(true),
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: () => openInvoiceCustomerDetail(),
                        theme: commandBarTheme,
                        disabled:
                          !invoiceCustomer ||
                          !invoiceCustomer.id ||
                          invoiceCustomer.id === -1,
                      },
                    ]}
                    theme={commandBarTheme}
                  />
                  <ShimmeredDetailsList
                    items={
                      constructionSiteData &&
                      constructionSiteData.findOneConstructionSite &&
                      constructionSiteData.findOneConstructionSite
                        .invoice_customers
                        ? constructionSiteData.findOneConstructionSite
                            .invoice_customers
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Naam',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (item: IInvoiceCustomer) => (
                          <span>
                            {item.lead && item.lead.first_name1}{' '}
                            {item.lead && item.lead.last_name1} -{' '}
                            {item.lead && item.lead.first_name2}{' '}
                            {item.lead && item.lead.last_name2}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Percentage',
                        fieldName: 'text',
                        minWidth: 300,
                        maxWidth: 300,
                        isRowHeader: true,
                        onRender: (item: IInvoiceCustomer) => (
                          <span>{item.percentage}%</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionInvoiceCustomer}
                    selectionMode={SelectionMode.single}
                    onItemInvoked={() => {
                      openInvoiceCustomerDetail();
                    }}
                    selectionPreservedOnEmptyClick
                    // enableShimmer={constructionSite.invoice_customers || constructionSite.invoice_customers.length === 0}
                  />
                  {(!constructionSite.invoice_customers ||
                    constructionSite.invoice_customers.length === 0) && (
                    <div style={{ textAlign: 'center' }}>
                      Geen resultaten gevonden
                    </div>
                  )}
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je het detail van de min-/meerwerken en
                  betalingsschijven aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='accordion-invoice-contract-updates'
                      id='accordion-invoice-contract-updates'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Min-/meerwerken
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-payments'
                      id='accordion-invoice-payments'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Betalingsschijven
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-payments'
                      id='accordion-invoice-payments'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Bestelbonnen
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-customer-invoices'
                      id='accordion-invoice-customer-invoices'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Facturen
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key='accordion-invoice-invoices'
                      id='accordion-invoice-invoices'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Extra klant voor facturatie
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !constructionSite ||
                (constructionSite && !constructionSite.name)
              }
              save={saveConstructionSite}
              permission='write:constructionSites'
              loading={false}
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ConstructionSiteDetail;
