import React, {
 useCallback, useState, useRef, useEffect,
} from 'react';
import {
  PrimaryButton,
  Stack,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  DefaultButton,
  Panel,
  PanelType,
  TextField,
  Toggle,
  Checkbox,
  Dropdown,
  IDropdownOption,
  ShimmeredDetailsList,
  Label,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IDetailsFooterProps,
  DetailsRow,
  DetailsRowCheck,
  IDetailsRowBaseProps,
  IDetailsRowCheckStyles,
} from '@fluentui/react';
import _, { property } from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment from 'moment';
import { defaultProperty, IProperty } from '../../../utils/Property';
import { IConstructionSite } from '../../../utils/ConstructionSite';
import {
  modalContentStyles,
  stackTokens10,
  stackTokens5,
  convertPropertyListTypeToDropdownOptions,
  IPropertyListType,
  convertPropertyTypeToComboBoxOptions,
  IPropertyType,
  property_types,
  GET_PROPERTY_TYPES,
  IPurchaseOrder,
  GET_PURCHASE_ORDER_BY_ID,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  GET_PURCHASE_ORDER_STATUSES,
} from '../../../utils';
import { updateListValue, getNextWeight } from '../../construction/utils/index';
import { toastError } from '../../../utils/toast';
import Accordion, { AccordionItem } from '../../../components/parts/Accordion';
import PurchaseOrderDetailFast, {
  sortLineItems,
} from '../../purchase-orders/components/PurchaseOrderDetailFast';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import { ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import parseNumber from '../../../utils/Numbers';
import purchaseOrderDownload from '../../purchase-orders/components/PurchaseOrderDownload';
import { GET_SUPPLIERS_WITH_CONTACT_PERSONS } from '../../../utils/Supplier';
import useDebounce from '../../../components/hooks/useDebounce';
import ModifyProperty from './ModifyProperty';

interface ExecutionListPanelProps {
  closePanel: () => void;
  dataTypes: IPropertyListType[];
  properties: IProperty[];
  selectedKey?: number;
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
  constructionSite: IConstructionSite;
  refetch: any;
}

const getProperty = (properties: IProperty[], selectedKey: number) => {
  for (let i = 0; i < properties.length; i++) {
    const property = properties[i];
    if (property.id === selectedKey) return property;
  }

  return defaultProperty;
};

const ExecutionListPanelNew: React.FC<ExecutionListPanelProps> = ({
  closePanel,
  dataTypes,
  properties,
  selectedKey,
  setConstructionSite,
  constructionSite,
  refetch,
}) => {
  const [currentSelection, setCurrentSelection] = useState<
    IProperty | undefined
  >();
  const [addSelectionToExecutionList, setAddSelectionToExecutionList] =
    useState<boolean | undefined>();
  const [addSelectionToConstructionSheet, setAddSelectiontoConstructionSheet] =
    useState<boolean | undefined>();

  const [isPurchaseOrderPanelOpen, setIsPurchaseOrderPanelOpen] =
    useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [showDeletePurchaseOrderDialog, setShowDeletePurchaseOrderDialog] =
    useState(false);

  const getSelectionPurchaseOrderDetails = () => {
    const currentSelection: any = selectionPurchaseOrder.getSelection();

    if (currentSelection.length > 0) {
      setPurchaseOrder(currentSelection[0]);
    } else {
      setPurchaseOrder(undefined);
    }
  };

  const selectionPurchaseOrder = new Selection({
    onSelectionChanged: getSelectionPurchaseOrderDetails,
  });

  const openPurchaseOrderDetail = (newLead?: boolean) => {
    if (purchaseOrder && !newLead) {
      // test
    } else {
      setPurchaseOrder(undefined);
    }
    setIsPurchaseOrderPanelOpen(true);
  };

  useEffect(() => {
    if (properties && selectedKey) {
      const existingProperty = getProperty(properties, selectedKey);
      if (existingProperty) setCurrentSelection(existingProperty);
      else {
        setCurrentSelection(defaultProperty);
      }
    } else {
      setCurrentSelection(defaultProperty);
    }
  }, [selectedKey, constructionSite]);

  useEffect(() => {
    if (!currentSelection) return; // only occurs in first render
    if (
      currentSelection &&
      currentSelection.isNew &&
      currentSelection.show_in_execution_list &&
      currentSelection.show_in_construction_sheet
    ) {
      setAddSelectionToExecutionList(true);
      setAddSelectiontoConstructionSheet(true);
      return;
    }
    if (
      currentSelection &&
      currentSelection.isNew &&
      currentSelection.show_in_execution_list
    ) {
      setAddSelectionToExecutionList(true);
      return;
    }
    if (
      currentSelection &&
      currentSelection.isNew &&
      currentSelection.show_in_construction_sheet
    ) {
      setAddSelectiontoConstructionSheet(true);
      return;
    }
    if (
      currentSelection &&
      currentSelection.weight_for_execution_list > 0 &&
      addSelectionToExecutionList === undefined
    ) setAddSelectionToExecutionList(true);
    else if (addSelectionToExecutionList === undefined) setAddSelectionToExecutionList(false);

    if (
      currentSelection &&
      currentSelection.weight_for_construction_sheet > 0 &&
      addSelectionToConstructionSheet === undefined
    ) setAddSelectiontoConstructionSheet(true);
    else if (addSelectionToConstructionSheet === undefined) setAddSelectiontoConstructionSheet(false);
  }, [currentSelection]);

  // When the TemplateItem is a parent, it loses its original type and therefor the ability to be edited.
  // So when it is a parent, the original type is passed along and all the controls are diverted to use this one.
  // 9 is id of string, should fetch id from inputP
  const comboboxPropertyType = useRef<IComboBox>(null);

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: types,
  } = useQuery(GET_PROPERTY_TYPES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: error => {
      toastError(error.message);
    },
  });

  const onChangePropertyName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.name = newValue || '';
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const onChangeNotApplicable = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.is_not_applicable = checked || false;
      updatedSelection.isDirty = true;

      setCurrentSelection(updatedSelection);
    }
  };

  const onChangeTappoints = (
    event: React.MouseEvent<HTMLElement>,
    checked?: boolean,
  ) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.needs_water_points = checked || false;
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const savePanel = useCallback(() => {
    closePanel();

    if (!currentSelection) return; // avoid further execution (normaly this will not happen)

    setConstructionSite(prevState => {
      const newState = _.cloneDeep(prevState);
      if (newState) {
        newState.update = true;
        const currentItem = currentSelection;
        // determine new weight for construction
        if (
          addSelectionToConstructionSheet &&
          currentItem.weight_for_construction_sheet <= 0
        ) {
          currentItem.isDirty = true;
          currentItem.weight_for_construction_sheet = getNextWeight(
            'construction',
            newState,
          );
        } else if (
          !addSelectionToConstructionSheet &&
          currentItem.weight_for_construction_sheet > 0
        ) {
          // console.log(addSelectionToConstructionSheet);
          currentItem.weight_for_construction_sheet = -1;
          currentItem.isDirty = true;
        }

        // determine new weight for construction
        if (
          addSelectionToExecutionList &&
          currentItem.weight_for_execution_list <= 0
        ) {
          currentItem.weight_for_execution_list = getNextWeight(
            'execution',
            newState,
          );
          currentItem.isDirty = true;
        } else if (
          !addSelectionToExecutionList &&
          currentItem.weight_for_execution_list > 0
        ) {
          currentItem.weight_for_execution_list = -1;
          currentItem.isDirty = true;
        }

        if (newState && newState.properties.length > 0) {
          newState.properties.map((item, index) => {
            if (newState && currentItem && item.id === currentItem.id) {
              console.log(currentItem);
              newState.properties[index] = currentItem;
            }
          });
        }
      }

      return newState;
    });
  }, [
    currentSelection,
    setConstructionSite,
    addSelectionToConstructionSheet,
    addSelectionToExecutionList,
  ]);

  const getPropertyType = (id: number | string): IPropertyType | undefined => {
    const propertyTypes =
      types && types.findManyPropertyTypes ? types.findManyPropertyTypes : [];
    if (id) {
      return propertyTypes.filter((el: IPropertyType) => el.id === id)[0];
    }
    return undefined;
  };

  const updateType = (type: IPropertyType) => {
    if (currentSelection) {
      const updatedSelection = _.cloneDeep(currentSelection);
      updatedSelection.type = type;
      updatedSelection.isDirty = true;
      setCurrentSelection(updatedSelection);
    }
  };

  const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = {
    root: { visibility: 'hidden' },
  };

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected />
  );

  const onRenderDetailsFooter = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: ICustomerPaymentTransaction = {};

    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          currentSelection?.purchase_orders
            ? currentSelection.purchase_orders.length
            : 1
        }
        selectionMode={SelectionMode.single}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataPTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS_WITH_CONTACT_PERSONS, {
    variables: {
      ...supplierQuery(),
    },
    skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
  });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
    {
      skip: !selectionPurchaseOrder && !isPurchaseOrderPanelOpen,
    },
  );

  const [getPurchaseOrderByID] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        // setPurchaseOrder(purchaseOrder);
      }
    },
  });

  const downloadPDF = () => {
    if (purchaseOrder && purchaseOrder.id) {
      getPurchaseOrderByID({
        variables: {
          where: {
            id: purchaseOrder.id,
          },
        },
      }).then((x: any) => {
        if (x && x.data && x.data.findOnePurchaseOrder) {
          let purchaseOrder: IPurchaseOrder = x.data.findOnePurchaseOrder;
          purchaseOrder = sortLineItems(purchaseOrder);
          // setPurchaseOrder(purchaseOrder);
          purchaseOrderDownload(purchaseOrder);
        }
      });
    }
  };

  const t = () => {
    console.log('sdfdsf :))sd');
  };

  return (
    <Panel
      // isLightDismiss
      onDismiss={() => {
        closePanel();
      }}
      closeButtonAriaLabel='Close'
      headerText='Item toevoegen of verwijderen'
      type={PanelType.custom}
      customWidth='650px'
      isOpen
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header}>
          <span>
            {currentSelection && currentSelection.id && currentSelection.id > 0
              ? 'Wijzig item'
              : 'Voeg item toe'}
          </span>
        </div>
        <div className={modalContentStyles.body}>
          <Stack
            horizontal
            tokens={stackTokens10}
            disableShrink
            style={{ marginBottom: '15px' }}
          >
            <Stack.Item>
              <Checkbox
                label='Uitvoeringslijst'
                checked={!!addSelectionToExecutionList}
                onChange={() => {
                  setAddSelectionToExecutionList(!addSelectionToExecutionList);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Checkbox
                label='Werffiche'
                checked={!!addSelectionToConstructionSheet}
                onChange={() => {
                  setAddSelectiontoConstructionSheet(
                    !addSelectionToConstructionSheet,
                  );
                }}
              />
            </Stack.Item>
          </Stack>
          <Stack tokens={stackTokens10} style={{ marginBottom: '15px' }}>
            {currentSelection && dataTypes && dataTypes.length > 0 && (
              <Dropdown
                placeholder='Kies lijsttype(s)'
                multiSelect
                selectedKeys={
                  currentSelection && currentSelection.list_types
                    ? currentSelection.list_types
                        .filter(item => !item.delete)
                        .map(item => item.list_type.id)
                    : undefined
                }
                options={convertPropertyListTypeToDropdownOptions(
                  dataTypes.filter(
                    (item: IPropertyListType) =>
                      item.name.toLowerCase() !== 'tappunten',
                  ),
                )}
                onChange={(
                  event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption | undefined,
                  // index?: number | undefined,
                ) => {
                  updateListValue(
                    option,
                    currentSelection,
                    setCurrentSelection,
                  );
                }}
              />
            )}
          </Stack>
          <Toggle
            onText='n.v.t.'
            offText='n.v.t.'
            checked={currentSelection && currentSelection.is_not_applicable}
            onChange={onChangeNotApplicable}
          />
          <Stack style={{ marginTop: '15px' }}>
            <Toggle
              onText='Tappunten'
              offText='Geen tappunten'
              checked={currentSelection && currentSelection.needs_water_points}
              onChange={onChangeTappoints}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.body}>
          <ComboBox
            componentRef={comboboxPropertyType}
            selectedKey={
              currentSelection && currentSelection.type
                ? currentSelection.type.id
                : undefined
            }
            label='Type'
            allowFreeform
            autoComplete='on'
            onChange={(
              event: React.FormEvent<IComboBox>,
              option?: IComboBoxOption,
              //   index?: number,
              //   value?: string,
            ) => {
              if (option) {
                const type = getPropertyType(option.key);
                if (type) updateType(type);
              }
            }}
            options={convertPropertyTypeToComboBoxOptions(
              types
                ? types.findManyPropertyTypes.filter(
                    (type: IPropertyType) =>
                      type.name !== property_types.PARENT,
                  )
                : [],
            )}
            // required
          />
        </div>
        <div className={modalContentStyles.body}>
          <Stack>
            <Stack.Item style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={currentSelection && currentSelection.name}
                onChange={onChangePropertyName}
                required
              />
            </Stack.Item>
          </Stack>

          {currentSelection && (
            <ModifyProperty
              item={currentSelection}
              setCurrentSelection={setCurrentSelection}
            />
          )}
          <Accordion
            selectedKey={selectedValueItem}
            defaultKey={undefined}
            toggleItem={(key: string | number) => {
              if (selectedValueItem === key) {
                setSelectedValueItem(undefined);
              } else setSelectedValueItem(key);
            }}
          >
            <AccordionItem
              key='accordion-purchase'
              id='accordion-purchase'
              title={
                <Stack>
                  <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                    Bestelbonnen
                  </h3>
                </Stack>
              }
            >
              {isPurchaseOrderPanelOpen && (
                <PurchaseOrderDetailFast
                  isOpen={isPurchaseOrderPanelOpen}
                  dismissPanel={() => {
                    setIsPurchaseOrderPanelOpen(false);
                    setPurchaseOrder(undefined);
                  }}
                  purchaseOrderSource={
                    purchaseOrder ||
                    ({
                      construction_site: constructionSite,
                      property: currentSelection,
                    } as any)
                  }
                  constructionSites={
                    constructionSite ? ([{ ...constructionSite }] as any[]) : []
                  }
                  suppliers={
                    dataSuppliers ? dataSuppliers.findManySuppliers : []
                  }
                  templates={
                    dataPTemplates
                      ? dataPTemplates.findManyPurchaseOrderTemplates
                      : []
                  }
                  statuses={
                    dataStatuses
                      ? dataStatuses.findManyPurchaseOrderStatuses
                      : undefined
                  }
                  measuringUnits={
                    dataMeasuringUnits
                      ? dataMeasuringUnits.findManyMeasuringUnits
                      : []
                  }
                  supplierSearch={supplierSearch}
                  setSupplierSearch={setSupplierSearch}
                  constructionSiteSearch={undefined}
                  setConstructionSiteSearch={() => {}}
                  downloadPDF={downloadPDF}
                  refetch={refetch}
                  setSelectionDetails={selectionPurchaseOrder}
                  fast
                />
              )}
              <CommandBarSticky
                items={[
                  {
                    key: 'new',
                    text: 'Nieuw',
                    iconProps: { iconName: 'Add' },
                    onClick: () => openPurchaseOrderDetail(true),
                    theme: commandBarTheme,
                  },
                  {
                    key: 'modify',
                    text: 'Wijzig',
                    iconProps: { iconName: 'Edit' },
                    onClick: () => openPurchaseOrderDetail(),
                    theme: commandBarTheme,
                    disabled: !purchaseOrder || !purchaseOrder.id,
                  },
                  {
                    key: 'delete',
                    text: 'Verwijderen',
                    iconProps: { iconName: 'Trash' },
                    onClick: () => {
                      setShowDeletePurchaseOrderDialog(true);
                    },
                    theme: commandBarTheme,
                    disabled: !purchaseOrder || !purchaseOrder.id,
                    // ||
                    // (customerPaymentTransaction.customer_invoices &&
                    //   customerPaymentTransaction.customer_invoices
                    //     .length > 0),
                  },
                ]}
                theme={commandBarTheme}
              />

              <ShimmeredDetailsList
                items={
                  currentSelection && currentSelection.purchase_orders
                    ? currentSelection.purchase_orders
                    : []
                }
                onItemInvoked={() => openPurchaseOrderDetail()}
                columns={[
                  {
                    key: 'column1',
                    name: 'Nr',
                    minWidth: 50,
                    maxWidth: 50,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>
                        {item && item.purchase_order_no
                          ? item.purchase_order_no
                          : ''}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column2',
                    name: 'Beschrijving',
                    minWidth: 190,
                    maxWidth: 190,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>
                        {item && item.description ? item.description : ''}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column3',
                    name: 'Leverancier',
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>{item && item.supplier && item.supplier.name}</span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column4',
                    name: 'Werf',
                    minWidth: 150,
                    maxWidth: 150,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>
                        {item.construction_site && item.construction_site.name}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column5',
                    name: 'Status',
                    minWidth: 75,
                    maxWidth: 75,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <Label
                        color={
                          item.status
                            ? item.status.id === 2
                              ? 'green'
                              : item.status.id === 1
                              ? 'blue'
                              : 'gray'
                            : 'gray'
                        }
                      >
                        <span
                          style={{
                            padding:
                              item?.status?.id === 1 ? '0px 12px' : '0px 2px',
                          }}
                        >
                          {item.status && item.status.label}
                        </span>
                      </Label>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column6',
                    name: 'Aangemaakt',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    isResizable: false,
                    isSorted: true,
                    isSortedDescending: true,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>
                        {item.creation_date &&
                          moment(item.creation_date).format('DD/MM/YYYY')}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column7',
                    name: 'Leverdatum',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    isResizable: false,
                    onColumnClick: () => {},
                    onRender: (item: IPurchaseOrder) => (
                      <span>
                        {item.delivery_date
                          ? moment(item.delivery_date).format('DD/MM/YYYY')
                          : null}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                ]}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible
                selection={selectionPurchaseOrder}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick
                onRenderDetailsFooter={onRenderDetailsFooter}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack horizontal reversed tokens={stackTokens5}>
            <DefaultButton
              onClick={() => {
                closePanel();
              }}
              text='Annuleren'
            />
            <PrimaryButton
              onClick={() => {
                savePanel();
              }}
              disabled={false}
              text={
                currentSelection &&
                currentSelection.id &&
                currentSelection.id > 0
                  ? 'Wijzigen'
                  : 'Toevoegen'
              }
            />
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default ExecutionListPanelNew;
