import { disconnect } from 'process';
import { useCallback, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from '@fluentui/react';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useBoolean } from '@fluentui/react-hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import SuggestedView from '../../../components/parts/SuggestedView';
import {
  ComboboxWithFilter,
  CommandBarSticky,
} from '../../../components/parts';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import { ILogItem } from '../../../utils/LogItem';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { getPermissions } from '../../../redux/permission/permissionSlice';
import DeleteButtonWithPermissions from '../../../components/parts/DeleteButtonWithPermissions';
import useDebounce from '../../../components/hooks/useDebounce';
import { toastError, toastSuccess } from '../../../utils/toast';
import { DELETE_ASSET, GET_ASSET_BY_ID, IAsset } from '../../../utils/Asset';
import { GET_TEAMS } from '../../../utils/Team';
import {
  ADD_ASSET_LOG,
  GET_ASSET_LOG_BY_ID,
  IAssetLog,
  UPDATE_ASSET_LOG,
} from '../../../utils/AssetLog';
import {
  ADD_INSPECTION,
  GET_INSPECTION_BY_ID,
  IInspection,
  UPDATE_INSPECTION,
} from '../../../utils/Inspection';
import AssetLogDetail from './AssetLogDetail';
import InspectionDetail from './InspectionDetail';
import InspectionGroupDetail from './InspectionGroupDetails';

// import LeadDownload from './LeadDownload';

// const LeadLogItemTitle = (suggestedValue: any) => {
//   const id = +suggestedValue.suggestedValue.substr(
//     suggestedValue.suggestedValue.length - 4,
//   );
//   const { data } = useQuery(GET_SUGGESTED_VALUE, {
//     variables: {
//       where: {
//         id,
//       },
//     },
//   });

//   if (data) {
//     return <span>{data.findOneSuggestedValue.suggested_value}</span>;
//   }
//   return <span />;
// };

export const AssetDetail = ({
  isOpen,
  dismissPanel,
  saveAsset,
  asset,
  setAsset,
  employees,
  refetchAsset,
  refetch,
  openNewUserModal,
}: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const permissions = useAppSelector(getPermissions);
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  // ===============================================================================================
  // =========================================  A S S E T  =========================================
  // ===============================================================================================

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setAsset((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);

  const [deleteasset] = useMutation(DELETE_ASSET);

  const deleteAssetFn = async () => {
    try {
      const res = toast.promise(
        new Promise((resolve, reject) => {
          deleteasset({
            variables: {
              where: {
                id: asset.id,
              },
            },
            onError: error => {
              dismissPanel();
              toggleDelete();
              reject(error);
            },
            onCompleted: async (x: any) => {
              dismissPanel();
              toggleDelete();

              setAsset(undefined);
              await refetch();
              resolve(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Asset verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : error);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =====================================  A S S E T   L O G  =====================================
  // ===============================================================================================

  const [isLogItemPanelOpen, setIsLogItemPanelOpen] = useState(false);
  const [logItem, setLogItem] = useState<IAssetLog>();

  const [selectionLogItemDetails, setSelectionLogItemDetails] = useState<
    ILogItem | undefined
  >();

  const getSelectionLogItemDetails = () => {
    const currentSelection: any = selectionLogItem.getSelection();

    if (currentSelection.length > 0) {
      setSelectionLogItemDetails(currentSelection[0]);
    } else {
      setSelectionLogItemDetails(undefined);
    }
  };

  const selectionLogItem = new Selection({
    onSelectionChanged: getSelectionLogItemDetails,
  });

  const openLogItemDetail = (newAsset?: boolean) => {
    // saveLead();
    if (selectionLogItemDetails && !newAsset) {
      setLogItem(undefined);
      getLogItem({ variables: { where: { id: selectionLogItemDetails.id } } });
    } else {
      setLogItem(undefined);
    }
    setIsLogItemPanelOpen(true);
  };

  const [getLogItem] = useLazyQuery(GET_ASSET_LOG_BY_ID, {
    onCompleted: data => {
      setLogItem(data.findOneAssetLog);
    },
  });

  const [addLogItem] = useMutation(ADD_ASSET_LOG, {
    refetchQueries: [
      {
        query: GET_ASSET_BY_ID,
        variables: {
          where: {
            id: asset.id,
          },
        },
      },
    ],
  });

  const [modifyLogItem] = useMutation(UPDATE_ASSET_LOG);

  const saveLogItem = async () => {
    try {
      if (logItem) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              comments: logItem.comments,
              asset: { connect: { id: asset.id } },
              date_created: logItem.date_created,
              team: logItem.team
                ? { connect: { id: logItem.team.id } }
                : logItem?.id
                ? {
                    disconnect: true,
                  }
                : undefined,
              construction_site: logItem.construction_site
                ? { connect: { id: logItem.construction_site.id } }
                : logItem?.id
                ? {
                    disconnect: true,
                  }
                : undefined,
            };

            if (logItem.id) {
              modifyLogItem({
                variables: {
                  id: logItem.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  refetchAsset({
                    variables: {
                      where: {
                        id: asset.id,
                      },
                    },
                  });
                  resolve(x);
                  toastSuccess('Log gewijzigd');
                },
              }).then(() => {
                setIsLogItemPanelOpen(false);
              });
            } else {
              addLogItem({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  toastError(error.message);
                },
                onCompleted: async (x: any) => {
                  await refetchAsset({
                    where: {
                      id: asset.id,
                    },
                  });
                  resolve(x);
                  toastSuccess('Log toegevoegd');
                },
              }).then(() => {
                setIsLogItemPanelOpen(false);
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // ===================================  I N S P E C T I O N   ====================================
  // ===============================================================================================

  const [isInspectionPanelOpen, setIsInspectionPanelOpen] = useState(false);
  const [inspection, setInspection] = useState<IInspection>();

  const [selectionInspectionDetails, setSelectionInspectionDetails] = useState<
    IInspection | undefined
  >();

  const getSelectioninspectionDetails = () => {
    const currentSelection: any = selectionInspection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionInspectionDetails(currentSelection[0]);
    } else {
      setSelectionInspectionDetails(undefined);
    }
  };

  const selectionInspection = new Selection({
    onSelectionChanged: getSelectioninspectionDetails,
  });

  const openInspectionDetail = (newAsset?: boolean) => {
    // saveLead();
    if (selectionInspectionDetails && !newAsset) {
      setInspection(undefined);
      getInspection({
        variables: { where: { id: selectionInspectionDetails.id } },
      });
    } else {
      setInspection(undefined);
    }
    setIsInspectionPanelOpen(true);
  };

  const [getInspection] = useLazyQuery(GET_INSPECTION_BY_ID, {
    onCompleted: data => {
      setInspection(data.findOneInspection);
    },
  });

  const [addInspection] = useMutation(ADD_INSPECTION, {
    refetchQueries: [
      {
        query: GET_ASSET_BY_ID,
        variables: {
          where: {
            id: asset.id,
          },
        },
      },
    ],
  });

  const [modifyInspection] = useMutation(UPDATE_INSPECTION);

  const saveInspection = async () => {
    try {
      if (inspection) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              comments: inspection.comments,
              asset: { connect: { id: asset.id } },

              inspection_date: inspection.inspection_date,
              inspection_expiry_date: inspection.inspection_expiry_date,
            };

            if (inspection.id) {
              modifyInspection({
                variables: {
                  id: inspection.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  refetchAsset({
                    variables: {
                      where: {
                        id: asset.id,
                      },
                    },
                  });
                  resolve(x);
                  toastSuccess('Inspectie gewijzigd');
                },
              }).then(() => {
                setIsInspectionPanelOpen(false);
              });
            } else {
              addInspection({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  toastError(error.message);
                },
                onCompleted: async (x: any) => {
                  await refetchAsset({
                    where: {
                      id: asset.id,
                    },
                  });
                  resolve(x);
                  toastSuccess('Inspection toegevoegd');
                },
              }).then(() => {
                setIsInspectionPanelOpen(false);
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== C O N S T R U C T I O N  ==================================
  // ===============================================================================================

  const [constructionSiteSearch, setConstructionSiteSearch] = useState<
    string | undefined
  >();

  const debouncedSearchTermConstructionSite = useDebounce(
    constructionSiteSearch,
    500,
  );

  const constructionSiteQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermConstructionSite
        ? {
            OR: [
              { name: { contains: debouncedSearchTermConstructionSite } },
              { city: { contains: debouncedSearchTermConstructionSite } },
              { address: { contains: debouncedSearchTermConstructionSite } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const { data: dataConstructionSites } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      ...constructionSiteQuery(),
    },
    skip: !asset && !isOpen,
  });

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  // ===============================================================================================
  // =================================== T E A M          T E A M ==================================
  // ===============================================================================================

  const [teamSearch, setTeamSearch] = useState<string | undefined>();

  const debouncedSearchTermTeam = useDebounce(teamSearch, 500);

  const teamQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermTeam
        ? {
            OR: [{ name: { contains: debouncedSearchTermTeam } }],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const { data: dataTeams } = useQuery(GET_TEAMS, {
    variables: {
      ...teamQuery(),
    },
    skip: !asset && !isOpen,
  });

  // ===============================================================================================
  // ===============================================================================================
  // ===============================================================================================

  const downloadInspectionsFile = useCallback(() => {
    toast.promise(
      new Promise((resolve, reject) => {
        (async () => {
          try {
            const url = `${process.env.REACT_APP_BACKEND_URI}/files/inspections/xls`;
            const token = await getAccessTokenSilently();
            const fileName = `inspections_${moment().format('YYYYMMDD')}.xlsx`;

            const res = await fetch(url, {
              method: 'post',
              headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                asset_id: asset.id,
              }),
            });

            if (!res.ok) throw new Error('Download failed');

            const result = await res.blob();

            const aElement = document.createElement('a');
            aElement.setAttribute('download', fileName);
            const href = URL.createObjectURL(result);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);

            resolve('Bestand is gedownload');
            toastSuccess('Bestand is succesvol gedownload');
          } catch (error) {
            reject(error);
          }
        })();
      }),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },

        error: {
          render({ data }: { data: any }) {
            return (
              data.message || 'Er is een fout opgetreden bij het downloaden'
            );
          },
        },
      },
    );
  }, [asset.id]);

  const downloadLogsFile = useCallback(() => {
    toast.promise(
      new Promise((resolve, reject) => {
        (async () => {
          try {
            const url = `${process.env.REACT_APP_BACKEND_URI}/files/asset_logs/xls`;
            const token = await getAccessTokenSilently();
            const fileName = `asset_logs_${moment().format('YYYYMMDD')}.xlsx`;

            const res = await fetch(url, {
              method: 'post',
              headers: new Headers({
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify({
                asset_id: asset.id,
              }),
            });

            if (!res.ok) throw new Error('Download failed');

            const result = await res.blob();

            const aElement = document.createElement('a');
            aElement.setAttribute('download', fileName);
            const href = URL.createObjectURL(result);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);

            resolve('Bestand is gedownload');
            toastSuccess('Bestand is succesvol gedownload');
          } catch (error) {
            reject(error);
          }
        })();
      }),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },

        error: {
          render({ data }: { data: any }) {
            return (
              data.message || 'Er is een fout opgetreden bij het downloaden'
            );
          },
        },
      },
    );
  }, [asset.id]);

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={asset && asset.id ? 'Asset wijzigen' : 'Asset toevoegen'}
      type={PanelType.custom}
      customWidth='900px'
      styles={customPanelStyles}
    >
      {deleteDialogOpen && (
        <>
          {asset.asset_logs.length > 0 || asset.inspections.length > 0 ? (
            <Dialog
              hidden={!deleteDialogOpen}
              onDismiss={toggleDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: `Asset ${asset.id} verwijderen`,
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Kan asset niet verwijderen</strong>
                <p>
                  Het geselecteerde asset is een asset met logs en of
                  inspecties.
                </p>
              </p>
              <DialogFooter>
                <DefaultButton text='Annuleren' onClick={toggleDelete} />
              </DialogFooter>
            </Dialog>
          ) : (
            <Dialog
              hidden={!deleteDialogOpen}
              onDismiss={toggleDelete}
              dialogContentProps={{
                type: DialogType.normal,
                title: `Asset ${asset.id} verwijderen`,
                closeButtonAriaLabel: 'Close',
              }}
            >
              <p>
                <strong>Ben je zeker dat je wil verwijderen?</strong>
              </p>
              <p>Deze actie kan niet ongedaan gemaakt worden.</p>
              <DialogFooter>
                <PrimaryButton text='Verwijderen' onClick={deleteAssetFn} />
                <DefaultButton text='Annuleren' onClick={toggleDelete} />
              </DialogFooter>
            </Dialog>
          )}
          <p />
        </>
      )}

      <AssetLogDetail
        isOpen={isLogItemPanelOpen}
        dismissPanel={() => {
          setIsLogItemPanelOpen(false);
        }}
        logItem={logItem}
        setLogItem={setLogItem}
        saveLogItem={saveLogItem}
        //
        refetchAsset={refetchAsset}
        asset={asset}
        //
        //
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        constructionSiteSearch={constructionSiteSearch}
        setConstructionSiteSearch={setConstructionSiteSearch}
        //
        teams={dataTeams ? dataTeams.findManyTeams : []}
        teamSearch={teamSearch}
        setTeamSearch={setTeamSearch}
      />

      <InspectionDetail
        isOpen={isInspectionPanelOpen}
        dismissPanel={() => {
          setIsInspectionPanelOpen(false);
        }}
        inspection={inspection}
        setInspection={setInspection}
        saveInspection={saveInspection}
        //
        refetchAsset={refetchAsset}
        asset={asset}
        //
        //
      />

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {asset && asset.id && (
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <StackItem>
                <Label>ID: {asset.id}</Label>
              </StackItem>
            </Stack>
          )}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <StackItem style={{ width: '100%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Algemeen</h3>
              <br />
              <Stack
                style={{
                  marginBottom: 25,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ width: '23%' }}>
                  <Checkbox
                    name='is_tool'
                    label='Gereedschap?'
                    checked={asset && asset.is_tool === true}
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        is_tool: asset && asset.is_tool ? !asset.is_tool : true,
                        is_sign: false,
                        is_ladder: false,
                        is_breaker: false,
                        breakers_nr: undefined,
                        breakers_type: undefined,
                      }));
                    }}
                  />
                </StackItem>

                <StackItem style={{ width: '23%' }}>
                  <Checkbox
                    name='is_sign'
                    label='Werfbord'
                    checked={asset && asset.is_sign === true}
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        is_sign: asset && asset.is_sign ? !asset.is_sign : true,
                        is_tool: false,
                        is_ladder: false,
                        is_breaker: false,
                        breakers_nr: undefined,
                        breakers_type: undefined,
                        make: undefined,
                      }));
                    }}
                  />
                </StackItem>

                <StackItem style={{ width: '23%' }}>
                  <Checkbox
                    name='is_ladder'
                    label='Ladder'
                    checked={asset && asset.is_ladder === true}
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        is_ladder:
                          asset && asset.is_ladder ? !asset.is_ladder : true,
                        is_sign: false,
                        is_tool: false,
                        is_breaker: false,
                        breakers_nr: undefined,
                        breakers_type: undefined,
                      }));
                    }}
                  />
                </StackItem>

                <StackItem style={{ width: '23%' }}>
                  <Checkbox
                    name='is_breaker'
                    label='Werfkast'
                    checked={asset && asset.is_breaker === true}
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        is_breaker:
                          asset && asset.is_breaker ? !asset.is_breaker : true,
                        is_sign: false,
                        is_ladder: false,
                        is_tool: false,
                        name: undefined,
                      }));
                    }}
                  />
                </StackItem>
              </Stack>
              <Stack
                style={{
                  marginBottom: 25,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {!asset.is_breaker && (
                  <StackItem style={{ width: '23.5%' }}>
                    <TextField
                      name='name'
                      label='Type'
                      value={asset && asset.name ? asset.name : ''}
                      onChange={onChangeTextFieldValue}
                      required={!asset.is_breaker}
                      errorMessage={
                        asset && !asset.is_breaker && !asset.name
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                    />
                  </StackItem>
                )}

                {asset.is_breaker && (
                  <StackItem style={{ width: '7%' }}>
                    <TextField
                      name='breakers_nr'
                      label='Nr'
                      value={
                        asset && asset.breakers_nr ? asset.breakers_nr : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                )}

                {asset.is_breaker && (
                  <StackItem style={{ width: '23.5%' }}>
                    <ComboboxWithFilter
                      label='Type'
                      options={[
                        {
                          key: 'Monofasig',
                          text: 'Monofasig',
                        },
                        { key: 'Driefasig', text: 'Driefasig' },
                      ]}
                      value={
                        asset && asset.breakers_type ? asset.breakers_type : ''
                      }
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          const result = { ...asset };
                          result.breakers_type = newValue[0].key;
                          setAsset(result);
                        }
                      }}
                      required={asset.is_breaker}
                      errorMessage={
                        asset && asset.is_breaker && !asset.breakers_type
                          ? 'Dit veld is verplicht'
                          : ''
                      }
                    />
                  </StackItem>
                )}

                {!asset.is_sign && (
                  <StackItem style={{ width: '23.5%' }}>
                    <SuggestedView
                      item={asset}
                      setItem={setAsset}
                      prefix='assets_merk_'
                      code={
                        asset &&
                        asset.make &&
                        asset.make.substr(asset.make.length - 4)
                      }
                      label='Merk'
                      field='make'
                    />
                  </StackItem>
                )}

                <StackItem style={{ width: '19%' }}>
                  <Label>Keuring</Label>
                  <Checkbox
                    name='inspectable'
                    label='Keuringsplichtig'
                    disabled={
                      (asset.last_inspection ||
                        (asset.inspections && asset.inspections.length > 0)) &&
                      asset.id
                    }
                    checked={asset && asset.inspectable === true}
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        inspectable:
                          asset && asset.inspectable
                            ? !asset.inspectable
                            : true,
                      }));
                    }}
                  />
                </StackItem>

                <StackItem style={{ width: '19%' }}>
                  <Label>Status</Label>
                  <Checkbox
                    name='active'
                    label='Uitgefaseerd'
                    checked={asset && asset.active === false} // Invert the condition
                    onChange={() => {
                      setAsset((prevState: any) => ({
                        ...prevState,
                        active: !(asset && asset.active),
                      }));
                    }}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>

          {asset && asset.is_tool === true && (
            <Stack
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <StackItem style={{ width: '100%' }}>
                <h3 style={{ marginTop: 0, marginBottom: 10 }}>
                  Gereedschap Informatie
                </h3>
                <Stack
                  style={{
                    marginBottom: 25,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StackItem style={{ width: '7%' }}>
                    <TextField
                      name='tools_nr'
                      label='Nr'
                      value={asset && asset.tools_nr ? asset.tools_nr : ''}
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                  <StackItem style={{ width: '22%' }}>
                    <SuggestedView
                      item={asset}
                      setItem={setAsset}
                      prefix='assets_kenmerk_'
                      code={
                        asset &&
                        asset.tools_reference &&
                        asset.tools_reference.substr(
                          asset.tools_reference.length - 4,
                        )
                      }
                      label='Kenmerk'
                      field='tools_reference'
                    />
                  </StackItem>

                  <StackItem style={{ width: '22%' }}>
                    <SuggestedView
                      item={asset}
                      setItem={setAsset}
                      prefix='assets_benaming_'
                      code={
                        asset &&
                        asset.tools_designation &&
                        asset.tools_designation.substr(
                          asset.tools_designation.length - 4,
                        )
                      }
                      label='Benaming'
                      field='tools_designation'
                    />
                  </StackItem>

                  <StackItem style={{ width: '22%' }}>
                    <TextField
                      name='tools_serial'
                      label='Serienummer'
                      value={
                        asset && asset.tools_serial ? asset.tools_serial : ''
                      }
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>

                  <StackItem style={{ width: '22%' }}>
                    <TextField
                      name='tools_year'
                      label='Bouwjaar'
                      value={
                        asset && asset.tools_year ? asset.tools_year : undefined
                      }
                      type='number'
                      onChange={onChangeTextFieldValue}
                    />
                  </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          )}

          <Stack>
            <h3 style={{ marginTop: 5, marginBottom: 10 }}>Opmerkingen</h3>
            <Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='comments'
                  multiline
                  value={asset && asset.comments ? asset.comments : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </Stack>
            <h3 style={{ marginTop: 5, marginBottom: 10 }}>
              Verantwoordelijke
            </h3>
            <Stack>
              <Stack style={{ marginBottom: 10 }}>
                <StackItem style={{ width: '23.5%' }}>
                  <ComboboxWithFilter
                    label=''
                    options={convertEmployeesToComboBoxOptions(
                      employees.filter((x: IEmployee) => x.active === true),
                    )}
                    value={asset && asset.owner ? asset.owner.id : ''}
                    multiline={false}
                    callBack={(newValue: IComboBoxOption[]) => {
                      if (newValue && newValue.length > 0) {
                        const result = { ...asset };

                        // If the result.owner exists, clone the owner object before modifying the id.
                        if (result.owner) {
                          result.owner = {
                            ...result.owner,
                            id: newValue[0].key as number,
                          };
                        } else {
                          result.owner = {
                            id: newValue[0].key as number,
                          };
                        }

                        setAsset(result);
                      }
                    }}
                    required
                    errorMessage={
                      asset && !asset.owner ? 'Dit veld is verplicht' : ''
                    }
                  />
                </StackItem>
              </Stack>
            </Stack>
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 30 }}>
            {asset && asset.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-log-items'
                  id='accordion-log-items'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Logs (Laatste 10)
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: async () => {
                          await saveAsset();
                          openLogItemDetail(true);
                        },
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: async () => {
                          await saveAsset();
                          openLogItemDetail();
                        },
                        theme: commandBarTheme,
                        disabled: !selectionLogItemDetails,
                      },
                      {
                        key: 'downloadXLS',
                        text: 'Excel',
                        iconProps: { iconName: 'ExcelDocument' },
                        onClick: () => downloadLogsFile(),
                        theme: commandBarTheme,
                        disabled:
                          !asset.asset_logs || asset.asset_logs.length === 0,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={asset.asset_logs || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Locatie',
                        fieldName: 'text',
                        minWidth: 150,
                        maxWidth: 150,
                        isRowHeader: true,
                        onRender: (asset_log: any) => (
                          <span>
                            {asset_log.team
                              ? `Ploeg: ${asset_log.team.name}`
                              : asset_log.construction_site
                              ? `Werf: ${asset_log.construction_site.name}`
                              : '3Bouw'}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Aangemaakt op',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (asset_log: any) => (
                          <span>
                            {asset_log.date_created &&
                              moment(new Date(asset_log.date_created)).format(
                                'DD/MM/YYYY',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionLogItem}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onItemInvoked={() => {
                      openLogItemDetail();
                    }}
                  />
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>Na opslaan kan je logs toevoegen en aanpassen.</Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='demo_logo_item'
                      id='demo_logo_item'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Logs (Laatste 10)
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>

          <Stack style={{ marginBottom: 10, marginTop: 10 }}>
            {asset && asset.id && (asset as IAsset).inspectable ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                <AccordionItem
                  key='accordion-inspections'
                  id='accordion-inspections'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Inspecties (Laatste 10)
                      </h3>
                    </Stack>
                  }
                >
                  <CommandBarSticky
                    items={[
                      {
                        key: 'new',
                        text: 'Nieuw',
                        iconProps: { iconName: 'Add' },
                        onClick: async () => {
                          await saveAsset();
                          openInspectionDetail(true);
                        },
                        theme: commandBarTheme,
                      },
                      {
                        key: 'modify',
                        text: 'Wijzig',
                        iconProps: { iconName: 'Edit' },
                        onClick: async () => {
                          await saveAsset();
                          openInspectionDetail();
                        },
                        theme: commandBarTheme,
                        disabled: !selectionInspectionDetails,
                      },
                      {
                        key: 'downloadXLS',
                        text: 'Excel',
                        iconProps: { iconName: 'ExcelDocument' },
                        onClick: () => downloadInspectionsFile(),
                        theme: commandBarTheme,
                        disabled:
                          !asset.inspections || asset.inspections.length === 0,
                      },
                    ]}
                    theme={commandBarTheme}
                    width='1200px'
                    maxWidth='1200px'
                  />
                  <ShimmeredDetailsList
                    items={asset.inspections || []}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Inspectie datum',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (inspection: IInspection) => (
                          <span>
                            {inspection.inspection_date &&
                              moment(
                                new Date(inspection.inspection_date),
                              ).format('DD/MM/YYYY')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Inspectie vervaldatum',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 130,
                        isRowHeader: true,
                        onRender: (inspection: IInspection) => (
                          <span>
                            {inspection.inspection_expiry_date &&
                              moment(
                                new Date(inspection.inspection_expiry_date),
                              ).format('DD/MM/YYYY')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Aangemaakt op',
                        fieldName: 'text',
                        minWidth: 100,
                        maxWidth: 100,
                        isRowHeader: true,
                        onRender: (inspection: IInspection) => (
                          <span>
                            {inspection.date_created &&
                              moment(new Date(inspection.date_created)).format(
                                'DD/MM/YYYY',
                              )}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selection={selectionInspection}
                    selectionMode={SelectionMode.single}
                    selectionPreservedOnEmptyClick
                    onItemInvoked={() => {
                      openInspectionDetail();
                    }}
                  />
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  {asset && asset.id
                    ? 'Zet keuringsplicht aan om inspecties toe te voegen'
                    : 'Na opslaan kan je inspecties toevoegen en aanpassen.'}
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key='demo_inspections'
                      id='demo_inspections'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Inspecties
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{ flexDirection: 'row', marginTop: 10, marginBottom: 5 }}
          >
            {permissions && permissions.includes('write:assets') && (
              <SaveButtonWithPermissions
                disabled={
                  !asset ||
                  (asset && !asset.name && !asset.is_breaker) ||
                  (asset && asset.is_breaker && !asset.breakers_type) ||
                  (!asset.is_breaker &&
                    !asset.is_tool &&
                    !asset.is_sign &&
                    !asset.is_ladder)
                }
                save={saveAsset}
                permission='write:assets'
              />
            )}
            <StackItem>
              <DefaultButton onClick={dismissPanel} style={{ marginRight: 10 }}>
                Annuleren
              </DefaultButton>
            </StackItem>

            {/* {asset && asset.id && (
              <StackItem>
                <DefaultButton
                  onClick={() => LeadDownload(lead as any, suggestedValues)}
                  style={{ marginRight: 10 }}
                >
                  download PDF TODO
                </DefaultButton>
              </StackItem>
            )} */}

            {asset && asset.id && (
              <StackItem>
                <DeleteButtonWithPermissions
                  disabled={!asset || !asset.id}
                  deleteFn={toggleDelete}
                  permission='write:assets'
                />
              </StackItem>
            )}
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default AssetDetail;
