import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { ISuggestedValue } from './SuggestedValue';

export const GET_SUGGESTED_VALUES_BY_CATEGORY_CODE = gql`
  query GetSuggestedValuesByCategoryCode($filter: SuggestedValueFilterInput) {
    findManySuggestedValues(filter: $filter) {
      id
      suggested_value
      suggested_value_code
      suggested_value_category {
        category_code
        category_description
        id
      }
    }
  }
`;

export const GET_SUGGESTED_VALUE_CATEGORIES = gql`
  query GetSuggestedValueCategories(
    $filter: SuggestedValueCategoryFilterInput
    $orderBy: SuggestedValueCategoryOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManySuggestedValueCategories(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      category_code
      category_description
      suggested_value_code_prefix
      suggested_value_with_code
      suggested_values {
        id
        suggested_value
        suggested_value_code
        active
      }
    }
  }
`;

export const GET_SUGGESTED_VALUE_CATEGORY_BY_ID = gql`
  query GetSuggestedValueCategoryByID(
    $where: SuggestedValueCategoryWhereInput
  ) {
    findOneSuggestedValueCategory(where: $where) {
      id
      category_code
      category_description
      suggested_value_code_prefix
      suggested_value_with_code
      suggested_values {
        id
        suggested_value
        suggested_value_code
        active
      }
    }
  }
`;

export const ADD_SUGGESTED_VALUE_CATEGORY = gql`
  mutation AddSuggestedValueCategory($data: SuggestedValueCatoryCreateInput!) {
    createSuggestedValueCategory(data: $data) {
      id
      category_code
      category_description
      suggested_value_code_prefix
      suggested_value_with_code
      suggested_values {
        id
        suggested_value
        suggested_value_code
        active
      }
    }
  }
`;

export const UPDATE_SUGGESTED_VALUE_CATEGORY = gql`
  mutation UpdateSuggestedValueCategory(
    $id: Int!
    $data: SuggestedValueCatoryUpdateInput!
  ) {
    updateSuggestedValueCategory(id: $id, data: $data) {
      id
      category_code
      category_description
      suggested_value_code_prefix
      suggested_value_with_code
      suggested_values {
        id
        suggested_value
        suggested_value_code
        active
      }
    }
  }
`;

export const convertSuggestedValueCategoriesToComboBoxOptions = (
  categories: ISuggestedValueCategory[],
) => {
  const result: IComboBoxOption[] = categories.map(
    (category: ISuggestedValueCategory): IComboBoxOption => ({
      key: category.id,
      text: category.category_description || '',
    }),
  );
  return result;
};

export const convertSuggestedValueCategoriesToComboBoxOptionsByCategoryCode = (
  categories: ISuggestedValueCategory[],
) => {
  const result: IComboBoxOption[] = categories.map(
    (category: ISuggestedValueCategory): IComboBoxOption => ({
      key: category.category_code || '',
      text: category.category_description || '',
    }),
  );
  return result;
};

export interface ISuggestedValueCategory {
  id: number;
  category_code?: string;
  category_description?: string;
  suggested_value_code_prefix?: string;
  suggested_value_with_code?: number;
  suggested_values?: ISuggestedValue[];
  dirty?: boolean;
  draft?: boolean;
  delete?: boolean;
}
