import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Briefpapier from '../../../Sjabloon-briefpapier.jpg';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import { ICustomerInvoiceLineItem } from '../../../utils/CustomerInvoiceLineItem';
import { displayNameLead } from '../../../utils/Lead';
import parseNumber from '../../../utils/Numbers';
import { normalizeFileNameBestelbon } from '../../../utils';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const addZero = (number: string) => {
  if (number.includes(',')) {
    const splitNumber = number.split(',');
    if (splitNumber[1].length === 1) return `${number}0`;
    return number;
  }
  return `${number},00`;
};

const CustomerInvoiceDownloadTemplate = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => {
  const showExtendedTableHeader =
    customerInvoice?.customer_invoice_line_items?.some(
      line => line.show_only_total !== true,
    );

  return (
    <Document
      title={`${normalizeFileNameBestelbon(
        `Factuur-${customerInvoice?.invoice_no || ''}-${
          customerInvoice.construction_site?.name || ''
        }`,
      )}.pdf`}
    >
      {customerInvoice &&
        customerInvoice.customer_payment_transaction &&
        customerInvoice.customer_payment_transaction.engineering_expense && (
          <Page size='A4' style={styles.page}>
            <View
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100vw',
                height: '100vh',
              }}
              fixed
            >
              <Image
                src={Briefpapier}
                style={{ width: '100%', height: '100%' }}
              />
            </View>

            {/* Header repeated on every page */}
            <PageHeader customerInvoice={customerInvoice} />

            <View style={styles.pageHeader}>
              <PageSupplierInfo customerInvoice={customerInvoice} />
            </View>
            {/* CustomerInvoice */}
            <View
              style={{
                marginTop: 40,
              }}
            >
              <Text style={styles.text}>
                Geachte,{'\n'}
                Beste {customerInvoice.lead && customerInvoice.lead.first_name1}
                ,{' '}
                {customerInvoice.lead && customerInvoice.lead.first_name2
                  ? ` ${customerInvoice.lead.first_name2},`
                  : ''}
              </Text>
              <Text style={styles.text}>
                Hartelijk dank voor uw vertrouwen in 3bouw.
              </Text>
              <Text style={styles.text}>
                Het 3bouw-team is verheugd om uw nieuwe woning te mogen
                realiseren.
              </Text>
              <Text style={styles.text}>
                Bijgevoegd vindt u het factuur studiekosten, zoals vermeld in
                onze overeenkomst.{'\n'}
                Het bedrag van deze factuur zal verrekend worden op de
                totaalprijs van de aanneming.
              </Text>
              <Text style={styles.text}>
                Inmiddels hebben wij{' '}
                {customerInvoice.construction_site.project_manager &&
                  `${customerInvoice.construction_site.project_manager.first_name} ${customerInvoice.construction_site.project_manager.last_name}`}{' '}
                aangeduid als uw dossierbeheerder, die u steeds mag contacteren
                als u vragen hebt aangaande uw bouwproject. Uiteraard staan{' '}
                {customerInvoice.lead &&
                  customerInvoice.lead.sales_rep_employee &&
                  `${customerInvoice.lead.sales_rep_employee.first_name} ${customerInvoice.lead.sales_rep_employee.last_name}`}{' '}
                en de directie, Jef en Paul, ook steeds ter uwer beschikking.
              </Text>
              <Text style={styles.text}>Tot weldra !</Text>
              <Text style={styles.text}>
                Vriendelijke groet vanwege het 3bouw-team !
              </Text>
            </View>
            {/* <PageCustomerInvoiceDeliveryDetails customerInvoice={customerInvoice} /> */}

            {/* Footer repeated on every page */}
            {/* Legal information */}
            {/* <PageLegalInfo /> */}
            <Text style={styles.now} fixed>
              {moment(new Date()).format('DD/MM/YYYY')}
            </Text>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `Pagina ${pageNumber} van ${totalPages - 1}`
              }
              fixed
            />
          </Page>
        )}
      <Page size='A4' style={styles.page}>
        <View
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100vw',
            height: '100vh',
          }}
          fixed
        >
          <Image src={Briefpapier} style={{ width: '100%', height: '100%' }} />
        </View>

        {/* Header repeated on every page */}
        <PageHeader customerInvoice={customerInvoice} />

        <View style={styles.pageHeader}>
          <PageSupplierInfo customerInvoice={customerInvoice} />
        </View>

        <View>
          <Text
            style={{
              paddingTop: 40,
              paddingRight: 5,
              fontFamily: 'Segoe UI',
              fontSize: 10,
              lineHeight: '120%',
            }}
          >
            {customerInvoice.intro}
          </Text>
        </View>
        {/* CustomerInvoice */}

        {customerInvoice.customer_invoice_line_items.some(
          line => line.show_only_total === false,
        ) ? (
          <PageCustomerInvoiceLinesWithAmount
            customerInvoice={customerInvoice}
          />
        ) : (
          <PageCustomerInvoiceLines
            customerInvoice={customerInvoice}
            show={showExtendedTableHeader}
          />
        )}

        <PageTotal customerInvoice={customerInvoice} />

        {/* <PageCustomerInvoiceDeliveryDetails customerInvoice={customerInvoice} /> */}

        {/* Footer repeated on every page */}
        {/* Legal information */}
        {/* <PageLegalInfo /> */}
        <Text style={styles.now} fixed>
          {moment(new Date()).format('DD/MM/YYYY')}
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Pagina ${pageNumber} van ${totalPages - 1}`
          }
          fixed
        />
      </Page>
      <Page size='A4' style={customStyles.page}>
        <View style={customStyles.top}>
          <Text>ALGEMENE VOORWAARDEN VAN 3BOUW BV</Text>
        </View>

        <View style={customStyles.tainer}>
          <View style={customStyles.columnl}>
            <Text style={customStyles.header}>
              Toepassing van onze algemene voorwaarden
            </Text>
            <Text style={customStyles.textheader}>
              1. Onze algemene voorwaarden zijn van toepassing op alle
              contracten die worden afgesloten, behoudens uitdrukkelijk
              andersluidend beding en met uitsluiting van de voorwaarden van de
              medecontractant, hierna genoemd klant. De algemene voorwaarden
              maken integraal deel uit van de contracten en er kan alleen
              schriftelijk van worden afgeweken.
            </Text>

            <Text style={customStyles.header}>
              Geldigheidsduur, prijzen, meerwerken{' '}
            </Text>
            <Text style={customStyles.textheader}>
              2.1 Onze offertes zijn slechts bindend wanneer zij door de
              bestuurder of zijn aangestelde werden ondertekend en blijven 1
              maand geldig. Elke aanpassing van het BTW-tarief gebeurt ten voor-
              of nadele van de opdrachtgever. 2.2 De technische informatie wordt
              voorafgaand aan de werken overhandigd door de opdrachtgever en/of
              zijn aangestelde (bv. architect of studiebureau). Deze informatie
              moet tijdig aan de aannemer bezorgd worden zodat hij over
              voldoende tijd beschikt om de werken naar behoren voor te
              bereiden. Bij gebreke hieraan kan de aannemer niet aangesproken
              worden voor vertragingen of termijnoverschrijding. De aannemer kan
              nooit aansprakelijk worden gesteld voor het niet behalen van het
              E-peil. 2.3 Onze prijs omvat alle werkzaamheden die nodig zijn
              voor de normale ingebruikname van het gebouw, met uitzondering
              van: aansluitingen voor gas, water, elektriciteit, riolering;
              nivelleringswerken aan het bouwterrein; toegangswegen;
              grondstudies; de kosten voor bronbemaling, tenzij uitdrukkelijk
              anders vermeld in onze offerte.
            </Text>

            <Text style={customStyles.text}>
              2.4 Prijsherzieningen worden voorzien teneinde de schommeling in
              de kosten van lonen en materialen te ondervangen die zich voordoen
              nadat de overeenkomst werd ondertekend. Indien zo bepaald in de
              overeenkomst is de overeengekomen prijs voor herziening vatbaar en
              dit in de hiernavolgende mate:
            </Text>

            <Text style={customStyles.text}>
              Het bedrag zonder BTW dat volgens de overeenkomst werd
              vastgesteld, wordt aangepast volgens de volgende formule: p = P x
              (a x s/S + b x i/I + c) waarbij a = 0,3, b = 0,5 en c = 0,2.
            </Text>

            <Text style={customStyles.text}>
              De term p is de nieuwe prijs na herziening dewelke wordt bekomen
              door de overeengekomen prijs (P) te vermenigvuldigen met het getal
              dat bekomen wordt na het uitvoeren van de bewerkingen die tussen
              haakjes staan. In de term ‘a x s/S’ is:
            </Text>

            <Text style={customStyles.text}>
              S = het laatst gepubliceerd gemiddeld uurloon op de datum van de
              ondertekening van de studieovereenkomst, verhoogd met het door het
              ministerie van Verkeer- en Infrastructuur op dezelfde datum
              aangenomen totaal percentage van de sociale lasten en
              verzekeringen; s = hetzelfde laatst gepubliceerd gemiddeld uurloon
              op datum van de start der werken, verhoogd met het door het
              ministerie van Verkeer- en Infrastructuur op dezelfde datum
              aangenomen totaal percentage van de sociale lasten en
              verzekeringen.
            </Text>

            <Text style={customStyles.text}>
              In de term ‘b x i/I’ vertegenwoordigen i en I het maandelijks
              indexcijfer I2021 vastgesteld door de Commissie van de prijslijst
              van de bouwmaterialen van het ministerie van Economische Zaken,
              meer bepaald:
            </Text>

            <Text style={customStyles.text}>
              I = het laatst gepubliceerd indexcijfer op de datum waarop de
              studieovereenkomst werd gesloten; i = het laatst gepubliceerd
              indexcijfer op datum van de start der werken.
            </Text>

            <Text style={customStyles.header}>Uitvoering van de werken </Text>
            <Text style={customStyles.textheader}>
              3.1 De specificaties omtrent de uitvoering van de werken zijn
              terug te vinden in ons algemeen standaard lastenboek
            </Text>

            <Text style={customStyles.header}>
              Gebreken en productaansprakelijkheid
            </Text>
            <Text style={customStyles.textheader}>
              4.2 Worden niet beschouwd als gebrek in de conformiteit of als een
              zichtbaar of verborgen gebrek: lichte verschillen in kleur,
              afmetingen of constructie van het goed, voor zover deze vanuit
              technisch oogpunt niet te voorkomen zijn of algemeen aanvaard zijn
              of eigen zijn aan de gebruikte materialen. 4.3 De
              aansprakelijkheid voor geleverde en gebruikte producten is beperkt
              tot de door de fabrikant verstrekte waarborgen.
            </Text>
          </View>
          <View style={customStyles.columnr}>
            <Text style={customStyles.header}>
              Sancties bij niet-nakoming van de contractuele verplichtingen van
              partijen
            </Text>
            <Text style={customStyles.textheader}>
              5.1 Wanneer de studieovereenkomst verbroken wordt door de klant
              behoudt de aannemer zich het recht een vergoeding te vragen die
              gelijk is aan de overeengekomen prijs studiekosten als vergoeding
              voor de geleverde prestaties tijdens de voorbereiding van het
              dossier.
            </Text>

            <Text style={customStyles.header}>Betaling </Text>
            <Text style={customStyles.textheader}>
              6.1 Onze facturen zijn betaalbaar binnen de 8 dagen na
              factuurdatum op de zetel van de aannemer. Al onze facturen worden
              geacht aanvaard te zijn wanneer zij niet binnen de 8 dagen na
              ontvangst bij aangetekend schrijven betwist worden.
            </Text>

            <Text style={customStyles.text}>
              6.2.1 Bij laattijdige betaling zal van rechtswege en zonder
              ingebrekestelling over de nog verschuldigde sommen een
              verwijlintrest betaald moeten worden a rato van 10 % per jaar,
              vanaf de vervaldag der respectieve facturen. Daarenboven is tevens
              van rechtswege en zonder ingebrekestelling een bijkomende
              schadevergoeding verschuldigd, conventioneel en forfaitair
              vastgesteld op 10 % van het onbetaald gebleven bedrag, met een
              minimum van 250,00 euro.
            </Text>

            <Text style={customStyles.text}>
              6.2.2 Indien de opdrachtgever een “consument” is in de zin van
              artikel I 1, 2° van het Wetboek van Economisch Recht, dan zal door
              de aannemer bij laattijdige betaling een eerste kosteloze
              herinnering aan de opdrachtgever worden verstuurd. Als de betaling
              niet plaatsvindt binnen 14 kalenderdagen vanaf de kalenderdag
              volgende op de datum van de herinnering verzonden via
              elektronische weg, worden de onbetaalde bedragen verhoogd met:
            </Text>

            <Text style={customStyles.text}>
              1. een verwijlsinterest tegen de in de wet van 2 augustus 2002
              betreffende de bestrijding van de betalingsachterstand bij
              handelstransacties bepaalde interestvoet, berekend naar rato van
              het aantal dagen betalingsachterstand vanaf de kalenderdag die
              volgt op de datum van verzending van voormelde kosteloze
              herinnering aan de consument;
            </Text>

            <Text style={customStyles.text}>
              2. alsook een forfaitaire vergoeding die gelijk is aan:
            </Text>

            <Text style={customStyles.text}>
              · Voor elke schuld tot en met 150 euro: 20 euro
            </Text>

            <Text style={customStyles.text}>
              · Voor elke schuld tussen 150,01 euro en 500 euro: 30 euro
              vermeerderd met 10 % van het verschuldigde bedrag op de schijf
              tussen 150,01 en 500 euro;
            </Text>

            <Text style={customStyles.text}>
              · Voor elke schuld boven 500 euro: 65 euro vermeerderd met 5% van
              het verschuldigd bedrag op de schijf boven 500 euro met een
              maximum van 2.000 euro.
            </Text>

            <Text style={customStyles.header}>GDPR</Text>
            <Text style={customStyles.textheader}>
              7.1 De persoonsgegevens van de opdrachtgevers worden door 3Bouw
              verwerkt met het oog op de uitvoering van deze overeenkomst, het
              klantenbeheer, de boekhouding en direct marketingactiviteiten. De
              rechtsgronden zijn de uitvoering van de overeenkomst, het
              vervullen van wettelijke en reglementaire verplichtingen en/of het
              gerechtvaardigd belang. De verwerkingsverantwoordelijke is 3Bouw
              gevestigd te 2260 Westerlo, Zone Reme 1. Deze persoonsgegevens
              zullen enkel worden doorgegeven aan verwerkers, ontvangers en/of
              derden voor zover dit noodzakelijk is in het kader van voormelde
              doeleinden voor de verwerking. De klant bevestigt dat hij afdoende
              werd geïnformeerd over de verwerking van zijn persoonsgegevens en
              over zijn rechten op de inzage, verbetering, het wissen en
              bezwaar. Voor meer informatie, zie ons privacy beleid, zoals terug
              te vinden op onze website.
            </Text>

            <Text style={customStyles.header}>
              Bevoegde rechtbank en toepasselijk recht{' '}
            </Text>
            <Text style={customStyles.textheader}>
              8.1. Alle geschillen die voortvloeien uit een overeenkomst zullen
              worden behandeld voor de rechtbank van het Arrondissement
              Antwerpen, afdeling Turnhout. 8.2 Al onze overeenkomsten worden
              beheerst door het Belgisch recht.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const PageHeader = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      {/* <Image src={Logo} style={styles.image} /> */}
      <View>
        <Text style={styles.pageHeaderTitle}>
          {customerInvoice.credit_note ? 'Creditnota' : 'Factuur'}{' '}
          {customerInvoice.invoice_no}
        </Text>
        <Text style={styles.pageHeaderName}>
          {customerInvoice && customerInvoice.construction_site && (
            <>Werf: {customerInvoice.construction_site.name}</>
          )}
        </Text>
      </View>
    </View>
  </View>
);

// const PageLegalInfo = () => (
//   <View style={[styles.legalInfo]} fixed wrap={false}>
//     <View style={styles.legalInfoRow}>
//       <Text>
//         <Text style={{ fontStyle: 'bold' }}>3Bouw BV</Text> Zone Reme 1, 2260
//         Westerlo <Text style={{ fontStyle: 'bold' }}>T</Text> 014/70 47 47{' '}
//         <Text style={{ fontStyle: 'bold' }}>E</Text> info@3bouw.be{' '}
//         <Text style={{ fontStyle: 'bold' }}>BTW</Text> BE 0884.733.931
//       </Text>
//     </View>
//     <View style={styles.legalInfoRow}>
//       <Text>
//         RPR Antwerpen, afd. Turnhout &bull; Erkend aannemer klasse 5 onder
//         erkenningsnr.: 32.454 <Text style={{ fontStyle: 'bold' }}>KBC</Text>{' '}
//         iban BE16 7310 2542 3974
//       </Text>
//     </View>
//   </View>
// );

const PageTotal = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => {
  const uniqueVatPercentages = [
    ...new Set(
      customerInvoice.customer_invoice_line_items.map(
        item => item.tax_code?.rate ?? item.percentage_vat ?? 0,
      ),
    ).values(),
  ];

  return (
    <View
      style={{
        paddingTop: 40,
        paddingRight: 5,
        fontFamily: 'Segoe UI',
        fontSize: 10,
        lineHeight: '120%',
      }}
      wrap={false}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: '65%',
        }}
      >
        <Text style={{ width: '50%' }}>Totaal excl. BTW:</Text>
        <Text style={{ width: '50%', textAlign: 'right' }}>
          &euro;{' '}
          {addZero(
            new Intl.NumberFormat('nl-BE', { maximumFractionDigits: 2 }).format(
              customerInvoice.customer_invoice_line_items.reduce(
                (previousValue, currentValue) =>
                  previousValue +
                  (currentValue.unit_price ? currentValue.unit_price : 0) *
                    (currentValue.units ? currentValue.units : 1),
                0,
              ),
            ),
          )}
        </Text>
      </View>

      {uniqueVatPercentages.map((percentage, i: number) => (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: 10,
            paddingLeft: '65%',
          }}
          key={`unique-vat-${i}`}
        >
          <Text style={{ width: '50%' }}>
            Totaal BTW {percentage > 1 ? percentage : percentage * 100}%:
          </Text>
          <Text style={{ width: '50%', textAlign: 'right' }}>
            &euro;{' '}
            {addZero(
              new Intl.NumberFormat('nl-BE', {
                maximumFractionDigits: 2,
              }).format(
                customerInvoice.customer_invoice_line_items
                  .filter(
                    x =>
                      (x.tax_code?.rate ?? x.percentage_vat ?? 0) ===
                      percentage,
                  )
                  .reduce((previousValue, currentValue) => {
                    const vatRate =
                      currentValue.tax_code?.rate ??
                      currentValue.percentage_vat ??
                      0;
                    const unitPrice = currentValue.unit_price ?? 0;
                    const units = currentValue.units ?? 1;
                    const vatAmount = (unitPrice * units * vatRate) / 100; // Calculate VAT amount separately
                    return previousValue + vatAmount; // Sum up the VAT amounts
                  }, 0),
              ),
            )}
          </Text>
        </View>
      ))}

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: 20,
          fontStyle: 'bold',
          paddingLeft: '65%',
        }}
      >
        <Text style={{ width: '50%' }}>Totaal incl. BTW:</Text>
        <Text style={{ width: '50%', textAlign: 'right' }}>
          &euro;{' '}
          {addZero(
            new Intl.NumberFormat('nl-BE', { maximumFractionDigits: 2 }).format(
              customerInvoice.customer_invoice_line_items.reduce(
                (previousValue, currentValue) => {
                  const unitPrice = currentValue.unit_price ?? 0; // Default to 0 if undefined
                  const units = currentValue.units ?? 1; // Default to 1 if undefined
                  const vatRate =
                    currentValue.tax_code?.rate ??
                    currentValue.percentage_vat ??
                    0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                  const vatMultiplier =
                    vatRate === 0
                      ? 1
                      : vatRate < 1
                      ? 1 + vatRate
                      : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

                  return previousValue + unitPrice * units * vatMultiplier; // Calculate total including VAT
                },
                0,
              ),
            ),
          )}
        </Text>
      </View>

      <View>
        <Text
          style={{
            paddingTop: 40,
            paddingRight: 5,
            fontFamily: 'Segoe UI',
            fontSize: 10,
            lineHeight: '120%',
          }}
        >
          {customerInvoice.tax_description}
        </Text>
      </View>

      <View>
        <Text
          style={{
            paddingTop: 40,
            paddingRight: 5,
            fontFamily: 'Segoe UI',
            fontSize: 10,
            lineHeight: '120%',
          }}
        >
          {customerInvoice.outro}
        </Text>
      </View>

      <View style={{ paddingTop: 30 }}>
        <Text>
          {customerInvoice.credit_note
            ? 'BTW terug te storten aan de staat in de mate dat deze oorspronkelijk in aftrek werd gebracht.'
            : 'Gelieve te betalen op onderstaande bankrekeningnummer met vermelding van het factuurnummer.'}
        </Text>
      </View>
    </View>
  );
};

const PageCustomerInvoiceLines = ({
  customerInvoice,
  show,
}: {
  customerInvoice: ICustomerInvoice;
  show: boolean;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    {show ? (
      <View style={styles.tableRowHeader} fixed>
        <Text style={styles.tableColDescription}>Omschrijving</Text>
        <Text style={styles.tableColBTW}>Aantal</Text>
        <Text style={styles.tableColBTW}>Eenheidsprijs</Text>
        <Text style={styles.tableColBTW}>BTW %</Text>
        <Text style={styles.tableColPrice}>Prijs excl. BTW</Text>
      </View>
    ) : (
      <View style={styles.tableRowHeader} fixed>
        <Text style={styles.tableColD}>Omschrijving</Text>
        <Text style={styles.tableColBTW}>BTW %</Text>
        <Text style={styles.tableColPrice}>Prijs excl. BTW</Text>
      </View>
    )}

    {/* Lines */}
    {customerInvoice.customer_invoice_line_items &&
    customerInvoice.customer_invoice_line_items.length > 0
      ? customerInvoice.customer_invoice_line_items.map(
          (line: ICustomerInvoiceLineItem, i: number) => (
            <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
              <Text
                style={show ? styles.tableColDescription : styles.tableColD}
              >
                {line.description}
              </Text>
              {show && (
                <Text style={styles.tableColBTW}>
                  {line.show_only_total === false
                    ? `${parseNumber(line.units)}`
                    : ''}
                </Text>
              )}
              {show && (
                <Text style={styles.tableColBTW}>
                  {line.show_only_total === false
                    ? `€ ${parseNumber(line.unit_price).toFixed(2)}`
                    : ''}
                </Text>
              )}
              <Text style={styles.tableColBTW}>
                {line.tax_code?.rate ?? line.percentage_vat
                  ? parseNumber(line.tax_code?.rate ?? line.percentage_vat) > 1
                    ? `${parseNumber(
                        line.tax_code?.rate ?? line.percentage_vat,
                      )}%`
                    : `${
                        parseNumber(
                          line.tax_code?.rate ?? line.percentage_vat,
                        ) * 100
                      }%`
                  : null}
              </Text>

              <Text style={styles.tableColPrice}>
                {line.unit_price
                  ? addZero(
                      `€ ${new Intl.NumberFormat('nl-BE', {
                        maximumFractionDigits: 2,
                      }).format(
                        parseNumber(line.unit_price) *
                          parseNumber(line.units, 1),
                      )}`,
                    )
                  : null}
              </Text>
            </View>
          ),
        )
      : null}
  </View>
);

const PageCustomerInvoiceLinesWithAmount = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColBTW}>Aantal</Text>
      <Text style={styles.tableColBTW}>Bedrag</Text>
      <Text style={styles.tableColBTW}>BTW %</Text>
      <Text style={styles.tableColPrice}>Prijs excl. BTW</Text>
    </View>

    {/* Lines */}
    {customerInvoice.customer_invoice_line_items &&
    customerInvoice.customer_invoice_line_items.length > 0
      ? customerInvoice.customer_invoice_line_items.map(
          (line: ICustomerInvoiceLineItem, i: number) => (
            <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
              <Text style={styles.tableColDescription}>{line.description}</Text>
              <Text style={styles.tableColBTW}>
                {line.units ? line.units : ''}
              </Text>
              <Text style={styles.tableColBTW}>
                {line.unit_price
                  ? addZero(
                      `€ ${new Intl.NumberFormat('nl-BE', {
                        maximumFractionDigits: 2,
                      }).format(parseNumber(line.unit_price))}`,
                    )
                  : null}
              </Text>
              <Text style={styles.tableColBTW}>
                {line.percentage_vat
                  ? parseNumber(line.percentage_vat) > 1
                    ? `${line.percentage_vat}%`
                    : `${parseNumber(line.percentage_vat) * 100}%`
                  : null}
              </Text>
              <Text style={styles.tableColPrice}>
                {line.unit_price
                  ? addZero(
                      `€ ${new Intl.NumberFormat('nl-BE', {
                        maximumFractionDigits: 2,
                      }).format(
                        parseNumber(line.unit_price) *
                          parseNumber(line.units, 1),
                      )}`,
                    )
                  : null}
              </Text>
            </View>
          ),
        )
      : null}
  </View>
);

const PageSupplierInfo = ({
  customerInvoice,
}: {
  customerInvoice: ICustomerInvoice;
}) => (
  <View style={{ paddingTop: '20px' }}>
    <View style={styles.supplierDetailsRow}>
      <View
        style={{
          flexDirection: 'column',
          width: '60%',
          overflow: 'hidden',
        }}
      >
        {customerInvoice.lead && (
          <Text>
            {displayNameLead(customerInvoice.lead)}
            {'\n'}
            {customerInvoice.lead.address1}
            {'\n'}
            {customerInvoice.lead.zip_code1} {customerInvoice.lead.city1}
          </Text>
        )}
      </View>

      {customerInvoice.lead && (
        <View style={styles.supplierDetailsRight}>
          <PageSupplierInfoRow
            label='Tel.'
            value={
              customerInvoice.lead &&
              (customerInvoice.lead.mobile1_v2 ||
                customerInvoice.lead.mobile1 ||
                customerInvoice.lead.mobile2_v2 ||
                customerInvoice.lead.mobile2 ||
                customerInvoice.lead.phone1_v2 ||
                customerInvoice.lead.phone1 ||
                customerInvoice.lead.phone2_v2 ||
                customerInvoice.lead.phone2)
              // (customerInvoice.lead.mobile1_v2 ||
              //   customerInvoice.lead.mobile2_v2 ||
              //   customerInvoice.lead.phone1_v2 ||
              //   customerInvoice.lead.phone2_v2)
            }
          />

          <PageSupplierInfoRow
            label='E-mail'
            value={customerInvoice.lead.email1 || customerInvoice.lead.email2}
          />
          <PageSupplierInfoRow
            label='Nummer'
            value={customerInvoice.invoice_no}
          />
          <PageSupplierInfoRow
            label='Datum'
            value={moment(new Date(customerInvoice.invoice_date)).format(
              'DD/MM/YYYY',
            )}
          />
          <PageSupplierInfoRow
            label='Vervaldatum'
            value={moment(new Date(customerInvoice.invoice_due_date)).format(
              'DD/MM/YYYY',
            )}
          />
          {customerInvoice.lead.vat && (
            <PageSupplierInfoRow
              label='BTW-nr'
              value={customerInvoice.lead.vat}
            />
          )}
        </View>
      )}
    </View>
  </View>
);

const PageSupplierInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <View style={styles.supplierDetailsRow}>
    <Text style={styles.supplierDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.supplierDetailsValueAlt}>{value}</Text>
  </View>
);

const PageInvoiceInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <View style={styles.invoiceDetailsRow}>
    <Text style={styles.invoiceDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.invoiceDetailsValueAlt}>{value}</Text>
  </View>
);

const customStyles = StyleSheet.create({
  page: {
    marginTop: 120,
  },
  tainer: {
    flexDirection: 'row',
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 10,
    paddingBottom: 0,
  },
  top: {
    padding: 4,
    borderBottom: '1px solid #949698',
    borderColor: '#949698',
    textAlign: 'center',
    fontSize: 9,
    color: '#949698',
    marginRight: 20,
    marginLeft: 50,
  },
  columnl: {
    width: '50%',
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'justify',
  },
  columnr: {
    width: '50%',
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: 'justify',
  },
  header: {
    color: '#76787a',
    fontSize: 7,
    fontWeight: 'bold',
    paddingBottom: 2,
  },
  text: {
    color: '#949698',
    fontSize: 7,
    paddingTop: 8,
    paddingBottom: 8,
    wordSpacing: 15,
    textAlign: 'justify',
  },
  textheader: {
    color: '#949698',
    fontSize: 7,
    paddingBottom: 8,
    wordSpacing: 3,
    textAlign: 'justify',
  },
});

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  invoiceDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  invoiceDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '20%',
  },
  invoiceDetailsValueAlt: {
    width: '80%',
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoRow: {
    flexDirection: 'row',
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 55,
    right: 40,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 20,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 43,
    left: 0,
    right: 40,
    textAlign: 'right',
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '11%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '40%',
  },
  supplierDetailsRight: {
    width: '49%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  supplierDetailsValueAlt: {
    width: '59%',
  },
  text: {
    paddingBottom: 15,
    paddingRight: 5,
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '40%',
    paddingRight: '15px',
  },
  tableColD: {
    width: '60%',
  },
  tableColDescriptionWithAmount: {
    width: '45%',
  },
  tableColBTW: {
    width: '15%',
    textAlign: 'left',
  },
  tableColBTWWithAmount: {
    width: '15%',
  },
  tableColPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableColP: {
    width: '15%',
    textAlign: 'left',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
});

export default CustomerInvoiceDownloadTemplate;
