import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  IObjectWithKey,
  ISelection,
  SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { ICar } from '../../../utils/Car';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import { displayNameLead } from '../../../utils/Lead';

interface props {
  items: ICustomerInvoice[];
  selection?: ISelection<IObjectWithKey> | undefined;
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
}

const CustomerInvoicesDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
}: props) => {
  // On Column Click
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Column List
  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Factuurdatum',
      fieldName: 'invoice_date',
      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.invoice_date &&
            moment(new Date(customerInvoice.invoice_date)).format('YYYY-MM-DD')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Factuur nr',
      fieldName: 'invoice_no',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>{customerInvoice && customerInvoice.invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Klant',
      fieldName: 'lead',
      minWidth: 175,
      maxWidth: 175,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.lead && displayNameLead(customerInvoice.lead)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Betaalbaar voor',
      fieldName: 'invoice_due_date',
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          {customerInvoice.invoice_due_date &&
            moment(new Date(customerInvoice.invoice_due_date)).format(
              'YYYY-MM-DD',
            )}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Betaald',
      fieldName: 'invoice_paid',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        /* const invoiceAmount = customerInvoice.customer_invoice_line_items
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue +
              (!currentValue.percentage_vat ||
              +currentValue.percentage_vat === 0
                ? currentValue.unit_price * currentValue.units
                : +currentValue.percentage_vat < 1
                ? currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat)
                : currentValue.unit_price *
                  currentValue.units *
                  (1 + currentValue.percentage_vat / 100)),
            0,
          )
          .toFixed(2);

        const invoiceAmountPayment = customerInvoice.customer_invoice_payments
          .reduce(
            (previousValue: any, currentValue: any) =>
              previousValue + currentValue.amount,
            0,
          )
          .toFixed(2); */

        <span>
          <Checkbox disabled checked={!!customerInvoice.invoice_paid} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Totaal excl. BTW',
      fieldName: 'unit_price',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce(
              (previousValue, currentValue) =>
                previousValue + currentValue.unit_price * currentValue.units,
              0,
            )
            .toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Totaal incl. BTW',
      fieldName: 'unit_price_with_vat',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => (
        <span>
          &euro;{' '}
          {customerInvoice.customer_invoice_line_items
            .reduce((previousValue, currentValue) => {
              const unitPrice = currentValue.unit_price ?? 0; // Default to 0 if undefined
              const units = currentValue.units ?? 1; // Default to 1 if undefined
              const vatRate =
                currentValue.tax_code?.rate ?? currentValue.percentage_vat ?? 0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

              const vatMultiplier =
                vatRate === 0
                  ? 1
                  : vatRate < 1
                  ? 1 + vatRate
                  : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

              return previousValue + unitPrice * units * vatMultiplier; // Calculate total including VAT
            }, 0)
            .toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Open saldo',
      fieldName: 'open_saldo',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => {
        const invoiceAmount = customerInvoice.customer_invoice_line_items
          .reduce((previousValue, currentValue) => {
            const unitPrice = currentValue.unit_price ?? 0; // Default to 0 if undefined
            const units = currentValue.units ?? 1; // Default to 1 if undefined
            const vatRate =
              currentValue.tax_code?.rate ?? currentValue.percentage_vat ?? 0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

            const vatMultiplier =
              vatRate === 0 ? 1 : vatRate < 1 ? 1 + vatRate : 1 + vatRate / 100; // Calculate VAT multiplier based on rate

            return previousValue + unitPrice * units * vatMultiplier; // Calculate total including VAT
          }, 0)
          .toFixed(2);

        const invoiceAmountPayment = customerInvoice.customer_invoice_payments
          .reduce(
            (previousValue, currentValue) =>
              previousValue + (currentValue.amount ?? 0),
            0,
          ) // Ensure amount is treated as a number, defaulting to 0 if undefined
          .toFixed(2);

        return (
          <span
            style={{
              color:
                +(invoiceAmount - invoiceAmountPayment).toFixed(2) < 0
                  ? 'orange'
                  : '',
            }}
          >
            &euro; {(invoiceAmount - invoiceAmountPayment).toFixed(2)}
          </span>
        );
      },
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column9',
      name: 'Betalingsschijf',
      fieldName: 'unit_price',
      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      onRender: (customerInvoice: ICustomerInvoice) => {
        if (
          customerInvoice &&
          customerInvoice.customer_payment_transaction &&
          customerInvoice.customer_payment_transaction.id
        ) {
          return (
            <a
              href={`/payment-transactions/${customerInvoice.customer_payment_transaction.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <DefaultButton>Bekijk schijf</DefaultButton>
            </a>
          );
        }
      },
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default CustomerInvoicesDetailsList;
