import { gql } from '@apollo/client';
import { IAsset } from './Asset';
import { ITeam } from './Team';
import { IConstructionSite } from './ConstructionSite';

export interface IAssetLog {
  id: number;
  comments?: string;

  asset_id?: number;
  asset?: IAsset;

  team_id?: number;
  team?: ITeam;

  construction_site_id?: number;
  construction_site?: IConstructionSite;

  date_created?: Date;

  last?: boolean;

  prisma_total?: number;
}

export const GET_ASSET_LOGS = gql`
  query getAssetLogs(
    $filter: AssetLogFilterInput
    $orderBy: AssetLogOrderByInput
    $take: Int
    $skip: Int
  ) {
    findManyAssetLogs(
      filter: $filter
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      comments

      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }

      team_id
      team {
        id
        name
      }

      construction_site_id
      construction_site {
        id
        name
      }

      date_created
      last
      prisma_total
    }
  }
`;

export const GET_ASSET_LOG_BY_ID = gql`
  query getAssetLogById($where: AssetLogWhereInput) {
    findOneAssetLog(where: $where) {
      id
      comments

      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }

      team_id
      team {
        id
        name
      }

      construction_site_id
      construction_site {
        id
        name
      }
      last
      date_created
    }
  }
`;

export const ADD_ASSET_LOG = gql`
  mutation AddAssetLog($data: AssetLogCreateInput!) {
    createAssetLog(data: $data) {
      id
      comments

      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }

      team_id
      team {
        id
        name
      }

      construction_site_id
      construction_site {
        id
        name
      }
      last
      date_created
    }
  }
`;

export const ADD_ASSET_LOG_GROUP = gql`
  mutation AddAssetLogGroup($data: AssetLogGroupCreateInput!) {
    createAssetLogGroup(data: $data)
  }
`;

export const UPDATE_ASSET_LOG = gql`
  mutation UpdateAssetLog($id: Int!, $data: AssetLogUpdateInput!) {
    updateAssetLog(id: $id, data: $data) {
      id
      comments

      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }

      team_id
      team {
        id
        name
      }

      construction_site_id
      construction_site {
        id
        name
      }
      last
      date_created
    }
  }
`;

export const DELETE_ASSET_LOG = gql`
  mutation deleteAssetLog($where: AssetLogWhereInput) {
    deleteAssetLog(where: $where) {
      id
    }
  }
`;
