import { useAuth0 } from '@auth0/auth0-react';
import {
  DatePicker,
  Icon,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import useDebounce from '../../components/hooks/useDebounce';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  default_page_size,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { sendNotification } from '../../redux/notification/notificationSlice';
import { FilterState } from '../../components/parts/FilterPanel';
import { toastError, toastInfo, toastSuccess } from '../../utils/toast';
import ConstructionSitesOverview from './components/ConstructionSitesOverview';

interface IPagedConstructionSites {
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
  lastCall?: number;
}

const ConstructionSites = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const [pagedState, setPagedState] = useState<IPagedConstructionSites>({
    skip: 0,
    take: 20,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'creation_date',
  });

  let isDirectie = false;

  for (let i = 0; i < (user ? user['3bouw/roles'].length : 0); i++) {
    if (
      (user as any)['3bouw/roles'][i] === 'SuperAdmin' ||
      (user as any)['3bouw/roles'][i] === 'Directie'
    ) {
      isDirectie = true;
    }
  }

  const [datePicker, setDatePicker] = useState({
    from: moment().startOf('year').format('YYYY-MM-DD'),
    till: moment().endOf('year').format('YYYY-MM-DD'),
  });
  const [filter, setFilter] = useState('');
  const [constructionSiteFilter, setConstructionSiteFilter] = useState<
    FilterState | undefined
  >();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  }, [filter, constructionSiteFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = useCallback(
    (filterString: string) => {
      const filterArray = filterString.split(' ');

      const filters: any = {
        AND: [],
      };

      if (constructionSiteFilter && constructionSiteFilter.schema.length > 0) {
        for (let i = 0; i < constructionSiteFilter.schema.length; i++) {
          for (
            let t = 0;
            t < constructionSiteFilter.schema[i].fields.length;
            t++
          ) {
            if (
              constructionSiteFilter.schema[i].fields[t].value &&
              constructionSiteFilter.schema[i].fields[t].parsedValue &&
              constructionSiteFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(
                constructionSiteFilter.schema[i].fields[t].parsedValue,
              );
            }
          }
        }
      }

      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { name: { contains: filterArray[i] } },
            { normalized_name: { contains: filterArray[i] } },
            { normalized_name: { contains: normalizeString(filterArray[i]) } },
            { address: { contains: filterArray[i] } },
            { city: { contains: filterArray[i] } },
          ],
        };

        const numericValue = parseInt(filterArray[i], 10);
        if (numericValue) {
          filterValue.OR.push({ zip_code: { equals: +numericValue } });
        }

        filters.AND.push(filterValue);
      }

      return filters;
    },
    [filter, constructionSiteFilter],
  );

  const downloadFile = useCallback(async () => {
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'template.updateTemplate',
        spinner: true,
      }),
    );
    const url = `${process.env.REACT_APP_BACKEND_URI}/files/constructionsites/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `Constructionsites_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ filter: getFilters(filter) }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    dispatch(
      sendNotification({
        module: 'template.updateTemplate',
        message: 'Bestand is gedownload',
        level: 1,
        timeout: 2500,
      }),
    );
  }, [filter, constructionSiteFilter]);

  const downloadExcelAnalyse = useCallback(async () => {
    const res2 = await toast.promise(
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_BACKEND_URI}/files/analyse/xls`;
        const token = await getAccessTokenSilently();
        const fileName = `Analyse${moment().format('YYYYMMDD')}.xlsx`;
        const res = await fetch(url, {
          method: 'post',
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            from: datePicker.from,
            till: datePicker.till,
          }),
        });

        const result = await res.blob();

        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        const href = URL.createObjectURL(result);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);

        resolve(true);
      }),
      {
        pending: {
          position: DEFAULT_TOAST_POSITION,
          render() {
            return DEFAULT_LOADING_MESSAGE;
          },
        },
      },
      {
        autoClose: DEFAULT_TOAST_DURATION,
      },
    );

    await res2;

    toastSuccess('Bestand is gedownload');
  }, [datePicker]);

  const variables = {
    filter: getFilters(debouncedValue),
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    data: dataConstructionSites,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: 0,
      orderBy: getOrderBy(),
    },
    onError: error => {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    },
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onDatePickerValueChangeFrom = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue || '';
    setDatePicker(prevState => ({
      ...prevState,
      from: filterString,
    }));
  };

  const onDatePickerValueChangeTill = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue || '';
    setDatePicker(prevState => ({
      ...prevState,
      till: filterString,
    }));
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearConstructionSites();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearConstructionSites = () => {
    setPagedState(prevState => ({
      ...prevState,
      constructionSites: [],
      skip: 0,
    }));
  };

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const fetchMoreConstructionSites = () => {
    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='MapPin' style={{ fontSize: '20px' }} />
          Werven
        </h3>

        <Stack horizontal tokens={{ childrenGap: 15 }}>
          <TextField
            label='Zoeken...'
            value={filter}
            styles={textFieldStyles300}
            onChange={onSearchValueChange}
          />
          {isDirectie && (
            <>
              <TextField
                label='Oplevering vanaf'
                type='date'
                value={datePicker.from}
                onChange={onDatePickerValueChangeFrom}
              />
              <TextField
                label='Oplevering tot'
                type='date'
                value={datePicker.till}
                onChange={onDatePickerValueChangeTill}
              />
              <PrimaryButton
                text='Download'
                iconProps={{ iconName: 'ExcelDocument' }}
                onClick={downloadExcelAnalyse}
                style={{ marginTop: '28px' }}
                disabled={!datePicker.from || !datePicker.till}
              />
            </>
          )}
        </Stack>
      </div>

      <ConstructionSitesOverview
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        loading={loading}
        //
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        //
        showLoadMore
        loadMore
        loadMoreCallback={fetchMoreConstructionSites}
        lazyLoading
        //
        downloadFile={downloadFile}
        //
        listFilters={variables}
        setConstructionSiteFilter={setConstructionSiteFilter}
        //
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        //
        refetch={refetch}
      />
    </Stack>
  );
};

export default ConstructionSites;
