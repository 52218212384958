import {
  Panel,
  PanelType,
  Stack,
  TextField,
  StackItem,
  DefaultButton,
  Checkbox,
  IComboBoxOption,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Label,
} from '@fluentui/react';
import { Editor } from '@tinymce/tinymce-react';
import { IMailTemplate } from '../../utils/MailTemplate';
import { customPanelStyles } from '../../theme';
import { modalContentStyles } from '../../utils';
import SaveButtonWithPermissions from '../../components/parts/SaveButtonWithPermissions';
import { ComboboxWithFilter } from '../../components/parts';
import { toastSuccess } from '../../utils/toast';

interface Props {
  isOpen: any;
  dismissPanel: any;
  saveTemplate: any;
  template: IMailTemplate | undefined;
  setTemplate: any;
  refetch: any;
}

const MailDetail = ({
  isOpen,
  dismissPanel,
  saveTemplate,
  template,
  setTemplate,
  refetch,
}: Props) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setTemplate((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        template && template.id
          ? 'Mail sjabloon wijzigen'
          : 'Mail sjabloon toevoegen'
      }
      type={PanelType.custom}
      customWidth='1000px'
      styles={customPanelStyles}
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <p>ID: {template?.id}</p>
          <Stack>
            <Stack style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                name='active'
                label='Actief?'
                checked={template && template.active}
                onChange={() => {
                  setTemplate((prevState: any) => ({
                    ...prevState,
                    active:
                      template && template.active ? !template.active : true,
                  }));
                }}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <ComboboxWithFilter
                label='Type'
                options={[
                  {
                    key: 'Uitgaande factuur',
                    text: 'Uitgaande factuur',
                  },
                  { key: 'Bestelbon', text: 'Bestelbon' },
                ]}
                value={template && template.type ? template.type : ''}
                multiline={false}
                callBack={(newValue: IComboBoxOption[]) => {
                  if (newValue && newValue.length > 0) {
                    setTemplate((prevState: any) => ({
                      ...prevState,
                      type: newValue[0].key,
                    }));
                  }
                }}
                required
                errorMessage={
                  template && !template.type ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={template && template.name ? template.name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  template && !template.name ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='comments'
                label='Beschrijving'
                value={template && template.comments ? template.comments : ''}
                onChange={onChangeTextFieldValue}
                multiline
                rows={2}
              />
            </Stack>
            {template?.type === 'Uitgaande factuur' && (
              <>
                <Label>Variabelen</Label>
                <div
                  style={{ border: '1px solid #565655', borderRadius: '2px' }}
                >
                  <DetailsList
                    items={[
                      {
                        key: '0',
                        naam: 'Factuur nummer',
                        waarde: 'invoice_no',
                        voorbeeld: '402.24',
                      },
                      {
                        key: '1',
                        naam: 'Factuur datum',
                        waarde: 'invoice_date',
                        voorbeeld: '20/06/2024',
                      },

                      {
                        key: '2',
                        naam: 'Werf naam',
                        waarde: 'construction_site_name',
                        voorbeeld: 'Poortmans - Van de Vondel',
                      },
                      {
                        key: '3',
                        naam: 'Werf address',
                        waarde: 'construction_site_address',
                        voorbeeld: 'Cuperuslei 2 bus 1',
                      },
                      {
                        key: '4',
                        naam: 'Werf postcode',
                        waarde: 'construction_site_zip_code',
                        voorbeeld: '2220',
                      },
                      {
                        key: '5',
                        naam: 'Werf plaats',
                        waarde: 'construction_site_city',
                        voorbeeld: 'Heist-op-den-Berg',
                      },
                      {
                        key: '6',
                        naam: 'Klant naam',
                        waarde: 'customer_name',
                        voorbeeld: 'Hans en Ellen',
                      },
                    ]}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Naam',
                        fieldName: 'naam',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                      },
                      {
                        key: 'column2',
                        name: 'Waarde',
                        fieldName: 'waarde',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                        onRender: item => (
                          <span
                            style={{
                              color: '#71BF44',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            role='button'
                            tabIndex={0}
                            onClick={() => {
                              navigator.clipboard.writeText(`[${item.waarde}]`);
                              toastSuccess(
                                `Copied "${item.waarde}" to clipboard!`,
                              );
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                navigator.clipboard.writeText(
                                  `[${item.waarde}]`,
                                );
                                toastSuccess(
                                  `Copied "${item.waarde}" to clipboard!`,
                                );
                              }
                            }}
                          >
                            {item.waarde}
                          </span>
                        ),
                      },
                      {
                        key: 'column3',
                        name: 'Voorbeeld',
                        fieldName: 'voorbeeld',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                      },
                    ]}
                    setKey='set'
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none} // Disable selection dots
                  />
                </div>
                <br />
              </>
            )}

            {template?.type === 'Bestelbon' && (
              <>
                <Label>Variabelen</Label>
                <div
                  style={{ border: '1px solid #565655', borderRadius: '2px' }}
                >
                  <DetailsList
                    items={[
                      {
                        key: '0',
                        naam: 'Bestelbon nummer',
                        waarde: 'purchase_order_no',
                        voorbeeld: '529.24',
                      },
                      {
                        key: '2',
                        naam: 'Werf naam',
                        waarde: 'construction_site_name',
                        voorbeeld: 'Poortmans - Van de Vondel',
                      },
                      {
                        key: '3',
                        naam: 'Werf address',
                        waarde: 'construction_site_address',
                        voorbeeld: 'Cuperuslei 2 bus 1',
                      },
                      {
                        key: '4',
                        naam: 'Werf postcode',
                        waarde: 'construction_site_zip_code',
                        voorbeeld: '2220',
                      },
                      {
                        key: '5',
                        naam: 'Werf plaats',
                        waarde: 'construction_site_city',
                        voorbeeld: 'Heist-op-den-Berg',
                      },
                      {
                        key: '6',
                        naam: 'Klant naam',
                        waarde: 'customer_name',
                        voorbeeld: 'Hans en Ellen',
                      },
                    ]}
                    columns={[
                      {
                        key: 'column1',
                        name: 'Naam',
                        fieldName: 'naam',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                      },
                      {
                        key: 'column2',
                        name: 'Waarde',
                        fieldName: 'waarde',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                        onRender: item => (
                          <span
                            style={{
                              color: '#71BF44',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            role='button'
                            tabIndex={0}
                            onClick={() => {
                              navigator.clipboard.writeText(`[${item.waarde}]`);
                              toastSuccess(
                                `Copied "${item.waarde}" to clipboard!`,
                              );
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                navigator.clipboard.writeText(
                                  `[${item.waarde}]`,
                                );
                                toastSuccess(
                                  `Copied "${item.waarde}" to clipboard!`,
                                );
                              }
                            }}
                          >
                            {item.waarde}
                          </span>
                        ),
                      },
                      {
                        key: 'column3',
                        name: 'Voorbeeld',
                        fieldName: 'voorbeeld',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                      },
                    ]}
                    setKey='set'
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none} // Disable selection dots
                  />
                </div>
                <br />
              </>
            )}
            <Stack>
              <Label>Body</Label>
              <Editor
                apiKey='y8v65wfkd6uxajrh03buxibzkyrxwywwe3ggcccybjg1ry4d'
                init={{
                  height: 400,
                  menubar: true,
                  menu: {
                    file: { title: 'File', items: 'newdocument' },
                    edit: {
                      title: 'Edit',
                      items: 'undo redo | cut copy paste pastetext | selectall',
                    },
                    insert: { title: 'Insert', items: 'link emplate' },
                    format: {
                      title: 'Format',
                      items: 'bold italic underline',
                    },
                  },
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'help',
                    'wordcount',
                  ],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                }}
                value={template && template.body ? template.body : ''}
                onEditorChange={(e: any) => {
                  setTemplate((prevState: any) => ({
                    ...prevState,
                    body: e,
                  }));
                }}
              />
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !template ||
                (template && !template.name) ||
                (template && !template.body) ||
                (template && !template.type)
              }
              save={saveTemplate}
              permission='write:mailTemplates'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default MailDetail;
