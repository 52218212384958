import React, {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
 Icon, Stack, TextField, Toggle,
} from '@fluentui/react';
import moment from 'moment';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import {
  GET_CUSTOMER_INVOICES,
  ICustomerInvoice,
} from '../../utils/CustomerInvoice';
import DateView from '../../components/parts/DateView';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { sendNotification } from '../../redux/notification/notificationSlice';
import { toastError, toastInfo, toastSuccess } from '../../utils/toast';
import CustomerInvoicesOverview from './components/CustomerInvoicesOverview';

interface IPagedCustomerInvoice {
  customerInvoices: ICustomerInvoice[];
  skip: number;
  take: number;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const CustomerInvoices = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedCustomerInvoice>({
    customerInvoices: [],
    skip: 0,
    take: default_page_size,
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: false,
    sortedField: 'invoice_date',
  });

  const [filter, setFilter] = useState('');
  const [isPaid, setIsPaid] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start_date: '',
    end_date: '',
  });
  const today = new Date().toDateString();

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      customerInvoices: [],
      skip: 0,
    }));
  }, [filter, dateFilter, isPaid]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string

    if (pagedState.sortedField === 'invoice_date') {
      const secondOrderByObject: any = {}; // deliberately kept any as type to index by string

      orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
        ? 'asc'
        : 'desc';

      // eslint-disable-next-line dot-notation
      secondOrderByObject['invoice_no'] = pagedState.isSortedAsc
        ? 'asc'
        : 'desc';

      return [orderByObject, secondOrderByObject];
    }

    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (
    filterString: string,
    dates: {
      start_date: string;
      end_date: string;
    },
  ) => {
    const filterArray = filterString.split(' ');

    const filters: any = {
      AND: [
        {
          invoice_paid: isExpired ? false : isPaid === true ? false : undefined,
        },
      ],
    };

    if (dates.start_date || dates.end_date) {
      filters.AND.push({
        invoice_date: {
          gte: dates.start_date
            ? moment(new Date(dates.start_date))
            : undefined,
          lte: dates.end_date
            ? moment(new Date(dates.end_date)).endOf('days')
            : undefined,
        },
      });
    }

    if (isExpired) {
      filters.AND.push({
        invoice_due_date: {
          lte: moment(new Date(today)),
        },
      });
    }

    if (filterArray.length !== 0) {
      for (let i = 0; i < filterArray.length; i++) {
        const filterValue: any = {
          OR: [
            { invoice_no: { contains: filterArray[i] } },
            {
              lead: {
                OR: [
                  { first_name1: { contains: filterArray[i] } },
                  { first_name2: { contains: filterArray[i] } },
                  { last_name1: { contains: filterArray[i] } },
                  { last_name2: { contains: filterArray[i] } },
                  { normalized_first_name1: { contains: filterArray[i] } },
                  { normalized_first_name2: { contains: filterArray[i] } },
                  { normalized_last_name1: { contains: filterArray[i] } },
                  { normalized_last_name2: { contains: filterArray[i] } },
                  {
                    normalized_first_name1: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_first_name2: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_last_name1: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                  {
                    normalized_last_name2: {
                      contains: normalizeString(filterArray[i]),
                    },
                  },
                ],
              },
            },
            { construction_site: { name: { contains: filterArray[i] } } },
          ],
        };
        filters.AND.push(filterValue);
      }
    }
    return filters;
  };

  const downloadFile = useCallback(async () => {
    toastInfo('Bezig met downloaden');

    const url = `${process.env.REACT_APP_BACKEND_URI}/files/customer-invoices/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `invoices_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ filter: getFilters(filter, dateFilter) }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    toastSuccess('Bestand is gedownload');
  }, [filter, dateFilter]);

  const {
    data: dataCustomerInvoices,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_CUSTOMER_INVOICES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, dateFilter),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    // notifyOnNetworkStatusChange: true,
    onError: error => {
      toastError(error.message);
    },
    // fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearCustomerInvoices();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const onChangeFilterPaid = () => {
    setIsPaid(!isPaid);
    setIsExpired(false);
    clearCustomerInvoices();
  };

  const onChangeFilterExpired = () => {
    setIsPaid(false);
    setIsExpired(!isExpired);
    clearCustomerInvoices();
  };

  const clearCustomerInvoices = () => {
    setPagedState(prevState => ({
      ...prevState,
      customerInvoices: [],
      skip: 0,
    }));
  };

  const fetchMoreCustomerInvoices = () => {
    // Load more guard

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, dateFilter), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <div style={{ padding: '25px' }}>
        <h3
          style={{
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Icon iconName='ClosePaneMirrored' style={{ fontSize: '20px' }} />
          Uitgaande facturen
        </h3>

        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <Stack style={{ flexDirection: 'row' }}>
          <Stack style={{ flexDirection: 'row', marginRight: 70 }}>
            <Stack style={{ marginBottom: 5, marginRight: 15 }}>
              <DateView
                item={dateFilter}
                setItem={setDateFilter}
                date={dateFilter && dateFilter.start_date}
                label='Startdatum'
                field='start_date'
                onChangeDate={(date: string) =>
                  setDateFilter(prevState => ({
                    ...prevState,
                    start_date: date,
                  }))
                }
              />
            </Stack>

            <Stack style={{ marginBottom: 5 }}>
              <DateView
                item={dateFilter}
                setItem={setDateFilter}
                date={dateFilter && dateFilter.end_date}
                label='Einddatum'
                field='end_date'
                onChangeDate={(date: string) =>
                  setDateFilter(prevState => ({ ...prevState, end_date: date }))
                }
              />
            </Stack>
          </Stack>

          <Stack style={{ width: 160 }}>
            <Toggle
              label='Niet betaald?'
              checked={isPaid}
              onText='Niet betaald'
              offText='Alle'
              onChange={onChangeFilterPaid}
            />
          </Stack>

          <Toggle
            label='Vervallen?'
            checked={isExpired}
            onText='Vervallen'
            offText='Niet vervallen'
            onChange={onChangeFilterExpired}
          />
        </Stack>
      </div>

      <CustomerInvoicesOverview
        customerInvoices={
          dataCustomerInvoices
            ? dataCustomerInvoices.findManyCustomerInvoices
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCustomerInvoices}
        lazyLoading={pagedState.lazyLoading}
        refetch={refetch}
        downloadFile={downloadFile}
      />
    </Stack>
  );
};

export default CustomerInvoices;
