import { gql } from '@apollo/client';
import { IEmployee } from './Employee';
import { IInspection } from './Inspection';
import { IAssetLog } from './AssetLog';

export interface IAsset {
  id: number;
  name?: string;
  make?: string;
  comments?: string;

  is_tool: boolean;
  is_sign: boolean;
  is_ladder: boolean;
  is_breaker: boolean;

  tools_reference?: string;
  tools_nr?: string;
  tools_designation?: string;
  tools_serial?: string;
  tools_year?: number;

  breakers_nr?: string;
  breakers_type?: string;

  inspectable: boolean;
  active: boolean;

  owner_id?: number;
  owner?: IEmployee;

  inspections: IInspection[];
  asset_logs: IAssetLog[];

  last_inspection?: IInspection;
  last_log?: IAssetLog;

  prisma_total?: number;
}

export const GET_ASSETS = gql`
  query getAsset(
    $filter: AssetFilterInput
    $take: Int
    $skip: Int
    $orderBy: AssetOrderByInput
  ) {
    findManyAssets(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      name
      make
      comments

      is_tool
      is_sign
      is_ladder
      is_breaker

      tools_reference
      tools_nr
      tools_designation
      tools_serial
      tools_year

      breakers_nr
      breakers_type

      inspectable
      active

      owner_id
      owner {
        id
        first_name
        last_name
      }

      inspections {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
        last
      }

      asset_logs {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
        last
      }

      last_inspection {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
      }
      last_log {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
      }

      prisma_total
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query getAssetById($where: AssetWhereInput) {
    findOneAsset(where: $where) {
      id
      name
      make
      comments

      is_tool
      is_sign
      is_ladder
      is_breaker

      tools_reference
      tools_nr
      tools_designation
      tools_serial
      tools_year

      breakers_nr
      breakers_type

      inspectable
      active

      owner_id
      owner {
        id
        first_name
        last_name
      }

      inspections {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
        last
      }

      asset_logs {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }

        date_created
        last
      }

      last_inspection {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
      }
      last_log {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
      }
    }
  }
`;

export const ADD_ASSET = gql`
  mutation addAsset($data: AssetCreateInput!) {
    createAsset(data: $data) {
      id
      name
      make
      comments

      is_tool
      is_sign
      is_ladder
      is_breaker

      tools_reference
      tools_nr
      tools_designation
      tools_serial
      tools_year

      breakers_nr
      breakers_type

      inspectable
      active

      owner_id
      owner {
        id
        first_name
        last_name
      }

      inspections {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
        last
      }

      asset_logs {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
        last
      }

      last_inspection {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
      }
      last_log {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
      }
    }
  }
`;

export const UPDATE_ASSET = gql`
  mutation updateAsset($id: Int!, $data: AssetUpdateInput) {
    updateAsset(id: $id, data: $data) {
      id
      name
      make
      comments

      is_tool
      is_sign
      is_ladder
      is_breaker

      tools_reference
      tools_nr
      tools_designation
      tools_serial
      tools_year

      breakers_nr
      breakers_type

      inspectable
      active

      owner_id
      owner {
        id
        first_name
        last_name
      }

      inspections {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
        last
      }

      asset_logs {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
        last
      }

      last_inspection {
        id
        comments
        inspection_date
        inspection_expiry_date
        date_created
      }
      last_log {
        id
        comments

        team_id
        team {
          id
          name
        }

        construction_site_id
        construction_site {
          id
          name
        }
        date_created
      }
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation deleteAsset($where: AssetWhereInput) {
    deleteAsset(where: $where) {
      id
    }
  }
`;
