import { gql } from '@apollo/client';
import { IAsset } from './Asset';

export interface IInspection {
  id: number;
  comments?: string;

  inspection_date?: Date;
  inspection_expiry_date?: Date;
  date_created?: Date;

  asset_id?: number;
  asset?: IAsset;
  last?: boolean;
  prisma_total?: number;
}

export const GET_INSPECTIONS = gql`
  query getInspection(
    $filter: InspectionFilterInput
    $take: Int
    $skip: Int
    $orderBy: InspectionOrderByInput
  ) {
    findManyInspections(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      comments
      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }
      inspection_date
      inspection_expiry_date
      date_created
      last
      prisma_total
    }
  }
`;

export const GET_INSPECTION_BY_ID = gql`
  query getInspectionById($where: InspectionWhereInput) {
    findOneInspection(where: $where) {
      id
      comments
      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }
      inspection_date
      inspection_expiry_date
      last
      date_created
    }
  }
`;

export const ADD_INSPECTION = gql`
  mutation addInspection($data: InspectionCreateInput!) {
    createInspection(data: $data) {
      id
      comments
      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }
      last
      inspection_date
      inspection_expiry_date
      date_created
    }
  }
`;

export const ADD_INSPECTION_GROUP = gql`
  mutation addInspectionGroup($data: InspectionGroupCreateInput!) {
    createInspectionGroup(data: $data)
  }
`;

export const UPDATE_INSPECTION = gql`
  mutation updateInspection($id: Int!, $data: InspectionUpdateInput) {
    updateInspection(id: $id, data: $data) {
      id
      comments
      asset_id
      asset {
        id
        name
        make
        comments

        is_tool
        is_sign
        is_ladder
        is_breaker

        tools_reference
        tools_nr
        tools_designation
        tools_serial
        tools_year

        inspectable
        active
      }
      inspection_date
      inspection_expiry_date
      last
      date_created
    }
  }
`;

export const DELETE_INSPECTION = gql`
  mutation deleteInspection($where: InspectionWhereInput) {
    deleteInspection(where: $where) {
      id
    }
  }
`;
