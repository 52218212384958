import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  Selection,
  ShimmeredDetailsList,
  CommandBar,
  DetailsListLayoutMode,
  SelectionMode,
  Icon,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import _, { xorBy } from 'lodash';
import { toast } from 'react-toastify';
import {
  convertConstructionSitesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  iconProps,
  IConstructionSite,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme, customPanelStyles } from '../../../theme';
import {
  DELETE_CUSTOMER_INVOICE_LINE_ITEM,
  ICustomerInvoiceLineItem,
  ICustomerInvoiceLineItemCreateWithoutCustomerInvoice,
  ICustomerInvoiceLineItemUpdateWithoutCustomerInvoice,
} from '../../../utils/CustomerInvoiceLineItem';
import {
  ADD_CUSTOMER_INVOICE_PAYMENT,
  DELETE_CUSTOMER_INVOICE_PAYMENT,
  GET_CUSTOMER_INVOICE_PAYMENT_BY_ID,
  ICustomerInvoicePayment,
  UPDATE_CUSTOMER_INVOICE_PAYMENT,
} from '../../../utils/CustomerInvoicePayment';
import {
  ADD_CUSTOMER_INVOICE,
  GET_CUSTOMER_INVOICE_BY_ID,
  ICustomerInvoice,
  ICustomerInvoiceCreate,
  UPDATE_CUSTOMER_INVOICE,
} from '../../../utils/CustomerInvoice';
import useDebounce from '../../../components/hooks/useDebounce';
import {
  convertLeadsToComboBoxOptions,
  GET_LEADS,
  GET_LEAD_BY_ID,
  ILead,
} from '../../../utils/Lead';
import {
  GET_CUSTOMER_CONTRACT_UPDATES,
  ICustomerContractUpdate,
} from '../../../utils/CustomerContractUpdate';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import parseNumber from '../../../utils/Numbers';
import {
  GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
  IConstructionSiteCreateUpdate,
} from '../../../utils/ConstructionSite';
import { toastSuccess, toastError } from '../../../utils/toast';
import { ITaxCode } from '../../../utils/TaxCode';
import { GET_MAIL_LOG_BY_ID, IMailLog } from '../../../utils/MailLog';
import InvoiceLineDetail from './InvoiceLineDetail';
import PaymentDetail from './PaymentDetail';
import CustomerInvoicesDynamicPdf from './CustomerInvoiceDynamicPdf';
import MailDetail from './MailDetail';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  customerInvoiceSource: ICustomerInvoiceCreate;
  customerInvoiceId?: number;
  constructionSite?: IConstructionSite | IConstructionSiteCreateUpdate;
  skipFetchConstructionSites?: boolean;
  tax_codes: ITaxCode[];
};

const CustomerInvoiceDetail = ({
  isOpen,
  dismissPanel,
  // saveCustomerInvoice,
  customerInvoiceSource = {},
  customerInvoiceId,
  constructionSite,
  skipFetchConstructionSites,
  tax_codes,
}: Props) => {
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceAmountPayed, setInvoiceAmountPayed] = useState(0);
  const [customerFilter, setCustomerFilter] = useState('');
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const [showDeleteLineItemDialog, setShowDeleteLineItemDialog] =
    useState(false);

  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = useState(false);

  const [customerInvoice, setCustomerInvoice] =
    useState<ICustomerInvoiceCreate>(customerInvoiceSource);

  const [showPdf, setShowPdf] = useState(false);

  useQuery(GET_CUSTOMER_INVOICE_BY_ID, {
    variables:
      (customerInvoiceSource && customerInvoiceSource.id) || customerInvoiceId
        ? { where: { id: customerInvoiceSource.id || customerInvoiceId } }
        : undefined,
    skip: !customerInvoiceSource && !customerInvoiceId,
    onCompleted: data => {
      if (data && data.findOneCustomerInvoice) {
        setCustomerInvoice(data.findOneCustomerInvoice);
      }
    },
  });

  const debouncedCustomerFilter = useDebounce(customerFilter, 500);
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { data: constructionSitesData } = useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        OR: [
          ...(customerInvoice?.construction_site?.id
            ? [
                {
                  id: {
                    in: [customerInvoice.construction_site.id],
                  },
                },
              ]
            : []),
          {
            name: {
              contains: debouncedFilter,
            },
          },
        ],
      },
    },
  });

  const constructionSites =
    constructionSitesData && constructionSitesData.findManyConstructionSites;

  const [addCustomerInvoice] = useMutation(ADD_CUSTOMER_INVOICE, {
    refetchQueries: [
      {
        query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
        variables: {
          where: {
            id:
              skipFetchConstructionSites &&
              constructionSite &&
              constructionSite.id
                ? constructionSite.id
                : customerInvoice.construction_site
                ? customerInvoice.construction_site.id
                : undefined,
          },
        },
      },
    ],
  });
  const [modifyCustomerInvoice] = useMutation(UPDATE_CUSTOMER_INVOICE, {
    refetchQueries: [
      {
        query: GET_CONSTRUCTION_SITE_BY_ID_DETAILS,
        variables: {
          where: {
            id:
              skipFetchConstructionSites &&
              constructionSite &&
              constructionSite.id
                ? constructionSite.id
                : customerInvoice.construction_site
                ? customerInvoice.construction_site.id
                : undefined,
          },
        },
      },
    ],
  });

  const [deleteLineItem, { loading: deletingLineItem }] = useMutation(
    DELETE_CUSTOMER_INVOICE_LINE_ITEM,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER_INVOICE_BY_ID,
          variables:
            customerInvoiceSource && customerInvoiceSource.id
              ? {
                  where: { id: customerInvoiceSource.id },
                }
              : undefined,
        },
      ],
    },
  );

  const saveCustomerInvoice = async () => {
    try {
      if (customerInvoice) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const constructionSiteID =
              skipFetchConstructionSites &&
              constructionSite &&
              constructionSite.id
                ? constructionSite.id
                : customerInvoice.construction_site
                ? customerInvoice.construction_site.id
                : undefined;

            // if (!constructionSiteID) return; // notification??  [19/09/23] -> nope, we need to be able to save without a construction site

            const createCustomerInvoiceUpdates: {
              contract_update: { connect: { id: number } };
            }[] = [];
            const deleteCustomerInvoiceUpdates: { id?: number }[] = [];
            const createInvoiceLineItems: ICustomerInvoiceLineItemCreateWithoutCustomerInvoice[] =
              [];
            const deleteInvoiceLineItems: { id?: number }[] = [];
            const updateInvoiceLineItems: {
              where: { id?: number };
              data: ICustomerInvoiceLineItemUpdateWithoutCustomerInvoice;
            }[] = [];

            if (
              customerInvoice &&
              customerInvoice.customer_invoice_contract_updates
            ) {
              for (
                let i = 0;
                i < customerInvoice.customer_invoice_contract_updates.length;
                i++
              ) {
                if (
                  customerInvoice.customer_invoice_contract_updates[i]
                    .connect &&
                  customerInvoice.customer_invoice_contract_updates[i]
                    .customer_contract_update_id
                ) {
                  createCustomerInvoiceUpdates.push({
                    contract_update: {
                      connect: {
                        id: customerInvoice.customer_invoice_contract_updates[i]
                          .customer_contract_update_id,
                      },
                    },
                  });
                } else if (
                  customerInvoice.customer_invoice_contract_updates[i]
                    .disconnect &&
                  customerInvoice.customer_invoice_contract_updates[i].id
                ) {
                  deleteCustomerInvoiceUpdates.push({
                    id: customerInvoice.customer_invoice_contract_updates[i].id,
                  });
                }
              }
            }

            if (
              customerInvoice &&
              customerInvoice.customer_invoice_line_items
            ) {
              for (
                let i = 0;
                i < customerInvoice.customer_invoice_line_items.length;
                i++
              ) {
                const item = customerInvoice.customer_invoice_line_items[i];

                if (item && item.isNew && !item.delete) {
                  // add new line item
                  createInvoiceLineItems.push({
                    comments: item.comments,
                    description: item.description || '',
                    percentage_vat: parseNumber(
                      item.tax_code?.rate
                        ? item.tax_code?.rate
                        : item.percentage_vat,
                    ),
                    show_only_total: item.show_only_total,
                    units: parseNumber(item.units),
                    unit_price:
                      parseNumber(item.unit_price) &&
                      parseNumber(item.unit_price),
                    // connect customer_contract_update_line_item for later reference & deletion
                    customer_contract_update_line_item:
                      item.customer_contract_update_line_item && {
                        connect: {
                          id: item.customer_contract_update_line_item.id!,
                        },
                      },
                    tax_code: item.tax_code?.id
                      ? {
                          connect: {
                            id: item.tax_code?.id,
                          },
                        }
                      : undefined,
                  });
                } else if (item && item.delete && !item.isNew && item.isDirty) {
                  // delete line item
                  console.log(item);
                  deleteInvoiceLineItems.push({ id: item.id });
                } else if (item.isDirty && !item.isNew) {
                  // update lin eitem
                  console.log(item);
                  updateInvoiceLineItems.push({
                    where: { id: item.id },
                    data: {
                      comments: item.comments,
                      description: item.description || '',
                      percentage_vat: parseNumber(
                        item.tax_code?.rate
                          ? item.tax_code?.rate
                          : item.percentage_vat,
                      ),
                      show_only_total: item.show_only_total,
                      units: item.units && +item.units,
                      unit_price: item.unit_price && +item.unit_price,
                      tax_code: item.tax_code?.id
                        ? {
                            connect: {
                              id: item.tax_code?.id,
                            },
                          }
                        : undefined,
                    },
                  });
                }
              }
            }

            const allInput: any = {
              invoice_due_date: customerInvoice.invoice_due_date,
              invoice_paid: customerInvoice.invoice_paid,
              sent: customerInvoice.sent,
              credit_note: customerInvoice.credit_note,
              invoice_date: customerInvoice.invoice_date,
              intro: customerInvoice.intro,
              outro: customerInvoice.outro,
              tax_description: customerInvoice.tax_description,
              construction_site: constructionSiteID
                ? {
                    connect: {
                      id: constructionSiteID,
                    },
                  }
                : undefined,
              lead:
                customerInvoice.lead && customerInvoice.lead.id
                  ? {
                      connect: { id: customerInvoice.lead.id },
                    }
                  : undefined,
              customer_invoice_line_items: {
                create: createInvoiceLineItems,
              },
              customer_invoice_contract_updates: {
                create: createCustomerInvoiceUpdates,
                // disconnect: disconnectCustomerInvoiceUpdates,
              },
            };

            if (customerInvoice.id) {
              allInput.customer_invoice_contract_updates = {
                create: createCustomerInvoiceUpdates,
                delete: deleteCustomerInvoiceUpdates,
              };

              allInput.customer_invoice_line_items = {
                create: createInvoiceLineItems,
                delete: deleteInvoiceLineItems,
                update: updateInvoiceLineItems,
              };

              modifyCustomerInvoice({
                variables: {
                  id: customerInvoice.id,
                  data: allInput,
                },
                onError: error => {
                  setConstructionSiteFilter('');
                  setCustomerFilter('');
                  dismissPanel();
                  reject(error);
                },
                onCompleted: x => {
                  setConstructionSiteFilter('');
                  setCustomerFilter('');
                  dismissPanel();
                  resolve(x);
                },
              });
            } else {
              addCustomerInvoice({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setConstructionSiteFilter('');
                  setCustomerFilter('');
                  dismissPanel();
                  reject(error);
                },
                onCompleted: x => {
                  setConstructionSiteFilter('');
                  setCustomerFilter('');
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createCustomerInvoice) {
          setCustomerInvoice(x.createCustomerInvoice);
        }
        toastSuccess('Factuur opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const getLeadFilter = useCallback(() => {
    const filter: any = {
      AND: [],
    };
    if (debouncedCustomerFilter) {
      const stringArray = debouncedCustomerFilter.split(' ');
      for (let i = 0; i < stringArray.length; i++) {
        filter.AND = [
          ...filter.AND,
          {
            OR: [
              {
                first_name1: {
                  contains: stringArray[i],
                },
              },
              {
                first_name2: {
                  contains: stringArray[i],
                },
              },
              {
                last_name1: {
                  contains: stringArray[i],
                },
              },
              {
                last_name2: {
                  contains: stringArray[i],
                },
              },
            ],
          },
        ];
      }
    }

    return filter;
  }, [debouncedCustomerFilter]);

  const { data: customersData } = useQuery(GET_LEADS, {
    // fetchPolicy: 'no-cache',
    variables: {
      take: debouncedCustomerFilter.length > 0 ? undefined : 10,
      filter: getLeadFilter(),
    },
  });

  const { data: customerData } = useQuery(GET_LEAD_BY_ID, {
    // fetchPolicy: 'no-cache',
    variables:
      customerInvoice && customerInvoice.lead
        ? { where: { id: customerInvoice.lead.id } }
        : undefined,
    skip: !customerInvoice || !customerInvoice.lead,
  });

  const { data: customerContractUpdatesData } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATES,
    {
      variables:
        customerInvoice &&
        customerInvoice.construction_site &&
        customerInvoice.construction_site.id
          ? {
              filter: {
                AND: [
                  {
                    construction_site: {
                      id: { equals: customerInvoice.construction_site.id },
                    },
                  },
                  {
                    OR: [
                      { status: { equals: 'INVOICED' } },
                      { status: { equals: 'COMPLETED' } },
                    ],
                  },
                ],
              },
            }
          : undefined,
      skip:
        !customerInvoiceSource ||
        !customerInvoice.id ||
        !customerInvoice.construction_site ||
        !customerInvoice.construction_site.id,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        console.log(data);
      },
    },
  );

  useEffect(() => {
    if (customerInvoice) {
      if (customerInvoice.customer_invoice_line_items) {
        setInvoiceAmount(
          parseNumber(
            customerInvoice.customer_invoice_line_items
              .filter(item => !item.delete)
              .reduce((previousValue, currentValue) => {
                const unitPrice = currentValue.unit_price || 0; // Default to 0 if undefined
                const units = currentValue.units || 0; // Default to 0 if undefined
                const vatRate =
                  currentValue.tax_code?.rate ??
                  currentValue.percentage_vat ??
                  0; // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined

                const vatMultiplier =
                  vatRate === 0
                    ? 1
                    : +vatRate < 1
                    ? 1 + +vatRate
                    : 1 + +vatRate / 100; // Calculate VAT multiplier based on rate

                return previousValue + +unitPrice * +units * vatMultiplier; // Calculate total including VAT
              }, 0)
              .toFixed(2), // Convert the total to a string with two decimal places
          ),
        );
      }
      if (customerInvoice.customer_invoice_payments) {
        setInvoiceAmountPayed(
          customerInvoice.customer_invoice_payments
            .reduce(
              (previousValue: any, currentValue: any) =>
                previousValue + currentValue.amount,
              0,
            )
            .toFixed(2),
        );
      }
    }
  }, [customerInvoice]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCustomerInvoice((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();

  // Invoice line
  const [isInvoiceLinePanelOpen, setIsInvoiceLinePanelOpen] = useState(false);
  const [invoiceLine, setInvoiceLine] = useState<
    ICustomerInvoiceLineItem | undefined
  >({});

  const getSelectionInvoiceLineDetails = () => {
    const currentInvoiceLineSelection: any =
      selectionInvoiceLine.getSelection();

    if (currentInvoiceLineSelection.length > 0) {
      setInvoiceLine(currentInvoiceLineSelection[0]);
    } else {
      setInvoiceLine(undefined);
    }
  };

  const selectionInvoiceLine = new Selection({
    onSelectionChanged: getSelectionInvoiceLineDetails,
  });

  // ============================================================================
  // ============================================================================
  // ============================================================================

  function getUniqueTaxCodeDescriptionsByUniqueId(
    invoice: ICustomerInvoice,
  ): string {
    // Use a Map to store unique tax codes by ID
    const taxCodes = new Map<number, string>();

    // Iterate over each line item in the invoice
    invoice.customer_invoice_line_items.forEach(item => {
      // Check if the item has a tax code and the tax code has a description
      if (item.tax_code && item.tax_code.description) {
        // Add the tax code ID and description to the Map
        // This automatically handles uniqueness based on tax code ID
        taxCodes.set(item.tax_code.id, item.tax_code.description);
      }
    });

    // Extract the descriptions from the Map and join them with two line breaks
    return Array.from(taxCodes.values()).join('\n\n');
  }

  const openInvoiceLineDetail = (newInvoiceLine?: boolean) => {
    if (newInvoiceLine) {
      setInvoiceLine({
        isNew: true,
      });
    }
    setIsInvoiceLinePanelOpen(true);
  };

  const deleteSelection = useCallback(async () => {
    try {
      if (invoiceLine) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deleteLineItem({
              variables: {
                where: {
                  id: invoiceLine.id,
                },
              },
              onError: error => {
                setShowDeleteLineItemDialog(false);
                reject(error);
              },
              onCompleted: data => {
                setShowDeleteLineItemDialog(false);
                resolve(data);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await res;
        toastSuccess('Lijnitem verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [invoiceLine]);

  const saveInvoiceLine = () => {
    const currentCustomerInvoice = _.cloneDeep(customerInvoice);
    console.log('save executed');
    if (invoiceLine) {
      if (
        invoiceLine &&
        invoiceLine.id &&
        currentCustomerInvoice.customer_invoice_line_items
      ) {
        console.log('weird conditional');
        // get invoice line index
        const index =
          currentCustomerInvoice.customer_invoice_line_items.findIndex(
            item => item.id === invoiceLine.id,
          );
        currentCustomerInvoice.customer_invoice_line_items[index] = {
          ...invoiceLine,
          percentage_vat: parseNumber(
            invoiceLine?.tax_code?.rate
              ? invoiceLine?.tax_code?.rate
              : invoiceLine.percentage_vat,
          ),
          unit_price: parseNumber(invoiceLine.unit_price),
          units: parseNumber(invoiceLine.units),
          tax_code: invoiceLine.tax_code,
        };
      } else {
        // add to object
        const newLineItem = _.cloneDeep(invoiceLine);
        if (!newLineItem.id) newLineItem.id = moment().unix();
        if (newLineItem.percentage_vat) {
          newLineItem.percentage_vat = parseNumber(newLineItem.percentage_vat);
        }
        if (newLineItem.unit_price) {
          newLineItem.unit_price = parseNumber(newLineItem.unit_price);
        }
        if (newLineItem.units) {
          newLineItem.units = parseNumber(newLineItem.units);
        }

        if (currentCustomerInvoice.customer_invoice_line_items) {
          currentCustomerInvoice.customer_invoice_line_items.push(newLineItem);
        } else {
          currentCustomerInvoice.customer_invoice_line_items = [newLineItem];
        }
      }
    }

    setCustomerInvoice(currentCustomerInvoice);

    setIsInvoiceLinePanelOpen(false);
  };

  // ============================================================================
  // ============================================================================
  // ============================================================================

  const [isMailPanelOpen, setIsMailPanelOpen] = useState(false);
  const [mail, setMail] = useState<any>();
  const [selectionMailDetails, setSelectionMailDetails] = useState<
    IMailLog | undefined
  >();

  const getSelectionMailDetails = () => {
    const currentMailSelection: any = selectionMail.getSelection();
  };

  const selectionMail = new Selection({
    onSelectionChanged: getSelectionMailDetails,
  });

  useQuery(GET_MAIL_LOG_BY_ID, {
    variables: selectionMailDetails
      ? { where: { id: selectionMailDetails.id } }
      : undefined,
    skip: !selectionMailDetails,
  });

  const openMailDetail = (newMail?: boolean) => {
    if (newMail) {
      setMail(undefined);
    }
    setIsMailPanelOpen(true);
  };

  const saveMail = () => {
    toastSuccess('Mail verzonden');
    console.log(mail);
    // setIsMailPanelOpen(false);
  };

  // ============================================================================
  // ================================= P A Y M E N T=============================
  // ============================================================================

  const [isPaymentPanelOpen, setIsPaymentPanelOpen] = useState(false);
  const [payment, setPayment] = useState<any>();
  const [selectionPaymentDetails, setSelectionPaymentDetails] = useState<
    ICustomerInvoicePayment | undefined
  >();

  const getSelectionPaymentDetails = () => {
    const currentPaymentSelection: any = selectionPayment.getSelection();

    if (currentPaymentSelection.length > 0) {
      setSelectionPaymentDetails(currentPaymentSelection[0]);
    } else {
      setSelectionPaymentDetails(undefined);
    }
  };

  const selectionPayment = new Selection({
    onSelectionChanged: getSelectionPaymentDetails,
  });

  useQuery(GET_CUSTOMER_INVOICE_PAYMENT_BY_ID, {
    variables: selectionPaymentDetails
      ? { where: { id: selectionPaymentDetails.id } }
      : undefined,
    skip: !selectionPaymentDetails,
    onCompleted: data => {
      setPayment(data.findOneCustomerInvoicePayment);
    },
  });

  const openPaymentDetail = (newPayment?: boolean) => {
    if (newPayment) {
      setPayment(undefined);
    }
    setIsPaymentPanelOpen(true);
  };

  const [addPayment] = useMutation(ADD_CUSTOMER_INVOICE_PAYMENT, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_INVOICE_BY_ID,
        variables:
          customerInvoiceSource && customerInvoiceSource.id
            ? {
                where: { id: customerInvoiceSource.id },
              }
            : undefined,
      },
    ],
  });
  const [modifyPayment] = useMutation(UPDATE_CUSTOMER_INVOICE_PAYMENT, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_INVOICE_BY_ID,
        variables:
          customerInvoiceSource && customerInvoiceSource.id
            ? {
                where: { id: customerInvoiceSource.id },
              }
            : undefined,
      },
    ],
  });

  const [deletePayment, { loading: deletingPayment }] = useMutation(
    DELETE_CUSTOMER_INVOICE_PAYMENT,
    {
      refetchQueries: [
        {
          query: GET_CUSTOMER_INVOICE_BY_ID,
          variables:
            customerInvoiceSource && customerInvoiceSource.id
              ? {
                  where: { id: customerInvoiceSource.id },
                }
              : undefined,
        },
      ],
    },
  );

  const deletePaymentSelection = useCallback(async () => {
    try {
      if (selectionPaymentDetails) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            deletePayment({
              variables: {
                where: {
                  id: selectionPaymentDetails.id,
                },
              },
              onError: error => {
                setShowDeletePaymentDialog(false);
                reject(error);
              },
              onCompleted: data => {
                setShowDeletePaymentDialog(false);
                resolve(data);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Betaling verwijderd');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  }, [selectionPaymentDetails]);

  const savePayment = async () => {
    try {
      if (payment) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            console.log(payment);

            const allInput = {
              comments: payment.comments,
              amount: parseNumber(payment.amount),
              payment_date: payment.payment_date,
              customer_invoice: {
                connect: { id: customerInvoice.id },
              },
            };

            console.log(allInput);

            if (payment.id) {
              modifyPayment({
                variables: {
                  id: payment.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPaymentPanelOpen(false);
                  reject(error);
                },
                onCompleted: data => {
                  setIsPaymentPanelOpen(false);
                  resolve(data);
                },
              });
            } else {
              addPayment({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPaymentPanelOpen(false);
                  reject(error);
                },
                onCompleted: data => {
                  setIsPaymentPanelOpen(false);
                  resolve(data);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Betaling opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // ============================================================================
  // ============================================================================
  // ============================================================================

  const getConstructionSiteComboBoxOptions = useCallback(() => {
    let constructionSiteArray: IConstructionSite[] = [];

    if (
      constructionSitesData &&
      constructionSitesData.findManyConstructionSites
    ) {
      constructionSiteArray = constructionSiteArray.concat(
        constructionSitesData.findManyConstructionSites,
      );
    }
    if (
      customerInvoice &&
      customerInvoice.construction_site &&
      customerInvoice.construction_site.id
    ) {
      let constructionSiteIncluded = false;
      if (
        constructionSitesData &&
        constructionSitesData.findManyConstructionSites
      ) {
        for (
          let i = 0;
          i < constructionSitesData.findManyConstructionSites.length;
          i++
        ) {
          if (
            constructionSitesData.findManyConstructionSites[i].id ===
            customerInvoice.construction_site.id
          ) {
            constructionSiteIncluded = true;
          }
        }
      }

      if (!constructionSiteIncluded) {
        constructionSiteArray = constructionSiteArray.concat(
          customerInvoice.construction_site as IConstructionSite,
        );
      }
    }

    return convertConstructionSitesToComboBoxOptions(constructionSiteArray);
  }, [customerInvoice, constructionSitesData]);

  const getCustomerComboBoxOptions = useCallback(() => {
    let customerArray: ILead[] = [];

    if (customersData && customersData.findManyLeads) {
      // Apply the filtering criteria                        // any because construction site does not excist on ILead
      customerArray = customersData.findManyLeads.filter(
        (lead: any) =>
          lead.status === 'SOLD' ||
          lead.status === 'INVOICE_ONLY' ||
          lead.status === 'PROVISIONAL_AGREEMENT' ||
          lead.status === 'CANCELED' ||
          lead.only_invoice ||
          (lead.construction_sites &&
            lead.construction_sites.some((site: any) => site.id > 0)),
      );
    }

    // Check if the customer from customerInvoice is included
    if (customerInvoice && customerInvoice.lead && customerInvoice.lead.id) {
      const isCustomerIncluded = customerArray.some(
        customer => customer.id === customerInvoice?.lead?.id,
      );

      if (!isCustomerIncluded) {
        customerArray.push(customerInvoice.lead as ILead);
      }
    }

    return convertLeadsToComboBoxOptions(customerArray);
  }, [customerInvoice, customersData]);

  // ============================================================================
  // ============================================================================
  // ============================================================================

  const addCustomerContractUpdate = (item: ICustomerContractUpdate) => {
    setCustomerInvoice(prevState => {
      const currentCustomerInvoice = _.cloneDeep(prevState);
      const currentUpdates = _.cloneDeep(
        prevState.customer_invoice_contract_updates,
      );
      if (currentUpdates && currentUpdates.length > 0) {
        let exists = false;
        for (let i = 0; i < currentUpdates.length; i++) {
          if (currentUpdates[i].customer_contract_update_id === item.id) {
            exists = true;
            currentUpdates[i].connect = true;
            currentUpdates[i].disconnect = false;

            currentCustomerInvoice.customer_invoice_contract_updates =
              currentUpdates;
          }
        }
        if (!exists && item.id) {
          currentCustomerInvoice.customer_invoice_contract_updates = [
            ...currentUpdates,
            {
              contract_update: {
                ...item,
              },
              customer_contract_update_id: item.id,
              connect: true,
              disconnect: false,
            },
          ];
        }
      } else {
        currentCustomerInvoice.customer_invoice_contract_updates = [
          {
            contract_update: {
              ...item,
            },
            customer_contract_update_id: item.id!,
            connect: true,
            disconnect: false,
          },
        ];
      }

      // todo

      if (
        currentCustomerInvoice &&
        !currentCustomerInvoice.customer_invoice_line_items
      ) {
        currentCustomerInvoice.customer_invoice_line_items = [];
      }

      if (
        item &&
        item.customer_contract_update_line_items &&
        currentCustomerInvoice.customer_invoice_line_items
      ) {
        for (
          let i = 0;
          i < item.customer_contract_update_line_items.length;
          i++
        ) {
          const contractUpdateLine =
            item.customer_contract_update_line_items[i];
          const currentIndex =
            currentCustomerInvoice.customer_invoice_line_items.findIndex(
              invoiceLineItem =>
                invoiceLineItem.customer_contract_update_line_item &&
                invoiceLineItem.customer_contract_update_line_item.id ===
                  contractUpdateLine.id,
            );

          if (currentIndex === -1) {
            // otherwise it already exists on invoice
            currentCustomerInvoice.customer_invoice_line_items.push({
              units: parseNumber(contractUpdateLine.units),
              unit_price: parseNumber(contractUpdateLine.unit_price),
              description: `[Meerwerk]  ${item.document_no}: ${contractUpdateLine.description}`,
              percentage_vat: parseNumber(
                contractUpdateLine.tax_code?.rate
                  ? contractUpdateLine.tax_code?.rate
                  : contractUpdateLine.percentage_vat,
              ),
              tax_code: contractUpdateLine.tax_code,
              show_only_total: true,
              isDirty: true,
              isNew: true,
              id: moment().unix(),
              customer_contract_update_line_item: {
                id: contractUpdateLine.id,
              },
            });
          } else if (currentIndex) {
            currentCustomerInvoice.customer_invoice_line_items[
              currentIndex
            ].delete = false;
          }
        }
      }

      return currentCustomerInvoice;
    });
  };

  const removeCustomerContractUpdate = (item: any) => {
    setCustomerInvoice(prevState => {
      const currentCustomerInvoice = _.cloneDeep(prevState);
      const currentUpdates = _.cloneDeep(
        prevState.customer_invoice_contract_updates,
      );

      if (currentUpdates && currentUpdates.length > 0) {
        let exists = false;
        for (let i = 0; i < currentUpdates.length; i++) {
          if (currentUpdates[i].customer_contract_update_id === item.id) {
            exists = true;
            currentUpdates[i].connect = false;
            currentUpdates[i].disconnect = true;

            currentCustomerInvoice.customer_invoice_contract_updates =
              currentUpdates;
          }
        }
      }

      console.log(item);
      console.log(item.customer_contract_update_line_items);

      if (
        item &&
        item.customer_contract_update_line_items &&
        currentCustomerInvoice.customer_invoice_line_items
      ) {
        for (
          let i = 0;
          i < item.customer_contract_update_line_items.length;
          i++
        ) {
          const contractUpdateLine =
            item.customer_contract_update_line_items[i];
          const currentIndex =
            currentCustomerInvoice.customer_invoice_line_items.findIndex(
              invoiceLineItem =>
                invoiceLineItem.customer_contract_update_line_item &&
                invoiceLineItem.customer_contract_update_line_item.id ===
                  contractUpdateLine.id,
            );

          if (currentIndex !== -1) {
            // otherwise it already exists on invoice
            currentCustomerInvoice.customer_invoice_line_items[
              currentIndex
            ].isDirty = true;
            currentCustomerInvoice.customer_invoice_line_items[
              currentIndex
            ].delete = true;
          }
        }
      }

      return currentCustomerInvoice;
    });
  };

  // ============================================================================
  // ============================================================================
  // ============================================================================

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        customerInvoice && customerInvoice.id
          ? 'Uitgaande factuur wijzigen'
          : 'Uitgaande factuur toevoegen'
      }
      type={PanelType.custom}
      customWidth='1050px'
      styles={customPanelStyles}
    >
      <InvoiceLineDetail
        isOpen={isInvoiceLinePanelOpen}
        dismissPanel={() => {
          setIsInvoiceLinePanelOpen(false);
        }}
        invoiceLine={invoiceLine}
        saveInvoiceLine={saveInvoiceLine}
        setInvoiceLine={setInvoiceLine}
        taxCodes={tax_codes}
      />
      {showDeleteLineItemDialog && invoiceLine && (
        <Dialog
          hidden={!showDeleteLineItemDialog}
          onDismiss={() => {
            setShowDeleteLineItemDialog(false);
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Detaillijn verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>Ben je zeker dat je deze lijn wil verwijderen?</strong>
          </p>
          <p>{invoiceLine.description}</p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton onClick={deleteSelection}>
              Verwijderen{' '}
              {deletingLineItem && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
            <DefaultButton
              text='Annuleren'
              onClick={() => {
                setShowDeleteLineItemDialog(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}

      <PaymentDetail
        isOpen={isPaymentPanelOpen}
        dismissPanel={() => {
          setIsPaymentPanelOpen(false);
        }}
        payment={payment}
        savePayment={savePayment}
        setPayment={setPayment}
      />

      <MailDetail
        isOpen={isMailPanelOpen}
        dismissPanel={() => {
          setIsMailPanelOpen(false);
        }}
        mail={mail}
        saveMail={saveMail}
        setMail={setMail}
      />

      {showDeletePaymentDialog && selectionPaymentDetails && (
        <Dialog
          hidden={!showDeletePaymentDialog}
          onDismiss={() => {
            setShowDeletePaymentDialog(false);
          }}
          dialogContentProps={{
            type: DialogType.normal,
            title: 'Detaillijn verwijderen',
            closeButtonAriaLabel: 'Close',
          }}
        >
          <p>
            <strong>Ben je zeker dat je deze lijn wil verwijderen?</strong>
          </p>
          <p>Deze actie kan niet ongedaan gemaakt worden.</p>
          <DialogFooter>
            <PrimaryButton onClick={deletePaymentSelection}>
              Verwijderen{' '}
              {deletingPayment && <Spinner size={SpinnerSize.small} />}
            </PrimaryButton>
            <DefaultButton
              text='Annuleren'
              onClick={() => {
                setShowDeletePaymentDialog(false);
              }}
            />
          </DialogFooter>
        </Dialog>
      )}

      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {customerInvoice && <Label>ID: {customerInvoice.id}</Label>}

          <Stack
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <StackItem style={{ width: '48%' }}>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Werf'
                  disabled={customerInvoice.sent}
                  options={convertConstructionSitesToComboBoxOptions(
                    skipFetchConstructionSites
                      ? [constructionSite]
                      : customerInvoice &&
                        customerInvoice.construction_site &&
                        constructionSites
                      ? [
                          ...constructionSites,
                          customerInvoice.construction_site,
                        ]
                      : constructionSites || [],
                  )}
                  value={
                    skipFetchConstructionSites &&
                    constructionSite &&
                    constructionSite.id
                      ? constructionSite.id
                      : customerInvoice && customerInvoice.construction_site
                      ? customerInvoice.construction_site.id
                      : ''
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...customerInvoice };

                      result.construction_site = {
                        id: newValue[0].key as number,
                      };

                      const constructionSites =
                        constructionSitesData &&
                        constructionSitesData.findManyConstructionSites;

                      if (constructionSites && constructionSites.length > 0) {
                        for (let i = 0; i < constructionSites.length; i++) {
                          if (
                            constructionSites[i].id ===
                            (newValue[0].key as number)
                          ) {
                            result.construction_site = constructionSites[i];

                            if (
                              constructionSites[i] &&
                              constructionSites[i].lead &&
                              !result.lead
                            ) {
                              result.lead = constructionSites[i].lead;
                            }
                          }
                        }
                      }

                      setCustomerInvoice(result);
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    setConstructionSiteFilter(value);
                  }}
                />
              </Stack>
              <Stack
                style={{
                  marginBottom: 10,
                }}
              >
                <ComboboxWithFilter
                  label='Klant'
                  options={getCustomerComboBoxOptions()}
                  value={
                    customerInvoice && customerInvoice.lead
                      ? customerInvoice.lead.id
                      : ''
                  }
                  disabled={customerInvoice.sent}
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = { ...customerInvoice };

                      result.lead = {
                        id: newValue[0].key as number,
                      };

                      setCustomerInvoice(result);
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    setCustomerFilter(value);
                  }}
                />
              </Stack>
              <Stack style={{ marginBottom: 10, marginTop: 31 }}>
                <Label>
                  Factuurnummer: {customerInvoice && customerInvoice.invoice_no}
                </Label>
              </Stack>
              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Stack style={{ width: '48%' }}>
                  {!customerInvoice.sent ? (
                    <DateView
                      item={customerInvoice}
                      setItem={setCustomerInvoice}
                      date={customerInvoice && customerInvoice.invoice_date}
                      label='Factuurdatum'
                      field='invoice_date'
                    />
                  ) : (
                    <TextField
                      name='date_invoice'
                      label='Factuurdatum'
                      value={
                        customerInvoice &&
                        moment(customerInvoice.invoice_date).format(
                          'DD/MM/YYYY',
                        )
                      }
                      disabled={customerInvoice.sent}
                      onChange={onChangeTextFieldValue}
                    />
                  )}
                </Stack>
                <Stack style={{ width: '48%' }}>
                  {!customerInvoice.sent ? (
                    <DateView
                      item={customerInvoice}
                      setItem={setCustomerInvoice}
                      date={customerInvoice && customerInvoice.invoice_due_date}
                      label='Betaalbaar voor'
                      field='invoice_due_date'
                    />
                  ) : (
                    <TextField
                      name='date_invoice_due'
                      label='Betaald voor'
                      value={
                        customerInvoice &&
                        moment(customerInvoice.invoice_due_date).format(
                          'DD/MM/YYYY',
                        )
                      }
                      disabled={customerInvoice.sent}
                      onChange={onChangeTextFieldValue}
                    />
                  )}
                </Stack>
              </Stack>
              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ width: '48%' }}>
                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='credit_note'
                      label='Creditnota?'
                      disabled={customerInvoice.sent}
                      checked={
                        !!(
                          customerInvoice &&
                          customerInvoice.credit_note === true
                        )
                      }
                      onChange={(e, checked) => {
                        setCustomerInvoice((prevState: any) => ({
                          ...prevState,
                          credit_note: checked,
                        }));
                      }}
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='invoice_paid'
                      label='Betaald?'
                      disabled={customerInvoice.sent}
                      checked={
                        !!(
                          customerInvoice &&
                          customerInvoice.invoice_paid === true
                        )
                      }
                      onChange={(e, checked) => {
                        setCustomerInvoice((prevState: any) => ({
                          ...prevState,
                          invoice_paid: checked,
                        }));
                      }}
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='sent'
                      label='Verzonden?'
                      checked={
                        !!(customerInvoice && customerInvoice.sent === true)
                      }
                      defaultChecked={
                        !!(customerInvoice && customerInvoice.sent === true)
                      }
                      disabled={customerInvoice.sent}
                      onChange={(e, checked) => {
                        setCustomerInvoice((prevState: any) => ({
                          ...prevState,
                          sent: checked,
                        }));
                      }}
                    />
                  </Stack>
                </StackItem>
                <StackItem style={{ width: '48%' }}>
                  <Stack style={{ marginTop: 10 }}>
                    <Label>Totaal incl. BTW</Label>
                    &euro; {invoiceAmount || '0.00'}
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Openstaand bedrag</Label>
                    &euro; {(invoiceAmount - invoiceAmountPayed).toFixed(2)}
                  </Stack>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='intro'
                  label='Inleidende tekst'
                  multiline
                  value={
                    customerInvoice && customerInvoice.intro
                      ? customerInvoice.intro
                      : ''
                  }
                  rows={4}
                  disabled={customerInvoice.sent}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='tax_description'
                  label='BTW tekst'
                  multiline
                  value={
                    customerInvoice && customerInvoice.tax_description
                      ? customerInvoice.tax_description
                      : ''
                  }
                  rows={4}
                  disabled={customerInvoice.sent}
                  onChange={onChangeTextFieldValue}
                />
                <p
                  style={{
                    cursor: 'pointer',
                    color: '#71BF44',
                    marginTop: 0,
                  }}
                  aria-hidden='true'
                  onClick={() => {
                    const description = getUniqueTaxCodeDescriptionsByUniqueId(
                      customerInvoice as any,
                    );

                    setCustomerInvoice({
                      ...customerInvoice,
                      tax_description: description,
                    });
                  }}
                >
                  Genereren
                </p>
              </Stack>
              <Stack style={{ marginBottom: 10 }}>
                <TextField
                  name='outro'
                  label='Afsluitende tekst'
                  multiline
                  value={
                    customerInvoice && customerInvoice.outro
                      ? customerInvoice.outro
                      : ''
                  }
                  rows={4}
                  disabled={customerInvoice.sent}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </StackItem>
          </Stack>

          {customerInvoice && customerInvoice.id ? (
            <Stack style={{ marginBottom: 10, marginTop: 30 }}>
              <h3>Details</h3>
              <CommandBar
                items={[
                  {
                    key: 'new',
                    text: 'Nieuw',
                    iconProps: { iconName: 'Add' },
                    onClick: () => openInvoiceLineDetail(true),
                    disabled: customerInvoice.sent,
                    theme: commandBarTheme,
                  },
                  {
                    key: 'modify',
                    text: 'Wijzig',
                    iconProps: { iconName: 'Edit' },
                    onClick: () => openInvoiceLineDetail(),
                    theme: commandBarTheme,
                    disabled:
                      !invoiceLine || !invoiceLine.id || customerInvoice.sent,
                  },
                  {
                    key: 'delete',
                    text: 'Verwijderen',
                    iconProps: { iconName: 'Trash' },
                    onClick: () => setShowDeleteLineItemDialog(true),
                    theme: commandBarTheme,
                    disabled:
                      !invoiceLine || !invoiceLine.id || customerInvoice.sent,
                  },
                ]}
                theme={commandBarTheme}
              />
              <ShimmeredDetailsList
                items={customerInvoice.customer_invoice_line_items || []}
                columns={[
                  {
                    key: 'column1',
                    name: 'Omschrijving',
                    fieldName: 'description',
                    minWidth: 175,
                    maxWidth: 175,
                    isRowHeader: true,
                    onRender: (line_item: any) => (
                      <span>
                        <span
                          style={{
                            opacity: line_item.delete ? '0.5' : undefined,
                          }}
                        >
                          {line_item.description}
                        </span>{' '}
                        {line_item.delete && '(verwijderen)'}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column2',
                    name: 'Aantal',
                    fieldName: 'units',
                    minWidth: 40,
                    maxWidth: 40,
                    isRowHeader: true,
                    onRender: (line_item: any) => (
                      <span
                        style={{
                          opacity: line_item.delete ? '0.5' : undefined,
                        }}
                      >
                        {line_item.units}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column3',
                    name: 'Eenheidsprijs',
                    fieldName: 'unit_price',
                    minWidth: 70,
                    maxWidth: 70,
                    isRowHeader: true,
                    onRender: (line_item: any) => (
                      <span
                        style={{
                          opacity: line_item.delete ? '0.5' : undefined,
                        }}
                      >
                        &euro;{' '}
                        {line_item.unit_price &&
                          line_item.unit_price.toFixed(2)}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column4',
                    name: 'BTW %',
                    fieldName: 'percentage_vat',
                    minWidth: 30,
                    maxWidth: 30,
                    isRowHeader: true,
                    onRender: (line_item: any) => {
                      // Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined
                      const vatRate =
                        line_item.tax_code?.rate ??
                        line_item.percentage_vat ??
                        0;

                      // If vatRate is effectively 0 (after fallbacks), return null to avoid rendering
                      if (vatRate === 0) return null;

                      return (
                        <span>
                          {vatRate < 1
                            ? `${(vatRate * 100).toFixed(2)}%` // Convert decimal to percentage and format to 2 decimal places
                            : `${vatRate}%`}{' '}
                        </span>
                      );
                    },
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column5',
                    name: 'Totaal excl. BTW',
                    fieldName: 'excl_vat',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    onRender: (line_item: any) => (
                      <span
                        style={{
                          opacity: line_item.delete ? '0.5' : undefined,
                        }}
                      >
                        &euro;{' '}
                        {(line_item.unit_price * line_item.units).toFixed(2)}
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column6',
                    name: 'Totaal incl. BTW',
                    fieldName: 'incl_vat',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    onRender: (line_item: any) => {
                      if (line_item.calculatedTotalIncludingVat) {
                        return (
                          <span>
                            &euro; {line_item.calculatedTotalIncludingVat}
                          </span>
                        );
                      }

                      // Determine VAT rate: Use tax_code.rate if available, else fallback to percentage_vat, default to 0 if both are undefined
                      const vatRate =
                        line_item.tax_code?.rate ??
                        line_item.percentage_vat ??
                        0;

                      // Calculate total including VAT based on the available VAT rate
                      const totalIncludingVat = (
                        parseNumber(line_item.unit_price) *
                        parseNumber(line_item.units) *
                        (vatRate === 0
                          ? 1
                          : +vatRate < 1
                          ? 1 + +vatRate
                          : 1 + +vatRate / 100)
                      ).toFixed(2);

                      return <span>&euro; {totalIncludingVat}</span>;
                    },
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column7',
                    name: 'Enkel totaal',
                    fieldName: 'show_only_total',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    onRender: (line_item: any) => (
                      <span
                        style={{
                          opacity: line_item.delete ? '0.5' : undefined,
                        }}
                      >
                        <Checkbox
                          disabled
                          checked={line_item.show_only_total === true}
                        />
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                  {
                    key: 'column8',
                    name: '',
                    fieldName: ' toggle delete',
                    minWidth: 80,
                    maxWidth: 80,
                    isRowHeader: true,
                    onRender: (item: ICustomerInvoiceLineItem, index) => (
                      <span>
                        <Icon
                          iconName={item.delete ? 'Refresh' : 'Delete'}
                          styles={iconProps}
                          onClick={() => {
                            setCustomerInvoice(prevState => {
                              const newCustomerInvoice = _.cloneDeep(prevState);
                              if (!prevState.sent) {
                                if (
                                  newCustomerInvoice &&
                                  newCustomerInvoice.customer_invoice_line_items &&
                                  index !== undefined
                                ) {
                                  newCustomerInvoice.customer_invoice_line_items[
                                    index
                                  ].isDirty = true;
                                  newCustomerInvoice.customer_invoice_line_items[
                                    index
                                  ].delete =
                                    !newCustomerInvoice
                                      .customer_invoice_line_items[index]
                                      .delete;
                                }
                              }

                              return newCustomerInvoice;
                            });
                          }}
                        />
                      </span>
                    ),
                    data: 'string',
                    isPadded: true,
                  },
                ]}
                layoutMode={DetailsListLayoutMode.justified}
                selection={selectionInvoiceLine}
                selectionMode={SelectionMode.single}
                selectionPreservedOnEmptyClick
                isHeaderVisible
                onItemInvoked={() => openInvoiceLineDetail()}
              />
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {customerInvoice && (
                  <AccordionItem
                    key='accordion-invoice-contract-updates'
                    id='accordion-invoice-contract-updates'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Min-/meerwerken
                        </h3>
                      </Stack>
                    }
                  >
                    {customerInvoice && !customerInvoice.sent && (
                      <>
                        <Stack>
                          <h4 style={{ marginTop: 0, marginBottom: 10 }}>
                            Beschikbaar
                          </h4>
                        </Stack>
                        <ShimmeredDetailsList
                          items={
                            customerContractUpdatesData &&
                            customerContractUpdatesData.findManyCustomerContractUpdates
                              ? customerContractUpdatesData.findManyCustomerContractUpdates.filter(
                                  (item: ICustomerContractUpdate) => {
                                    if (
                                      item.customer_invoice ||
                                      item.customer_invoice_id
                                    ) {
                                      // if we have a legacy invoice don't show here!
                                      return false;
                                    }
                                    if (
                                      item.customer_invoice_contract_updates &&
                                      item.customer_invoice_contract_updates
                                        .length > 0
                                    ) {
                                      // if we have another invoice for this contract update don't show here!
                                      return false;
                                    }
                                    if (item.split_payment === true) {
                                      // if split_payment is true, exclude from the list
                                      return false;
                                    }
                                    if (
                                      customerInvoice &&
                                      customerInvoice.customer_invoice_contract_updates
                                    ) {
                                      for (
                                        let i = 0;
                                        i <
                                        customerInvoice
                                          .customer_invoice_contract_updates
                                          .length;
                                        i++
                                      ) {
                                        if (
                                          customerInvoice
                                            .customer_invoice_contract_updates[
                                            i
                                          ].customer_contract_update_id ===
                                          item.id
                                        ) {
                                          if (
                                            customerInvoice
                                              .customer_invoice_contract_updates[
                                              i
                                            ].disconnect
                                          ) {
                                            return true;
                                          }
                                          return false;
                                        }
                                      }
                                    }

                                    return true;
                                  },
                                )
                              : []
                          }
                          columns={[
                            {
                              key: 'column1',
                              name: 'Bon nr',
                              fieldName: 'document_no',
                              minWidth: 75,
                              maxWidth: 100,
                              isRowHeader: true,
                              onRender: (item: ICustomerContractUpdate) => (
                                <span>{item.document_no}</span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column2',
                              name: 'Datum',
                              fieldName: 'document_date',
                              minWidth: 100,
                              maxWidth: 200,
                              isRowHeader: true,
                              onRender: (item: ICustomerContractUpdate) => (
                                <span>
                                  {item.document_date
                                    ? moment(item.document_date).format(
                                        'DD/MM/YYYY',
                                      )
                                    : ''}
                                </span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column3',
                              name: 'Omschrijving',
                              fieldName: 'summary',
                              minWidth: 100,
                              // maxWidth: 100,
                              isRowHeader: true,
                              onRender: (item: ICustomerContractUpdate) => (
                                <span>{item.summary}</span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column3',
                              name: 'Status',
                              fieldName: 'status',
                              minWidth: 100,
                              // maxWidth: 100,
                              isRowHeader: true,
                              onRender: (item: ICustomerContractUpdate) => {
                                switch (item.status) {
                                  case 'NEW':
                                    return <span>Nieuw</span>;
                                  case 'CANCELLED':
                                    return <span>Geannuleerd</span>;
                                  case 'INVOICED':
                                    return <span>Op factuur</span>;
                                  case 'COMPLETED':
                                    return <span>Afgesloten</span>;
                                  default:
                                    return <span>{item.status}</span>;
                                }
                              },
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column4',
                              name: '',
                              fieldName: 'addToInvoice',
                              minWidth: 100,
                              maxWidth: 100,
                              isRowHeader: true,
                              onRender: (item: ICustomerContractUpdate) => (
                                <button
                                  type='button'
                                  onClick={e => {
                                    e.preventDefault();
                                    console.log('clikc');
                                    addCustomerContractUpdate(item);
                                  }}
                                >
                                  Voeg toe
                                </button>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                          ]}
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible
                          selectionMode={SelectionMode.none}
                        />
                      </>
                    )}

                    <Stack>
                      <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                        Gelinkt aan deze factuur
                      </h4>
                    </Stack>
                    <ShimmeredDetailsList
                      items={
                        customerInvoice.customer_invoice_contract_updates
                          ? customerInvoice.customer_invoice_contract_updates
                              .filter(item => {
                                if (item.disconnect) {
                                  return false;
                                }
                                return true;
                              })
                              .map(item => item)
                          : []
                      }
                      columns={[
                        {
                          key: 'column4',
                          name: 'Bon nr',
                          fieldName: 'document_no',
                          minWidth: 75,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: any) => (
                            <span>{item.contract_update.document_no}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column5',
                          name: 'Datum',
                          fieldName: 'document_date',
                          minWidth: 100,
                          maxWidth: 200,
                          isRowHeader: true,
                          onRender: (item: any) => (
                            <span>
                              {item.contract_update.document_date
                                ? moment(
                                    item.contract_update.document_date,
                                  ).format('DD/MM/YYYY')
                                : ''}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column6',
                          name: 'Omschrijving',
                          fieldName: 'description',
                          minWidth: 100,
                          // maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: any) => (
                            <span>{item.contract_update.summary}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: '',
                          fieldName: 'addToInvoice',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (item: any) => (
                            <span>
                              {customerInvoice && !customerInvoice.sent && (
                                <button
                                  type='button'
                                  onClick={e => {
                                    e.preventDefault();
                                    removeCustomerContractUpdate(
                                      item.contract_update,
                                    );
                                  }}
                                >
                                  Verwijderen
                                </button>
                              )}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selectionMode={SelectionMode.none}
                    />
                  </AccordionItem>
                )}
                {customerInvoice && (
                  <AccordionItem
                    key='accordion-invoice-payments'
                    id='accordion-invoice-payments'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Betalingen
                        </h3>
                      </Stack>
                    }
                  >
                    <CommandBar
                      items={[
                        {
                          key: 'new',
                          text: 'Nieuw',
                          iconProps: { iconName: 'Add' },
                          onClick: () => openPaymentDetail(true),
                          theme: commandBarTheme,
                        },
                        {
                          key: 'modify',
                          text: 'Wijzig',
                          iconProps: { iconName: 'Edit' },
                          onClick: () => openPaymentDetail(),
                          theme: commandBarTheme,
                          disabled: !selectionPaymentDetails,
                        },
                        {
                          key: 'delete',
                          text: 'Verwijderen',
                          iconProps: { iconName: 'Trash' },
                          onClick: () => setShowDeletePaymentDialog(true),
                          theme: commandBarTheme,
                          disabled: !selectionPaymentDetails,
                        },
                      ]}
                      theme={commandBarTheme}
                    />
                    <ShimmeredDetailsList
                      items={customerInvoice.customer_invoice_payments || []}
                      columns={[
                        {
                          key: 'column1',
                          name: 'Datum',
                          fieldName: 'payment_date',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>
                              {moment(payment.payment_date).format(
                                'DD/MM/YYYY',
                              )}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Bedrag',
                          fieldName: 'amount',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>&euro; {payment.amount.toFixed(2)}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column3',
                          name: 'Referentie / Nota',
                          fieldName: 'comments',
                          minWidth: 500,
                          maxWidth: 500,
                          isRowHeader: true,
                          onRender: (payment: any) => (
                            <span>{payment.comments}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={selectionPayment}
                      selectionMode={SelectionMode.single}
                      selectionPreservedOnEmptyClick
                      onItemInvoked={() => openPaymentDetail()}
                    />
                  </AccordionItem>
                )}
                {customerInvoice && (
                  <AccordionItem
                    key='accordion-invoice-mails'
                    id='accordion-invoice-mails'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Mails (in ontwikkeling)
                        </h3>
                      </Stack>
                    }
                  >
                    <CommandBar
                      items={[
                        {
                          key: 'new',
                          text: 'Nieuw',
                          iconProps: { iconName: 'Add' },
                          onClick: () => openMailDetail(true),
                          theme: commandBarTheme,
                        },
                        {
                          key: 'modify',
                          text: 'Bekijk',
                          iconProps: { iconName: 'Edit' },
                          onClick: () => openMailDetail(),
                          theme: commandBarTheme,
                          disabled: !selectionMailDetails,
                        },
                      ]}
                      theme={commandBarTheme}
                    />
                    <ShimmeredDetailsList
                      items={customerInvoice.mail_logs || []}
                      columns={[
                        {
                          key: 'column1',
                          name: 'Verzonden op',
                          fieldName: 'date_created',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (log: IMailLog) => (
                            <span>
                              {moment(log.date_created).format('DD/MM/YYYY')}
                            </span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                        {
                          key: 'column2',
                          name: 'Ontvanger',
                          fieldName: 'to',
                          minWidth: 100,
                          maxWidth: 100,
                          isRowHeader: true,
                          onRender: (log: IMailLog) => (
                            <span>&euro; {log.to}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={selectionPayment}
                      selectionMode={SelectionMode.single}
                      selectionPreservedOnEmptyClick
                      onItemInvoked={() => openMailDetail()}
                    />
                  </AccordionItem>
                )}
              </Accordion>
            </Stack>
          ) : (
            <Stack style={{ marginTop: 30, marginBottom: 10 }}>
              <Label>
                Na opslaan kan je het detail van de factuur aanpassen.
              </Label>
              <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                <Accordion
                  selectedKey={undefined}
                  defaultKey={undefined}
                  toggleItem={() => {
                    setSelectedValueItem(undefined);
                  }}
                >
                  <AccordionItem
                    key='accordion-invoice-linte-items'
                    id='accordion-invoice-linte-items'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Detail
                        </h3>
                      </Stack>
                    }
                  />
                  <AccordionItem
                    key='accordion-invoice-contract-updates'
                    id='accordion-invoice-contract-updates'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Min-/meerwerken
                        </h3>
                      </Stack>
                    }
                  />
                  <AccordionItem
                    key='accordion-invoice-payments'
                    id='accordion-invoice-payments'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Betalingen
                        </h3>
                      </Stack>
                    }
                  />
                  <AccordionItem
                    key='accordion-invoice-mailings'
                    id='accordion-invoice-mailings'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>Mails</h3>
                      </Stack>
                    }
                  />
                </Accordion>
              </Stack>
            </Stack>
          )}
        </div>
        <Stack
          className={modalContentStyles.footer}
          horizontal
          horizontalAlign='space-between'
        >
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !customerInvoice ||
                (customerInvoice && !customerInvoice.invoice_date) ||
                loading
              }
              save={saveCustomerInvoice}
              permission='write:customerInvoices'
              loading={loading}
            />
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <StackItem>
              <PrimaryButton
                iconProps={{ iconName: 'Download' }}
                onClick={() => {
                  setShowPdf(true);
                }}
              >
                Download PDF
              </PrimaryButton>
            </StackItem>
          </Stack>
        </Stack>
      </div>
      {showPdf && customerInvoice && (
        <CustomerInvoicesDynamicPdf
          customerInvoiceSource={customerInvoice}
          setShowPdf={setShowPdf}
        />
      )}
    </Panel>
  );
};

export default CustomerInvoiceDetail;
